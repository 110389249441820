import React from "react";
import { useTranslation } from "react-i18next";
import { Bar } from "react-chartjs-2";
import { merge } from "lodash";
import {
  generateSimpleLabels,
  alignDataWithLabels,
  renderChart,
  baseOptions
} from "pages/OrchestrationDashboard/Utils/Helpers";
import theme from "style/orchestratorTheme";
import useStyles from "../../style";

const metrics = [
  "totalExecutionTimeHours",
  "totalPreemptionTimeHours",
  "totalReactionTimeHours",
];

const colors = [
  theme.custom.color.chartColor1,
  theme.palette.info.main,
  theme.palette.primary.main,
];

const ResourceExecutionTime = ({ data, isLoading, filter }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const chartData = {
    labels: generateSimpleLabels(filter.selectedDurationValue, t),
    datasets: metrics.map((metric, index) => ({
      label: t(metric),
      data: alignDataWithLabels(filter.selectedDurationValue, data, metrics).map((item) => item[metric]),
      backgroundColor: colors[index],
      barThickness: 34,
      borderWidth: 0,
    })),
  };

  const options = merge({}, baseOptions(metrics, colors, t), {
    scales: {
      xAxes: [
        {
          stacked: true,
        },
      ],
      yAxes: [
        {
          stacked: true,
          ticks: {
            callback(value) {
              return `${value} s`;
            },
          },
        },
      ],
    },
  });
  return renderChart(
    isLoading,
    data,
    "&nbsp;",
    classes,
    <Bar data={chartData} options={options} height={90} />
  );
};

export default ResourceExecutionTime;
