import React, { useState } from "react";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import { useTranslation } from "react-i18next";
import CustomAutoComplete from "components/FormFields/CustomAutoComplete";
import useFetchData from "../useFetchData";
import useStyles from "../../style";

const AnalyticsContainer = ({
 name, sortList, graph, fetchAction, baseFilter, mock, defaultSort = ""
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [selectedSort, setSelectedSort] = useState(defaultSort);
  const { data, isLoading } = useFetchData(fetchAction, baseFilter, selectedSort, mock);

  return (
    <Box className={classes.container} display="grid" gridRowGap={20} justifyContent="space-between">
      <Box display="grid" gridTemplateColumns="3fr 1fr">
        <Typography className={classes.graphLabel}>{name}</Typography>
        <CustomAutoComplete
          className={classes.filter}
          value={sortList.find(({ code }) => code === selectedSort)}
          options={sortList ?? []}
          optionLabel="label"
          optionUuid="code"
          label={t("sortBy")}
          onChange={(value) => setSelectedSort(value.code ?? "")}
        />
      </Box>
      <Box mt="auto">{React.cloneElement(graph, { isLoading, data })}</Box>
    </Box>
  );
};

export default AnalyticsContainer;
