import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
    analyticsContainer: {
        margin: theme.spacing(3, 0),
    },
    filter: {
        marginTop: theme.spacing(-1.5)
    },
    container: {
        border: `1px solid ${theme.palette.primary.light}`,
        borderRadius: "10px",
        padding: theme.spacing(2, 3),
        height: "100%",
    },
    graphLabel: {
        fontSize: "1.5rem",
        fontWeight: 700,
        color: theme.palette.primary.main,
    },
    axesLabel: {
        fontWeight: 700,
        fontSize: 13,
        color: theme.palette.primary.main,
    }
}));
