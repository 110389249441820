import React from "react";
import { useTranslation } from "react-i18next";
import { Bar } from "react-chartjs-2";
import {
  generateSimpleLabels,
  alignDataWithLabels,
  renderChart, baseOptions,
} from "pages/OrchestrationDashboard/Utils/Helpers";
import theme from "style/orchestratorTheme";
import useStyles from "../../style";

const metrics = ["completedSessions", "stoppedSessions", "failedSessions"];
const colors = [
  theme.palette.primary.main,
  theme.custom.color.chartColor1,
  theme.palette.secondary.main,
];

const ResourceSessionActivity = ({ data, isLoading, filter }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const chartData = {
    labels: generateSimpleLabels(filter.selectedDurationValue, t),
    datasets: metrics.map((metric, index) => ({
      label: t(metric),
      data: alignDataWithLabels(
        filter.selectedDurationValue,
        data,
        metrics
      ).map((item) => item[metric]),
      backgroundColor: colors[index],
      barThickness: 20,
      borderWidth: 0,
    })),
  };

  return renderChart(
    isLoading,
    data,
    t("Resources"),
    classes,
    <Bar data={chartData} options={baseOptions(metrics, colors, t)} height={90} />
  );
};

export default ResourceSessionActivity;
