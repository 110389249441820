import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
    analyticsContainer: {
        marginTop: theme.spacing(0.5),
    },
    container: {
        border: `1px solid ${theme.palette.primary.light}`,
        borderRadius: "10px",
        padding: theme.spacing(0.5, 1),
    },
    graphLabel: {
        fontSize: "1.2rem",
        fontWeight: 700,
        color: theme.palette.primary.main,
    },
    axesLabel: {
        fontWeight: 700,
        fontSize: 13,
        color: theme.palette.primary.main,
    },
    moreIcon: {
        fontSize: "0.8rem !important",
        marginLeft: "-0.4rem",
    },
    moreBtnLabel: {
        fontWeight: 800,
    },
    dialogTitle: {
        fontSize: "1.35rem",
        color: theme.palette.primary.main,
        fontWeight: 700,
    },
    searchFilter: {
        marginTop: theme.spacing(2)
    },
    tableContainer: {
        overflowX: "auto",
        maxWidth: "100%",
        minHeight: 200
    }
}));
