import {
  FETCH_ALL_EXECUTION_REQUESTS, FETCH_NUMBER_OF_REQUESTS,
  FETCH_PROCESS_RESPONSE_TIME,
  FETCH_REQUEST_OUTCOME_DISTRIBUTION
} from "redux/constants";
import { URL_EXECUTIONS_REQUESTS, URL_EXECUTIONS_METRICS } from "redux/constants/endpoints";
import { formatDateParam } from "util/helpers/date.helpers";
import { numberOfRequests, processResponseTime, requestOutcomeDistribution } from "util/configs/backendEntities";
/* eslint-disable import/prefer-default-export */
export const fetchExecutionRequestsList = (
  page,
  size,
  sortField,
  sortOrder,
  processes,
  fleetIds,
  fromDate,
  toDate,
  selectedDurationValue,
  searchCriteria,
  tagsIds = [],
  archived = false
) => ({
  type: FETCH_ALL_EXECUTION_REQUESTS,
  request: {
    url: encodeURI(`${URL_EXECUTIONS_REQUESTS}${searchCriteria ? `?searchCriteria=${searchCriteria}` : ""}`),
    params: {
      page,
      processesIds: processes.join(),
      size,
      sort: `${sortField},${sortOrder}`,
      fleetIds: fleetIds?.join(),
      fromDate: formatDateParam(fromDate),
      toDate: formatDateParam(toDate),
      timePeriod: selectedDurationValue,
      tagsIds: tagsIds?.join(),
      archived
    },
  },
});

export const fetchProcessResponseTime = (executionsAnalyticsFilter, onSuccess) => {
  const {
 fromDate, toDate, selectedDurationValue, ...restFilters
} = executionsAnalyticsFilter;

  return {
    type: FETCH_PROCESS_RESPONSE_TIME,
    request: {
      url: `${URL_EXECUTIONS_METRICS}${processResponseTime}`,
      params: {
        ...restFilters,
        fromDate: formatDateParam(fromDate),
        toDate: formatDateParam(toDate),
        timePeriod: selectedDurationValue,
      },
    },
    meta: {
      onSuccess: (response) => {
        if (onSuccess) onSuccess(response);
        return response;
      },
    },
  };
};

export const fetchNumberOfRequests = (executionsAnalyticsFilter, onSuccess) => {
  const {
 fromDate, toDate, selectedDurationValue, ...restFilters
} = executionsAnalyticsFilter;

  return {
    type: FETCH_NUMBER_OF_REQUESTS,
    request: {
      url: `${URL_EXECUTIONS_METRICS}${numberOfRequests}`,
      params: {
        ...restFilters,
        fromDate: formatDateParam(fromDate),
        toDate: formatDateParam(toDate),
        timePeriod: selectedDurationValue,
      },
    },
    meta: {
      onSuccess: (response) => {
        if (onSuccess) onSuccess(response);
        return response;
      },
    },
  };
};

export const fetchRequestOutcomeDistribution = (executionsAnalyticsFilter, onSuccess) => {
  const {
 fromDate, toDate, selectedDurationValue, ...restFilters
} = executionsAnalyticsFilter;

  return {
    type: FETCH_REQUEST_OUTCOME_DISTRIBUTION,
    request: {
      url: `${URL_EXECUTIONS_METRICS}${requestOutcomeDistribution}`,
      params: {
        ...restFilters,
        fromDate: formatDateParam(fromDate),
        toDate: formatDateParam(toDate),
        timePeriod: selectedDurationValue,
      },
    },
    meta: {
      onSuccess: (response) => {
        if (onSuccess) onSuccess(response);
        return response;
      },
    },
  };
};
