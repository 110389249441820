import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "@redux-requests/react";
import Box from "@material-ui/core/Box";
import FormControl from "@material-ui/core/FormControl";
import { toast } from "react-toastify";
import { Controller, useForm } from "react-hook-form";
import {
  createResourceGroup, fetchResourceGroupById,
  updateResourceGroup, fetchResourcesByOrchestrator
} from "redux/actions/services";
import CustomDialog from "components/CustomDialog";
import { useDispatch } from "react-redux";
import CustomAutoComplete, {
  CustomTextField,
} from "components/FormFields/CustomAutoComplete";
import { FETCH_ORCHESTRATORS_NAMES_AND_IDS } from "redux/constants";
import CircularLoader from "components/Loaders/CircularLoader";
import useStyles from "../style";

export default function ResourceGroupForm({ open, handleClose, currentResourceGroup }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const classes = useStyles();
  const [resources, setResources] = useState();
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const [isNameError, setIsNameError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const orchestrators = useQuery({ type: FETCH_ORCHESTRATORS_NAMES_AND_IDS }).data;
  const {
    register,
    unregister,
    watch,
    formState: { errors },
    getValues,
    setValue,
    control,
    reset,
    clearErrors,
        trigger
  } = useForm({
    defaultValues: {},
  });

  const CloseCleanUp = () => {
    unregister();
    reset();
    clearErrors();
    setIsSubmitLoading(false);
    setIsNameError(false);
  };
  const onSuccess = () => {
    setIsSubmitLoading(false);
    toast.success(t("changes.saved.successfully"));
    CloseCleanUp();
    handleClose();
  };
  const onError = (data) => {
    if (data?.response?.data?.title === "unique.name.constraint") {
      toast.error(t("A resourceGroup with the same already exists"))
      setIsNameError(true);
      trigger("name");
    } else {
      toast.error(t("Unexpected Error"));
    }
    setIsSubmitLoading(false);
  };

  const onSubmit = async () => {
    const result = await trigger();
    if (!result) {
      return;
    }
    const tempData = {
      ...getValues(),
    };
    if (!currentResourceGroup) {
      dispatch(createResourceGroup(tempData, onSuccess, onError));
    } else {
      dispatch(updateResourceGroup(tempData, onSuccess, onError));
    }
  };

  const onFetchCurrentResourceGroupSuccess = (res) => {
    const tempResourceGroup = res?.data;
    setValue("id", tempResourceGroup?.id);
    setValue("name", tempResourceGroup?.name);
    setValue("description", tempResourceGroup?.description);
    setValue("orchestratorId", tempResourceGroup?.orchestratorId);
    setValue("resourceIds", tempResourceGroup?.resourceIds);
    setIsLoading(false);
  }

  const onFetchFixedResourceGroup = () => {
    setValue("id", currentResourceGroup?.id);
    setValue("name", currentResourceGroup?.name);
    setValue("description", currentResourceGroup?.description);
    setValue("orchestratorId", currentResourceGroup?.orchestratorId);
    setValue("resourceIds", currentResourceGroup?.resourceIds);
  }

  const onFetchCurrentResourceGroupError = () => {
    setIsLoading(false);
    toast.error(t("Unexpected Error"));
    handleClose();
  };

  useEffect(() => {
    if (currentResourceGroup) {
      if (currentResourceGroup?.isFixed) onFetchFixedResourceGroup()
      else {
        setIsLoading(true);
        dispatch(
          fetchResourceGroupById(
                currentResourceGroup?.id,
                onFetchCurrentResourceGroupSuccess,
                onFetchCurrentResourceGroupError
            )
        );
      }
    }
  }, [currentResourceGroup]);

  useEffect(() => {
    if (watch("orchestratorId")) {
      dispatch(fetchResourcesByOrchestrator(watch("orchestratorId"))).then(
        (res) => {
          if (res?.data) {
            setResources(res.data);
          }
        },
      );
    }
  }, [watch("orchestratorId")]);

  return (
    <CustomDialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      maxHeight="99%"
      width={660}
      fullWidth
      open={open}
      disableBackdropClick
      PaperProps={{
        square: true,
      }}
      title={t("ResourceGroup Configuration")}
      actionElements={[
        {
          label: t("Cancel"),
          onClick: handleClose,
          color: "cancelModal",
          disabled: isSubmitLoading,
        },
        {
          label: currentResourceGroup ? t("Update ResourceGroup") : t("Create ResourceGroup"),
          view: "primary",
          type: "submit",
          isLoading: isSubmitLoading,
          disabled: isSubmitLoading || isLoading,
          onClick: onSubmit,
          hidden: currentResourceGroup?.isFixed,
        },
      ]}
      borderRadius={6}
    >
      {isLoading ? (
        <CircularLoader height={400} />
      ) : (
        <Box>
          <Box mb="10px">
            <CustomTextField
                  {...register("name", {
                    required: t("field.is.required", {
                      field: t("ResourceGroup Name"),
                    }),
                    validate: !isNameError
                  })}
                  valued={getValues("name")}
                  label={`${t("ResourceGroup Name")} *`}
                  error={!!errors.name?.message}
                  helperText={errors.name && errors.name?.message}
                  fullWidth
                  InputLabelProps={{
                    shrink: !!watch("name"),
                  }}
              />
          </Box>
          <Box mb="10px">
            <CustomTextField
                  {...register("description", {
                    required: t("field.is.required", {
                      field: t("Description"),
                    }),
                  })}
                  valued={getValues("description")}
                  label={`${t("Description")} *`}
                  error={!!errors.description?.message}
                  helperText={errors.description && errors.description?.message}
                  fullWidth
                  InputLabelProps={{
                    shrink: !!watch("description"),
                  }}
                  className={classes.field}
              />
          </Box>
          <FormControl fullWidth margin="none">
            <Controller
                    control={control}
                    name="orchestratorId"
                    rules={{
                      required: t("queue.management.form.validation.required"),
                    }}
                    render={(field) => (
                      <CustomAutoComplete
                        {...field}
                        fullWidth
                        id="orchestrationId"
                        label={t("orchestrator")}
                        InputLabelProps={{
                          shrink: !!watch("orchestratorId"),
                        }}
                        options={orchestrators ?? []}
                        optionLabel="name"
                        value={orchestrators?.find((item) => (watch("orchestratorId")) === (item?.id))}
                        onChange={(e) => setValue("orchestratorId", e?.id)}
                    />
                    )}
                />
          </FormControl>
          <FormControl fullWidth margin="none">
            <Controller
                    control={control}
                    name="resourceIds"
                    rules={{
                      required: t("queue.management.form.validation.required"),
                    }}
                    render={(field) => (
                      <CustomAutoComplete
                            {...field}
                            multiple
                            label={t("Resources")}
                            options={resources ?? []}
                            value={resources?.filter((resource) => watch("resourceIds")?.includes(resource?.id))}
                            onChange={(value) => {
                              setValue("resourceIds", value?.map((resource) => resource.id));
                            }}
                            error={!!errors.resourceIds?.message}
                            helperText={
                              !!errors.resourceIds && errors.resourceIds?.message
                            }
                            optionLabel={(item) => (item?.resourceDisplayName ? item?.resourceDisplayName : item?.resourceName)}
                        />
                    )}
                />
          </FormControl>
        </Box>
      )}

    </CustomDialog>
  );
}
