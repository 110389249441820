export const RESOURCE_BASE_URL = "/resources";
export const QUEUES_BASE_URL = "/queues";
export const ORCHESTRATORS_BASE_URL = "/orchestrators";
export const ORCHESTRATION_DASHBOARD_BASE_URL = "/orchestrationDashboard";
export const INTELLIGENT_ORCHESTRATORS_BASE_URL = "/intelligentorchestration";
export const EXECUTION_REQUEST_BASE_URL = "/executionRequests";
export const GROUPS_BASE_URL = "/groups";
export const TAGS_BASE_URL = "/tags";
export const USERS_BASE_URL = "/users";
export const OPPORTUNITY_ASSESSMENT_BASE_URL = "/opportunityAssessment";
