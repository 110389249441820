import React from "react";
import { useTranslation } from "react-i18next";
import { Bar } from "react-chartjs-2";
import { merge } from "lodash";
import ChartDataLabels from "chartjs-plugin-datalabels";
import {
  alignDataWithLabels, baseOptions,
  generateSimpleLabels,
  renderChart,
} from "pages/OrchestrationDashboard/Utils/Helpers";
import theme, { fontFamily } from "style/orchestratorTheme";
import useStyles from "../../style";

const metrics = ["achievedSlas", "missedSlas"];
const colors = [theme.palette.primary.main, theme.palette.secondary.main];

const SlaPercentage = ({ data, isLoading, filter }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const chartData = {
    labels: generateSimpleLabels(filter.selectedDurationValue, t),
    datasets: metrics.map((metric, index) => ({
      label: t(metric),
      data: alignDataWithLabels(
        filter.selectedDurationValue,
        data,
        metrics
      ).map((item) => item[metric]),
      backgroundColor: colors[index],
      barThickness: 20,
      borderWidth: 0,
    })),
  };

  const options = merge({}, baseOptions(metrics, colors, t), {
    plugins: {
      datalabels: {
        formatter: (value) => `${+value}%`,
        color: theme.palette.primary.main,
        align: "end",
        anchor: "end",
        font: {
          weight: 800,
          family: fontFamily,
          size: 12,
        },
      },
    },
  });
  return renderChart(
    isLoading,
    data,
    t("SLAs"),
    classes,
    <Bar
      data={chartData}
      options={options}
      height={90}
      plugins={[ChartDataLabels]}
    />
  );
};

export default SlaPercentage;
