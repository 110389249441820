import React from "react";
import Typography from "@material-ui/core/Typography";
import { LINE_CHART_TOOLTIP_OPTIONS } from "pages/newDashboard/dashboardUtils";
import CircularLoader from "components/Loaders/CircularLoader";
import DataNotFound from "components/DataNotFound";
import theme from "style/orchestratorTheme";

export const generateSimpleLabels = (period, t = (key) => key) => {
  const labels = { THIS_MONTH: "week", THIS_YEAR: "Q" };
  return [1, 2, 3, 4].map((num) => `${t(labels[period])}${num}`);
};
export const alignDataWithLabels = (
  period,
  data,
  metrics,
  periodKeyName = "period"
) => generateSimpleLabels(period).map((label) => {
    const matchingItem = data.find((item) => item[periodKeyName] === label) || {};
    const alignedItem = {};

    metrics.forEach((metric) => {
      alignedItem[metric] = matchingItem[metric] || 0;
    });

    return alignedItem;
  });

export const renderChart = (isLoading, data, title, classes, chart) => {
  if (isLoading) {
    return <CircularLoader height={90} size={23} />;
  }

  if (data?.length > 0) {
    return (
      <>
        <Typography className={classes.axesLabel}>{title}</Typography>
        {chart}
      </>
    );
  }

  return (
    <DataNotFound
      backgroundColor="transparent"
      boxShadow="none"
      iconHeight={50}
      iconWidth={50}
      height={90}
    />
  );
};

export const errorBarPlugin = (data, filter) => ({
  beforeDatasetsDraw(chart) {
    const { ctx } = chart;
    const mainData = data?.map((item) => item.avg);
    const errorMargins = data?.map((item) => item.standardDeviation);
    const labels = generateSimpleLabels(filter.selectedDurationValue);

    labels.forEach((label, index) => {
      const dataIndex = data.findIndex((item) => item.period === label);
      if (dataIndex === -1) return;

      const meta = chart.getDatasetMeta(0);
      const bar = meta.data[index];

      if (!bar) return;
      const { x } = bar._model;
      const yTop = chart.scales["y-axis-0"].getPixelForValue(
        mainData[dataIndex] + errorMargins[dataIndex]
      );
      const yBottom = chart.scales["y-axis-0"].getPixelForValue(
        mainData[dataIndex] - errorMargins[dataIndex] > 0
          ? mainData[dataIndex] - errorMargins[dataIndex]
          : 0
      );
      const barThickness = 40;
      ctx.beginPath();
      ctx.fillStyle = theme.custom.color.chartColor1;
      ctx.fillRect(x - barThickness / 2, yTop, barThickness, yBottom - yTop);
      ctx.closePath();
    });
  },
});

export const calculateMaxY = (data) => (data.reduce(
    (minItem, currentItem) => (currentItem?.avg > minItem?.avg ? currentItem : minItem),
    data[0]
    )?.standardDeviation ?? 0) + 100;

const createLegendWithCustomLabels = (metrics, colors, t) => ({
    display: true,
    position: "bottom",
    labels: {
        fontSize: 13,
        fontColor: theme.palette.primary.main,
        boxWidth: 13,
        generateLabels: () => metrics.map((metric, index) => ({
                text: t(metric),
                fillStyle: colors[index],
                hidden: false,
                lineJoin: "round",
                strokeStyle: colors[index],
            })),
    },
});

export const baseOptions = (metrics, colors, t) => ({
  layout: {
    padding: {
      top: 30,
    },
  },
    legend: createLegendWithCustomLabels(metrics, colors, t),
    tooltips: LINE_CHART_TOOLTIP_OPTIONS,
  scales: {
    xAxes: [
      {
        ticks: {
          fontColor: theme.palette.primary.main,
          fontSize: 14,
        },
        gridLines: {
          display: false,
        },
      },
    ],
    yAxes: [
      {
        ticks: {
          beginAtZero: true,
          fontColor: theme.palette.primary.main,
          maxTicksLimit: 5,
        },
        gridLines: {
          display: true,
          color: theme.palette.info.light,
          lineWidth: 2,
          zeroLineWidth: 0,
        },
      },
    ],
  },
});
