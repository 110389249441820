import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

const useFetchData = (fetchAction) => {
  const dispatch = useDispatch();
  const filter = useSelector(
    ({ filterOrchestrationDashboard }) => filterOrchestrationDashboard
  );
  const { t } = useTranslation();

  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const onSuccess = (res) => {
      setData(res?.data);
      setIsLoading(false);
  }
  const onError = () => {
      setIsLoading(false);
      toast.error(t("Unexpected Error"));
  }
  const fetchData = () => {
    setIsLoading(true);
    const updatedFilter = { ...filter };
    dispatch(
      fetchAction(
        updatedFilter,
        onSuccess,
        onError
      )
    );
  };

  useEffect(() => {
    fetchData();
  }, [filter]);

  return { data, isLoading };
};

export default useFetchData;
