import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";

const useFetchData = (fetchAction, baseFilter, sort, mock) => {
    const dispatch = useDispatch();
    // TODO replace with const [data, setData] = useState([]);
    const [data, setData] = useState(mock);
    const [isLoading, setIsLoading] = useState(false);

    const fetchData = () => {
        // TODO replace with setIsLoading(true);
        setIsLoading(false);
        const updatedFilter = {
          selectedDurationValue: baseFilter.selectedDurationValue,
          processIds: baseFilter?.process.map(({ id }) => id)?.join(),
          sort,
          tagsIds: baseFilter?.tags.map(({ id }) => id)?.join(),
          fromDate: baseFilter?.fromDate,
          toDate: baseFilter?.toDate
        };

        dispatch(
            fetchAction(updatedFilter, (res) => {
                setData(res?.data || res);
                setIsLoading(false);
            })
        );
    };

    useEffect(() => {
        fetchData();
    }, [baseFilter, sort]);

    return { data, isLoading };
};

export default useFetchData;
