import React from "react";
import Grid from "@material-ui/core/Grid";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { fetchNumberOfRequests, fetchProcessResponseTime, fetchRequestOutcomeDistribution } from "redux/actions/services";
import AnalyticsContainer from "pages/ExecutionRequest/ExecutionRequestsPage/Analytics/components/AnalyticsContainer";
import RequestOutcomeDistribution from "pages/ExecutionRequest/ExecutionRequestsPage/Analytics/RequestOutcomeDistribution";
import NumberOfRequests from "pages/ExecutionRequest/ExecutionRequestsPage/Analytics/NumberOfRequests";
import ProcessResponseTime from "pages/ExecutionRequest/ExecutionRequestsPage/Analytics/ProcessResponseTime";
import useStyles from "./style";

// TODO remove mockData
const mockData1 = [
  {
    orchestrationId: 1,
    orchestrationName: "Orchest 1",
    avgWaitTime: 150,
    stdDev: 5,
  },
  {
    orchestrationId: 2,
    orchestrationName: "Orchest 2",
    avgWaitTime: 300,
    stdDev: 40,
  },
  {
    orchestrationId: 3,
    orchestrationName: "Orchest 3",
    avgWaitTime: 450,
    stdDev: 60,
  },
  {
    orchestrationId: 4,
    orchestrationName: "Orchest 4",
    avgWaitTime: 200,
    stdDev: 30,
  },
  {
    orchestrationId: 5,
    orchestrationName: "Orchest 5",
    avgWaitTime: 100,
    stdDev: 10,
  },
];

const mockData2 = [
  {
    orchestrationId: 1,
    orchestrationName: "Orchest 1",
    stoppedRequests: 1,
    failedToStartRequests: 2,
    completedRequests: 3,
  },
  {
    orchestrationId: 2,
    orchestrationName: "Orchest 2",
    stoppedRequests: 5,
    failedToStartRequests: 2,
    completedRequests: 3,
  },
  {
    orchestrationId: 3,
    orchestrationName: "Orchest 3",
    stoppedRequests: 1,
    failedToStartRequests: 2,
    completedRequests: 3,
  },
  {
    orchestrationId: 4,
    orchestrationName: "Orchest 4",
    stoppedRequests: 1,
    failedToStartRequests: 2,
    completedRequests: 3,
  },
  {
    orchestrationId: 5,
    orchestrationName: "Orchest 5",
    stoppedRequests: 1,
    failedToStartRequests: 2,
    completedRequests: 3,
  },
];

const mockData3 = [
  { orchestrationId: 1, orchestrationName: "Orchest 1", activityHours: 5 },
  { orchestrationId: 2, orchestrationName: "Orchest 2", activityHours: 3.2 },
  { orchestrationId: 3, orchestrationName: "Orchest 3", activityHours: 4 },
  { orchestrationId: 4, orchestrationName: "Orchest 4", activityHours: 4.5 },
  { orchestrationId: 5, orchestrationName: "Orchest 5", activityHours: 3.5 },
];

const ProcessResponseTimeSortList = [
  { code: "avg", label: "avgWaitTimeAsc" },
  { code: "avg,desc", label: "avgWaitTimeDesc" },
];

const NumberOfRequestsSortList = [
  { code: "completedRequests", label: "completedRequestsAsc" },
  { code: "completedRequests,desc", label: "completedRequestsDesc" },
  { code: "stoppedRequests", label: "stoppedRequestsAsc" },
  { code: "stoppedRequests,desc", label: "stoppedRequestsDesc" },
  { code: "failedToStartRequests", label: "failedToStartRequestsAsc" },
  { code: "failedToStartRequests,desc", label: "failedToStartRequestsDesc" },
];

const RequestOutcomeSortList = [
  { code: "activity", label: "activityHoursAsc" },
  { code: "activity,desc", label: "activityHoursDesc" }
];

const Analytics = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const executionRequestsFilter = useSelector(
      ({ executionRequest }) => executionRequest
  );

  return (
    <Grid
      container
      item
      justify="space-between"
      spacing={2}
      className={classes.analyticsContainer}
    >
      <Grid item xs={4}>
        <AnalyticsContainer
          name={t("Process response time")}
          graph={<ProcessResponseTime />}
          sortList={ProcessResponseTimeSortList}
          fetchAction={fetchProcessResponseTime}
          baseFilter={executionRequestsFilter}
          mock={mockData1}
          defaultSort="avg,desc"
        />
      </Grid>
      <Grid item xs={4}>
        <AnalyticsContainer
          name={t("Number of requests")}
          graph={<NumberOfRequests />}
          sortList={NumberOfRequestsSortList}
          fetchAction={fetchNumberOfRequests}
          baseFilter={executionRequestsFilter}
          mock={mockData2}
          defaultSort="completedRequests,desc"
        />
      </Grid>
      <Grid item xs={4}>
        <AnalyticsContainer
          name={t("Request Outcome Distribution")}
          graph={<RequestOutcomeDistribution />}
          sortList={RequestOutcomeSortList}
          fetchAction={fetchRequestOutcomeDistribution}
          baseFilter={executionRequestsFilter}
          mock={mockData3}
          defaultSort="activity,desc"
        />
      </Grid>
    </Grid>
  );
};

export default Analytics;
