import React from "react";
import { useTranslation } from "react-i18next";
import { merge } from "lodash";
import {
  generateSimpleLabels,
  alignDataWithLabels,
  renderChart,
  errorBarPlugin,
  baseOptions,
  calculateMaxY,
} from "pages/OrchestrationDashboard/Utils/Helpers";
import { LINE_CHART_DATA_LABELS } from "pages/newDashboard/dashboardUtils";
import MixedChart from "pages/newDashboard/MixedChart";
import theme from "style/orchestratorTheme";
import useStyles from "../../style";

const metrics = ["Average Processing Time", "Maximum Processing Time"];
const colors = [theme.palette.primary.main, theme.custom.color.chartColor1];

const AvgProcessingTime = ({ data, isLoading, filter }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const chartData = {
    labels: generateSimpleLabels(filter.selectedDurationValue, t),
    datasets: [
      {
        data: alignDataWithLabels(
          filter.selectedDurationValue,
          data,
          ["avg"],
          "period"
        ).map((item) => item.avg),
        fill: false,
        borderColor: theme.palette.primary.main,
        borderWidth: 4,
        pointRadius: 5,
        pointBorderColor: theme.palette.background.default,
        pointBackgroundColor: theme.palette.primary.main,
        pointBorderWidth: 3,
      },
    ],
  };
  const options = merge({}, baseOptions(metrics, colors, t), {
    elements: {
      line: {
        tension: 0,
      },
    },
    scales: {
      yAxes: [
        {
          ticks: {
            max: calculateMaxY(data),
          },
        },
      ],
    },
    tooltips: {
      enabled: false
    },
    plugins: {
      datalabels: LINE_CHART_DATA_LABELS(),
    },
  });

  return renderChart(
    isLoading,
    data,
    t("Hours"),
    classes,
    <MixedChart
      data={chartData}
      options={options}
      height={90}
      width={null}
      customPlugins={errorBarPlugin(data, filter)}
    />
  );
};

export default AvgProcessingTime;
