import React, { useState } from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import ArrowForwardIosRoundedIcon from "@material-ui/icons/ArrowForwardIosRounded";
import { useTranslation } from "react-i18next";
import useFetchData from "pages/OrchestrationDashboard/Utils/useFetchData";
import { generateSimpleLabels } from "pages/OrchestrationDashboard/Utils/Helpers";
import { useSelector } from "react-redux";
import CustomButton from "components/CustomButton";
import GraphPopUp from "pages/OrchestrationDashboard/Components/GraphPopUp";
import useStyles from "../../style";

const OrchGraphContainer = ({
 name, graph, fetchAction, mock, config, mockDataTable, fetchActionTable, exportAction
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const filter = useSelector(
    ({ filterOrchestrationDashboard }) => filterOrchestrationDashboard
  );
  const [open, setOpen] = useState(false);

  const { data, isLoading } = useFetchData(fetchAction, mock);

  return (
    <Grid
      container
      direction="column"
      justify="space-between"
      className={classes.container}
      spacing={2}
    >
      <Grid container item justify="space-between" alignItems="center">
        <Grid item>
          <Typography className={classes.graphLabel}>{t(name)}</Typography>
        </Grid>
        <Grid item>
          <CustomButton
            view="primaryText"
            onClick={() => setOpen(true)}
            endIcon={
              <ArrowForwardIosRoundedIcon className={classes.moreIcon} />
            }
            className={classes.moreBtnLabel}
          >
            {t("See More")}
          </CustomButton>
        </Grid>
      </Grid>
      <Grid item>{React.cloneElement(graph, { isLoading, data, filter })}</Grid>
      <GraphPopUp
        open={open}
        mockDataTable={mockDataTable}
        config={config}
        labels={generateSimpleLabels(filter.selectedDurationValue, t)}
        periods={generateSimpleLabels(filter.selectedDurationValue)}
        name={name}
        filter={filter}
        handleDialogClose={() => setOpen(false)}
        fetchAction={fetchActionTable}
        exportAction={exportAction}
      />
    </Grid>
  );
};

export default OrchGraphContainer;
