import React from "react";

import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import FormHelperText from "@material-ui/core/FormHelperText";
import { MuiPickersUtilsProvider, DateTimePicker, DatePicker } from "@material-ui/pickers";
import { CalendarToday } from "@material-ui/icons";
import DateFnsUtils from "@date-io/date-fns";
import { Duration } from "util/constants";
import { getLocale } from "util";
import useStyles from "./style";
import { useTranslation } from "react-i18next";
import CustomSelectField from "../../../../components/FormFields/CustomSelectField";

const DateFilter = ({
  fromDate,
  onFromDateChange,
  toDate,
  onToDateChange,
  dateFormat,
  selectedDuration,
  onSelectDurationValue,
  showCustomDate,
  type,
  isAdmin,
  handleSelectPeriodChange,
  formControlClassName,
  invalidEndDate,
  invalidStartDate,
  futureEndDate,
  futureStartDate,
  isCustom = false,
  xs = 3,
  DurationList = Duration.list,
  showTime = true
}) => {
  const { t } = useTranslation()
  const classes = useStyles();
  // eslint-disable-next-line no-nested-ternary
  let selectStyle = !isAdmin
    // eslint-disable-next-line no-nested-ternary
    ? type === "completedItems"
      ? null
      : type === "exception"
        ? { paddingRight: "8px" }
        : null
    : type === "exception"
      ? { paddingRight: "8px" }
      : null;

  selectStyle = type === "dashboard" ? { paddingRight: "8px", maxWidth: "300px" } : selectStyle;

  const Component = showTime ? DateTimePicker : DatePicker;
  const handlePeriodChange = (event) => {
    const { value } = event.target;
    if (!value) return;
    onSelectDurationValue(event.target.value);
  };

  return (
    <>
      <Grid
        item
        xs={xs}
        className={classes.customDate}
        style={selectStyle}
      >
        <CustomSelectField
            options={DurationList}
            optionLabel="label"
            optionValue="value"
            value={selectedDuration}
            onChange={handleSelectPeriodChange ?? handlePeriodChange}
            variant="standard"
            label={t("Time period")}
            formControlClassName={formControlClassName}
            isCustom={isCustom}
        />
      </Grid>
      {showCustomDate && (
        <Grid container direction="row" item xs={xs * 2}>
          <Grid item xs={6} className={classes.dateInput}>
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={getLocale()}>
              <FormControl className={classes.customDateInput} fullWidth>
                <Component
                  label={t("reporting.from")}
                  InputProps={{ endAdornment: <CalendarToday className={isCustom ? classes.popupIcon : ""} fontSize="small" /> }}
                  className={isCustom ? classes.input : ""}
                  value={fromDate}
                  onChange={onFromDateChange}
                  maxDate={new Date()}
                  format={dateFormat}
                  fullWidth
                  showTodayButton
                  todayLabel={t("Today")}
                  cancelLabel={t("user.button.cancel")}
                  ampm={false}
                  DialogProps={{
                    className: classes.dateTimePicker,
                  }}
                />
                {invalidStartDate && <FormHelperText error>{t("start date cannot be newer than the end date")}</FormHelperText>}
                {futureStartDate && <FormHelperText error>{t("Start date cannot be in the future")}</FormHelperText>}
              </FormControl>
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid item xs={6} className={classes.dateInput}>
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={getLocale()}>
              <FormControl className={classes.customDateInput} fullWidth>
                <Component
                  label={t("reporting.to")}
                  InputProps={{ endAdornment: <CalendarToday className={isCustom ? classes.popupIcon : ""} fontSize="small" /> }}
                  className={isCustom ? classes.input : ""}
                  value={toDate}
                  onChange={onToDateChange}
                  showTodayButton
                  format={dateFormat}
                  fullWidth
                  cancelLabel={t("user.button.cancel")}
                  todayLabel={t("Today")}
                  ampm={false}
                  maxDate={new Date()}
                  classes={{
                    icon: classes.popupIcon
                  }}
                  DialogProps={{
                    className: classes.dateTimePicker,
                  }}
                />
                {invalidEndDate && <FormHelperText error>{t("end date cannot be earlier than the start date")}</FormHelperText>}
                {futureEndDate && <FormHelperText error>{t("end date cannot be in the future")}</FormHelperText>}
              </FormControl>
            </MuiPickersUtilsProvider>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default DateFilter;
