import React from "react";
import { useTranslation } from "react-i18next";
import { Bar } from "react-chartjs-2";
import { LINE_CHART_TOOLTIP_OPTIONS } from "pages/newDashboard/dashboardUtils";
import CircularLoader from "components/Loaders/CircularLoader";
import DataNotFound from "components/DataNotFound";
import theme from "style/orchestratorTheme";
import Typography from "@material-ui/core/Typography";
import useStyles from "pages/ExecutionRequest/ExecutionRequestsPage/Analytics/style";

const NumberOfRequests = ({ data, isLoading }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const metrics = [
    "stoppedRequests",
    "failedToStartRequests",
    "completedRequests",
  ];

  const colors = [
    theme.custom.color.chartColor1,
    theme.palette.secondary.main,
    theme.palette.primary.main,
  ];

  const chartData = {
    labels: data?.map((item) => item?.orchestrationName),
    datasets: metrics.map((metric, index) => ({
      label: t(metric),
      data: data.map((item) => item[metric] || 0),
      backgroundColor: colors[index],
      barThickness: 28,
      borderWidth: 0,
    })),
  };

  const options = {
    layout: {
      padding: {
        top: 30,
      },
    },
    legend: {
      display: true,
      position: "bottom",
      labels: {
        fontSize: 13,
        fontColor: theme.palette.primary.main,
        boxWidth: 13,
      },
    },
    tooltips: {
      ...LINE_CHART_TOOLTIP_OPTIONS,
    },
    scales: {
      xAxes: [
        {
          ticks: {
            fontColor: theme.palette.primary.main,
          },
          offset: true,
          gridLines: {
            display: false,
          },
          stacked: true,
        },
      ],
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
            stepSize: 1,
            fontColor: theme.palette.primary.main,
          },
          gridLines: {
            display: true,
            color: theme.palette.info.light,
            lineWidth: 2,
            zeroLineWidth: 0,
          },
          stacked: true,
        },
      ],
    },
  };

  let content;

  if (isLoading) {
    content = <CircularLoader height={300} size={23} />;
  } else if (data?.length > 0) {
    content = (
      <>
        <Typography className={classes.axesLabel}>&nbsp;</Typography>
        <Bar data={chartData} options={options} height={180} />
      </>
    );
  } else {
    content = (
      <DataNotFound
        backgroundColor="transparent"
        boxShadow="none"
        iconHeight={100}
        iconWidth={100}
        height={300}
      />
    );
  }

  return content;
};

export default NumberOfRequests;
