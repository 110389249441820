// Custom plugin to draw error bar
const errorBarPlugin = (data) => ({
    afterDatasetsDraw(chart) {
        const { ctx } = chart;
        const mainData = data?.map((item) => item?.avg);
        const errorMargins = data?.map((item) => item?.standardDeviation);

        mainData.forEach((value, index) => {
            const meta = chart.getDatasetMeta(0);
            const bar = meta.data[index];

            if (!bar) return;
            const { x } = bar._model;
            const yTop = chart.scales["y-axis-0"].getPixelForValue(mainData[index] + errorMargins[index]);
            const yBottom = chart.scales["y-axis-0"].getPixelForValue(mainData[index] - errorMargins[index]);

            ctx.beginPath();
            ctx.moveTo(x - 14, yTop);
            ctx.lineTo(x + 14, yTop);
            ctx.strokeStyle = "black";
            ctx.lineWidth = 1;
            ctx.stroke();

            ctx.beginPath();
            ctx.moveTo(x - 14, yBottom);
            ctx.lineTo(x + 14, yBottom);
            ctx.strokeStyle = "black";
            ctx.lineWidth = 1;
            ctx.stroke();

            ctx.beginPath();
            ctx.moveTo(x, yTop);
            ctx.lineTo(x, yBottom);
            ctx.strokeStyle = "black";
            ctx.lineWidth = 1;
            ctx.stroke();
        });
    },
});

export default errorBarPlugin;

export const calculateMaxErrorMargin = (data) => {
    const maxValue = data.reduce((maxItem, currentItem) => (currentItem.avg > maxItem.avg ? currentItem : maxItem), data[0])?.standardDeviation;
    return maxValue > 30 ? maxValue : 30;
};
