import {
  URL_BASE_EXPORT,
  URL_ORCHESTRATIONS_METRICS,
  URL_RESOURCES_METRICS,
} from "redux/constants/endpoints";
import {
  EXPORT_RESOURCES_EXECUTION_TIME,
  EXPORT_RESOURCES_RESPONSE,
  EXPORT_RESOURCES_SESSIONS_ACTIVITY,
  EXPORT_SLAS_STATS,
  FETCH_RESOURCES_EXECUTION_TIME,
  FETCH_RESOURCES_EXECUTION_TIME_TABLE,
  FETCH_RESOURCES_RESPONSE,
  FETCH_RESOURCES_RESPONSE_TABLE,
  FETCH_RESOURCES_SESSIONS_ACTIVITY,
  FETCH_RESOURCES_SESSIONS_ACTIVITY_TABLE,
  FETCH_SLAS_STATS,
  FETCH_SLAS_STATS_TABLE,
} from "redux/constants";
import {
  resourcesExecutionTime,
  resourcesExecutionTimeTable,
  resourcesResponse,
  resourcesResponseTable,
  resourcesSessionsActivity,
  resourcesSessionsActivityTable,
  slasStats,
  slasStatsTable,
} from "util/configs/backendEntities";

export const fetchSlasStats = (filter, onSuccess, onError) => ({
  type: FETCH_SLAS_STATS,
  request: {
    url: `${URL_ORCHESTRATIONS_METRICS}/${slasStats}`,
    params: {
      timePeriod: filter.selectedDurationValue,
      processesIds: filter.process.join(","),
      tagsIds: filter.tags.join(","),
    },
  },
  meta: {
    onSuccess: (response) => {
      if (onSuccess) onSuccess(response);
      return response;
    },
    onError: (error) => {
      if (onError) onError();
      throw error;
    },
  },
});

export const fetchSlasStatsTable = (filter, onSuccess, onError) => ({
  type: FETCH_SLAS_STATS_TABLE,
  request: {
    url: encodeURI(
        `${URL_ORCHESTRATIONS_METRICS}/${slasStatsTable}${
            filter.searchQuery ? `?searchCriteria=${filter.searchQuery}` : ""
        }`
    ),
    params: {
        timePeriod: filter.selectedDurationValue,
        processesIds: filter.process.join(","),
        tagsIds: filter.tags.join(","),
    },
  },
  meta: {
    onSuccess: (response) => {
      if (onSuccess) onSuccess(response);
      return response;
    },
    onError: (error) => {
      if (onError) onError();
      throw error;
    },
  },
});

export const exportSlasStats = (params = {}) => {
  const {
    searchQuery: searchCriteria,
    selectedDurationValue,
    process,
    tags,
    onSuccess,
    onError,
  } = params;
  return {
    type: EXPORT_SLAS_STATS,
    request: {
      url: encodeURI(
        `${URL_BASE_EXPORT}${slasStatsTable}${
          searchCriteria ? `?searchCriteria=${searchCriteria}` : ""
        }`
      ),
      params: {
        timePeriod: selectedDurationValue,
        processesIds: process.join(","),
        tagsIds: tags.join(","),
      },
    },
    meta: {
      onSuccess: (response) => {
        if (onSuccess) onSuccess(response);
        return response;
      },
      onError: (error) => {
        if (onError) onError(error);
        throw error;
      },
    },
  };
};

export const fetchResourcesSessionsActivity = (filter, onSuccess, onError) => ({
  type: FETCH_RESOURCES_SESSIONS_ACTIVITY,
  request: {
    url: `${URL_RESOURCES_METRICS}/${resourcesSessionsActivity}`,
    params: {
        timePeriod: filter.selectedDurationValue,
        processesIds: filter.process.join(","),
        tagsIds: filter.tags.join(","),
    },
  },
  meta: {
    onSuccess: (response) => {
      if (onSuccess) onSuccess(response);
      return response;
    },
    onError: (error) => {
      if (onError) onError();
      throw error;
    },
  },
});

export const fetchResourcesSessionsActivityTable = (filter, onSuccess, onError) => ({
  type: FETCH_RESOURCES_SESSIONS_ACTIVITY_TABLE,
  request: {
    url: encodeURI(
        `${URL_RESOURCES_METRICS}/${resourcesSessionsActivityTable}${
            filter.searchQuery ? `?searchCriteria=${filter.searchQuery}` : ""
        }`
    ),
    params: {
        timePeriod: filter.selectedDurationValue,
        processesIds: filter.process.join(","),
        tagsIds: filter.tags.join(","),
    },
  },
  meta: {
    onSuccess: (response) => {
      if (onSuccess) onSuccess(response);
      return response;
    },
    onError: (error) => {
      if (onError) onError();
      throw error;
    },
  },
});

export const exportResourcesSessionsActivity = (params = {}) => {
  const {
    searchQuery: searchCriteria,
    selectedDurationValue,
    process,
    tags,
    onSuccess,
    onError,
  } = params;
  return {
    type: EXPORT_RESOURCES_SESSIONS_ACTIVITY,
    request: {
      url: encodeURI(
        `${URL_BASE_EXPORT}${resourcesSessionsActivity}${
          searchCriteria ? `?searchCriteria=${searchCriteria}` : ""
        }`
      ),
      params: {
        timePeriod: selectedDurationValue,
        processesIds: process.join(","),
        tagsIds: tags.join(","),
      },
    },
    meta: {
      onSuccess: (response) => {
        if (onSuccess) onSuccess(response);
        return response;
      },
      onError: (error) => {
        if (onError) onError(error);
        throw error;
      },
    },
  };
};

export const fetchResourcesResponse = (filter, onSuccess, onError) => ({
  type: FETCH_RESOURCES_RESPONSE,
  request: {
    url: `${URL_RESOURCES_METRICS}/${resourcesResponse}`,
    params: {
        timePeriod: filter.selectedDurationValue,
        processesIds: filter.process.join(","),
        tagsIds: filter.tags.join(","),
    },
  },
  meta: {
    onSuccess: (response) => {
      if (onSuccess) onSuccess(response);
      return response;
    },
    onError: (error) => {
      if (onError) onError();
      throw error;
    },
  },
});

export const fetchResourcesResponseTable = (filter, onSuccess, onError) => ({
  type: FETCH_RESOURCES_RESPONSE_TABLE,
  request: {
    url: encodeURI(
        `${URL_RESOURCES_METRICS}/${resourcesResponseTable}${
            filter.searchQuery ? `?searchCriteria=${filter.searchQuery}` : ""
        }`
    ),
    params: {
        timePeriod: filter.selectedDurationValue,
        processesIds: filter.process.join(","),
        tagsIds: filter.tags.join(","),
    },
  },
  meta: {
    onSuccess: (response) => {
      if (onSuccess) onSuccess(response);
      return response;
    },
    onError: (error) => {
      if (onError) onError();
      throw error;
    },
  },
});

export const exportResourcesResponse = (params = {}) => {
  const {
    searchQuery: searchCriteria,
    selectedDurationValue,
    process,
    tags,
    onSuccess,
    onError,
  } = params;
  return {
    type: EXPORT_RESOURCES_RESPONSE,
    request: {
      url: encodeURI(
        `${URL_BASE_EXPORT}${resourcesResponse}${
          searchCriteria ? `?searchCriteria=${searchCriteria}` : ""
        }`
      ),
      params: {
        timePeriod: selectedDurationValue,
        processesIds: process.join(","),
        tagsIds: tags.join(","),
      },
    },
    meta: {
      onSuccess: (response) => {
        if (onSuccess) onSuccess(response);
        return response;
      },
      onError: (error) => {
        if (onError) onError(error);
        throw error;
      },
    },
  };
};

export const fetchResourcesExecutionTime = (filter, onSuccess, onError) => ({
  type: FETCH_RESOURCES_EXECUTION_TIME,
  request: {
    url: `${URL_RESOURCES_METRICS}/${resourcesExecutionTime}`,
    params: {
        timePeriod: filter.selectedDurationValue,
        processesIds: filter.process.join(","),
        tagsIds: filter.tags.join(","),
    },
  },
  meta: {
    onSuccess: (response) => {
      if (onSuccess) onSuccess(response);
      return response;
    },
    onError: (error) => {
      if (onError) onError();
      throw error;
    },
  },
});

export const fetchResourcesExecutionTimeTable = (filter, onSuccess, onError) => ({
  type: FETCH_RESOURCES_EXECUTION_TIME_TABLE,
  request: {
    url: encodeURI(
        `${URL_RESOURCES_METRICS}/${resourcesExecutionTimeTable}${
            filter.searchQuery ? `?searchCriteria=${filter.searchQuery}` : ""
        }`
    ),
    params: {
        timePeriod: filter.selectedDurationValue,
        processesIds: filter.process.join(","),
        tagsIds: filter.tags.join(","),
    },
  },
  meta: {
    onSuccess: (response) => {
      if (onSuccess) onSuccess(response);
      return response;
    },
    onError: (error) => {
      if (onError) onError();
      throw error;
    },
  },
});

export const exportResourcesExecutionTime = (params = {}) => {
  const {
    searchQuery: searchCriteria,
    selectedDurationValue,
    process,
    tags,
    onSuccess,
    onError,
  } = params;
  return {
    type: EXPORT_RESOURCES_EXECUTION_TIME,
    request: {
      url: encodeURI(
        `${URL_BASE_EXPORT}${resourcesExecutionTime}${
          searchCriteria ? `?searchCriteria=${searchCriteria}` : ""
        }`
      ),
      params: {
        timePeriod: selectedDurationValue,
        processesIds: process.join(","),
        tagsIds: tags.join(","),
      },
    },
    meta: {
      onSuccess: (response) => {
        if (onSuccess) onSuccess(response);
        return response;
      },
      onError: (error) => {
        if (onError) onError(error);
        throw error;
      },
    },
  };
};
