import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
    tableContainer: {
        marginTop: "16px",
        boxShadow: "none"
    },
    rootHeader: {
        backgroundColor: theme.custom.color.transparent,
    },
    tableHeader: {
        backgroundColor: theme.palette.primary.light,
        fontWeight: 400,
        fontSize: "13px",
        border: "none"
    },
    topRightRadius: {
        borderTopRightRadius: "5px"
    },
    topLeftRadius: {
      borderTopLeftRadius: "5px"
    },
    subHeader: {
        backgroundColor: theme.custom.color.staticInput,
        border: "none",
        fontWeight: 700,
        fontSize: "15px",
    },
    emptyCell: {
        border: "none"
    },
    tableCell: {
        border: `1px solid ${theme.palette.primary.light}`,
        borderLeft: 0,
        borderRight: 0,
    },
    rowTitleText: {
        color: theme.palette.primary.main,
        fontSize: "13px",
        fontWeight: 700,
    },
    infoText: {
        color: theme.palette.primary.main,
        fontSize: "15px",
        fontWeight: 400,
    },
    cellEllipsis: {
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        maxWidth: 130
    },
}));
