import React from "react";
import Typography from "@material-ui/core/Typography";
import { useTranslation } from "react-i18next";
import { Bar } from "react-chartjs-2";
import { LINE_CHART_TOOLTIP_OPTIONS } from "pages/newDashboard/dashboardUtils";
import CircularLoader from "components/Loaders/CircularLoader";
import DataNotFound from "components/DataNotFound";
import theme from "style/orchestratorTheme";
import useStyles from "../style";

const RequestOutcomeDistribution = ({ data, isLoading }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const chartData = {
    labels: data?.map((item) => item?.orchestrationName),
    datasets: [
      {
        data: data?.map((item) => item?.activityHours),
        backgroundColor: theme.palette.primary.main,
        barThickness: 28,
      },
    ],
  };

  const options = {
    layout: {
      padding: {
        top: 30,
      },
    },
    legend: {
      display: false,
    },
    tooltips: {
      ...LINE_CHART_TOOLTIP_OPTIONS,
    },
    scales: {
      xAxes: [
        {
          ticks: {
            fontColor: theme.palette.primary.main,
            display: true,
          },
          offset: true,
          gridLines: {
            display: false,
          },
        },
      ],
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
            fontColor: theme.palette.primary.main,
          },
          gridLines: {
            display: true,
            color: theme.palette.info.light,
            lineWidth: 2,
            zeroLineWidth: 0,
          },
        },
      ],
    },
  };

  let content;

  if (isLoading) {
    content = <CircularLoader height={300} size={23} />;
  } else if (data?.length > 0) {
    content = (
      <>
        <Typography className={classes.axesLabel}>{t("Hours")}</Typography>
        <Bar data={chartData} options={options} height={180} />
      </>
    );
  } else {
    content = (
      <DataNotFound
        backgroundColor="transparent"
        boxShadow="none"
        iconHeight={100}
        iconWidth={100}
        height={300}
      />
    );
  }

  return content;
};

export default RequestOutcomeDistribution;
