import React from "react";
import Table from "@material-ui/core/Table";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Paper from "@material-ui/core/Paper";
import Tooltip from "@material-ui/core/Tooltip";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import useStyles from "./style";

const GenericTable = ({
 data, config, mainHeader, periods
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <TableContainer component={Paper} className={classes.tableContainer}>
      <Table>
        <TableHead classes={{ root: classes.rootHeader }}>
          <TableRow>
            <TableCell classes={{ root: classes.emptyCell }} />
            {mainHeader.map((title, index) => (
              <TableCell
                key={index}
                colSpan={config.statsKeys.length}
                className={clsx(
                  classes.tableHeader,
                  index === mainHeader.length && classes.topRightRadius,
                  !index && classes.topLeftRadius
                )}
                padding="none"
                align="center"
              >
                {title}
              </TableCell>
            ))}
          </TableRow>
          <TableRow>
            <TableCell
              align="center"
              className={clsx(classes.subHeader, classes.topLeftRadius)}
            >
              {t(config.subHeader[0])}
            </TableCell>
            {mainHeader.flatMap(() => config.subHeader.slice(1).map((subHeader, subIndex) => (
              <Tooltip title={t(subHeader)} placement="top">
                <TableCell
                  key={`${subHeader}-${subIndex}`}
                  className={clsx(classes.subHeader, classes.cellEllipsis)}
                  align="center"
                >
                  {t(subHeader)}
                </TableCell>
              </Tooltip>
              )))}
          </TableRow>
        </TableHead>
        <TableBody>
          {data?.map((row) => (
            <TableRow key={row[config.rowKey]}>
              <TableCell align="center" className={clsx(classes.tableCell, classes.rowTitleText)}>
                {row[config.rowLabel]}
              </TableCell>
              {periods.map((period) => {
                const stats = row[config.statsKey].find((stat) => stat[config.periodKeyName] === period);
                return config.statsKeys.map((key) => (
                  <TableCell
                      align="center"
                      key={`${key}-${row[config.rowKey]}-${period}`}
                      className={clsx(classes.tableCell, classes.infoText)}
                  >
                    {stats ? stats[key] : "---"}
                  </TableCell>
                ));
              })}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default GenericTable;
