import React, { useCallback, useEffect, useState } from "react";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { debounce } from "lodash";
import { useDispatch } from "react-redux";
import { fetchExportedFiles } from "redux/actions/services";
import CustomDialog from "components/CustomDialog";
import DataNotFound from "components/DataNotFound";
import CircularLoader from "components/Loaders/CircularLoader";
import ExportButton from "components/ExportButton";
import GenericTable from "pages/OrchestrationDashboard/Components/GenericTable";
import { SearchFilter } from "components/Filter";
import useStyles from "../../style";

export default function GraphPopUp({
  open,
  handleDialogClose,
  labels,
  periods,
  config,
  name,
  filter,
  fetchAction,
  exportAction
}) {
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [data, setData] = useState([]);

  const debouncer = useCallback(
    debounce((newVale) => setSearchQuery(newVale), 500),
    []
  );

  const handleSearchQueryChange = (e) => {
    const { value } = e.target;
    setSearchValue(value);
    debouncer(value);
  };
  const fetchSuccess = (res) => {
    setData(res?.data);
    setIsLoading(false);
  }
  const fetchError = () => {
    setIsLoading(false);
    toast.error(t("Unexpected Error"));
  }
  useEffect(() => {
    if (open) {
      setIsLoading(true);
      const params = {
        searchQuery,
        ...filter,
      };
      dispatch(fetchAction(params, fetchSuccess, fetchError))
    }
  }, [searchQuery, filter?.selectedDurationValue, open]);

  const onExportSuccess = () => {
    dispatch(fetchExportedFiles());
    toast.success(t("export.successful.notification"));
  };

  const onExportError = () => {
    toast.error(t("Unexpected Error"));
  };

  const handleExport = () => {
    const params = {
      searchQuery,
      period: filter?.selectedDurationValue,
    };
    dispatch(
      exportAction({
        ...params,
        onSuccess: onExportSuccess,
        onError: onExportError,
      })
    );
  };

  return (
    <CustomDialog
      onClose={handleDialogClose}
      aria-labelledby="customized-dialog-title"
      maxWidth="md"
      fullWidth
      open={open}
      disableBackdropClick
      title={
        <Typography className={classes.dialogTitle} component="span">
          {name}
        </Typography>
      }
      withDivider
      withCloseButton
      borderRadius={10}
    >
      <Grid container direction="column" spacing={2}>
        <Grid container item spacing={3}>
          <Grid item xs={3} className={classes.searchFilter}>
            <SearchFilter
              value={searchValue}
              callback={handleSearchQueryChange}
            />
          </Grid>
          {(data?.length > 0) && (
          <Box
            marginLeft="auto"
            pr={1.5}
            pt={3}
            display="flex"
            alignItems="center"
          >
            <ExportButton onClick={handleExport} />
          </Box>
          )}
        </Grid>
        <Grid container item className={classes.tableContainer} alignItems="center">
          {(() => {
            if (isLoading) {
              return <CircularLoader height="100%" />;
            }
            if (data?.length === 0) {
              return (
                <DataNotFound iconHeight={120} padding={50} />
              );
            }
            return (
              <GenericTable
                mainHeader={labels}
                periods={periods}
                config={config}
                data={data}
              />
            );
          })()}
        </Grid>
      </Grid>
    </CustomDialog>
  );
}
