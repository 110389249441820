import React from "react";
import { Line } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";
import ChartStyles from "chartjs-plugin-style";

export default function MixedChart({
 data, options, height = 300, width = 700, customPlugins = [],

}) {
  data.datasets[0].data = data?.datasets[0]?.data?.map((value) => parseFloat(value.toFixed(2)));

  return (
    <Line
      height={height}
      width={width}
      plugins={[ChartDataLabels, ChartStyles, customPlugins]}
      data={data}
      options={options}
    />
  );
}
