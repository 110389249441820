import SlaPercentage from "pages/OrchestrationDashboard/Graphs/SlaPercentage";
import {
    exportResourcesExecutionTime,
    exportResourcesResponse,
    exportResourcesSessionsActivity,
    exportSlasStats,
    fetchResourcesExecutionTime,
    fetchResourcesExecutionTimeTable,
    fetchResourcesResponse,
    fetchResourcesResponseTable,
    fetchResourcesSessionsActivity,
    fetchResourcesSessionsActivityTable,
    fetchSlasStats,
    fetchSlasStatsTable
} from "redux/actions/services/orchestrationDashboard";
import React from "react";
import ResourceSessionActivity from "pages/OrchestrationDashboard/Graphs/ResourceSessionActivity";
import AvgProcessingTime from "pages/OrchestrationDashboard/Graphs/AvgProcessingTime";
import ResourceExecutionTime from "pages/OrchestrationDashboard/Graphs/ResourceExecutionTime";

const config1 = {
    rowKey: "orchestrationId",
    rowLabel: "orchestrationName",
    statsKey: "slaStats",
    statsKeys: ["achievedSlas", "missedSlas"],
    subHeader: ["Orchestration", "Achieved", "Missed"],
    periodKeyName: "period"
};

const config2 = {
    rowKey: "resourceId",
    rowLabel: "resourceName",
    statsKey: "resourceActivities",
    statsKeys: ["stoppedSessions", "completedSessions", "failedSessions"],
    subHeader: ["Resource", "stoppedSessions", "completedSessions", "failedSessions"],
    periodKeyName: "period"
};

const config3 = {
    rowKey: "resourceId",
    rowLabel: "resourceName",
    statsKey: "responseTimeStats",
    statsKeys: ["avg", "standardDeviation"],
    subHeader: ["Resource", "Average Processing Time", "Maximum Processing Time"],
    periodKeyName: "period"
};

const config4 = {
    rowKey: "resourceId",
    rowLabel: "resourceName",
    statsKey: "resourcesExecutionTime",
    statsKeys: ["totalExecutionTimeHours", "totalReactionTimeHours", "totalPreemptionTimeHours"],
    subHeader: ["Resource", "totalExecutionTimeHours", "totalReactionTimeHours", "totalPreemptionTimeHours"],
    periodKeyName: "period"
};

const graphsInfos = [
    {
        name: "Percentage of SLAs achieved & missed",
        graph: <SlaPercentage />,
        config: config1,
        fetchAction: fetchSlasStats,
        fetchActionTable: fetchSlasStatsTable,
        exportAction: exportSlasStats,
    },
    {
        name: "Resource session activity",
        graph: <ResourceSessionActivity />,
        config: config2,
        fetchAction: fetchResourcesSessionsActivity,
        fetchActionTable: fetchResourcesSessionsActivityTable,
        exportAction: exportResourcesSessionsActivity,
    },
    {
        name: "Average processing time",
        graph: <AvgProcessingTime />,
        config: config3,
        fetchAction: fetchResourcesResponse,
        fetchActionTable: fetchResourcesResponseTable,
        exportAction: exportResourcesResponse,
    },
    {
        name: "Average Resource Execution Time",
        graph: <ResourceExecutionTime />,
        config: config4,
        fetchAction: fetchResourcesExecutionTime,
        fetchActionTable: fetchResourcesExecutionTimeTable,
        exportAction: exportResourcesExecutionTime,
    }
];
export default graphsInfos;
