import React, { useEffect, useRef, useState } from "react";
import Typography from "@material-ui/core/Typography";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Chip from "@material-ui/core/Chip";
import { Doughnut } from "react-chartjs-2";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import { format } from "date-fns";
import { useDispatch, useSelector } from "react-redux";
import { getQuery } from "@redux-requests/core";
import { fetchDashboardProcessesStatus } from "redux/actions/services/dashboard";
import renderValue, {
    renderContent,
    DOUGHNUT_CHART_DATASET_OPTION,
    DOUGHNUT_CHART_DATASET_DATA, translationXValue, translationYValue, MONITORING_TIME, centeredCoordinates
} from "pages/newDashboard/dashboardUtils";
import { isEnvParamEnabled } from "util/index";
import InformationIcon from "components/InformationIcon";
import { handleRequestMonitoringMode } from "pages/Dashboard/dashboardUtils";
import LightCircle from "assets/NewDashboardIcons/lightCircle.svg";
import useStyles from "../../style";

const Processes = () => {
  const { t } = useTranslation();
  const [percentage, setPercentage] = useState(0);
    const [radius, setRadius] = useState(0);
    const [translationX, setTranslationX] = useState(translationXValue(percentage, radius));
    const [translationY, setTranslationY] = useState(translationYValue(percentage, radius));
  const classes = useStyles({ translationX, translationY, percentage })();
  const [processesWorking, setProcessesWorking] = useState();
  const [processesNotWorking, setProcessesNotWorking] = useState();
  const [processesTotal, setProcessesTotal] = useState();
  const [schedulesStates, setSchedulesStates] = useState();
  const isLoading = useSelector((state) => getQuery(state, { type: "FETCH_DASHBOARD_PROCESSES_STATUS_COUNT" }).loading);
  const dispatch = useDispatch();
  const filterDashboard = useSelector(
    ({ dashboardFilters }) => dashboardFilters,
  );
  const isRiyadBankEnvEnabled = isEnvParamEnabled("REACT_APP_DISPLAY_RIYAD_BANK_LOGO");
  const fetchProcesses = () => {
    dispatch(
      fetchDashboardProcessesStatus(filterDashboard, isRiyadBankEnvEnabled, (res) => {
        setProcessesWorking(res?.data?.countWorking);
        setProcessesNotWorking(res?.data?.countIdle);
        setProcessesTotal(res?.data?.countTotal);
        setSchedulesStates(res?.data);
      }),
    );
  };

  const fetchIntervalRef = useRef(null);
  useEffect(() => {
    handleRequestMonitoringMode(MONITORING_TIME, fetchIntervalRef, fetchProcesses)
    return () => {
        clearInterval(fetchIntervalRef.current)
    }
  }, [filterDashboard])

  useEffect(() => {
    if (filterDashboard?.selectedDurationValue === "DAY") {
      fetchProcesses();
    }
  }, [filterDashboard]);

  useEffect(() => {
    if (processesNotWorking + processesWorking) {
      setPercentage((processesWorking / (processesWorking + processesNotWorking)) * 100)
    }
  }, [processesWorking, processesNotWorking]);

  useEffect(() => {
      setTranslationX(translationXValue(percentage, radius))
      setTranslationY(translationYValue(percentage, radius))
  }, [percentage, radius]);

  const ProcessesChart = () => (
    <Box component={Grid} container item alignItems="center" direction="column" spacing={2} position="relative">
      <Grid item className={classes.chartContainer}>
        <Doughnut plugins={centeredCoordinates(setRadius, "text_center_doughnut_processes")} data={DOUGHNUT_CHART_DATASET_DATA(percentage, "light")} options={DOUGHNUT_CHART_DATASET_OPTION} />
        <Box
          className={classes.centeredContent}
          textAlign="center"
          id="text_center_doughnut_processes"
        >
          <img src={LightCircle} alt="circle" className={classes.circleImage} key={`chart-percentage-${percentage}-${translationX}-${translationY}`} />
          <Typography className={classes.textBold}>
            {renderValue({
                value: processesTotal,
            })}
          </Typography>
          <Typography className={classes.textLight}>
            {isRiyadBankEnvEnabled ? t("all.processes") : t("Schedules")}
          </Typography>
        </Box>
      </Grid>
      <Grid container item justify="space-between" alignItems="center">
        <Grid item xs={9}>
          <InformationIcon
            titleContent={schedulesStates?.missing && schedulesStates?.missing.length > 0 ? schedulesStates?.missing?.map((item) => (
              <Box key={item?.id}>
                {`${item?.name} - ${format(new Date(item?.startDate), "HH:mm:ss")}`}
              </Box>
            )) : ""}
            originalContent={(

              <Typography className={classes.textRegular}>
                {t("notWorking.processes.status")}
              </Typography>
            )}
            placement="left"
        />
        </Grid>
        <Grid item xs={2}>
          <Chip label={renderValue({ value: processesNotWorking })} className={clsx(classes.chip, classes.defaultChip)} />
        </Grid>
      </Grid>
      <Grid container item justify="space-between" alignItems="center">
        <Grid item xs={9}>
          <InformationIcon
                titleContent={schedulesStates?.execPerSchedule && schedulesStates?.execPerSchedule.length > 0 ? schedulesStates?.execPerSchedule?.map((item) => (
                  <Box key={item?.id}>
                    {`${item?.name} - ${item?.countExecs} ${t("executions found")}`}
                  </Box>
                )) : ""}
                originalContent={(

                  <Typography className={classes.textRegular}>
                    {t("working.processes.status")}
                  </Typography>
                )}
                placement="left"
            />
        </Grid>
        <Grid item xs={2}>
          <Chip label={renderValue({ value: processesWorking })} className={clsx(classes.chip, classes.lightChip)} />
        </Grid>
      </Grid>
    </Box>
  );

  return (
    <Box className={classes.cardContainer}>
      <InformationIcon
        titleContent={t("Processes.activity.tooltip")}
        originalContent={(
          <CardHeader
            title={t("Schedules health")}
            classes={{
                title: classes.cardHeaderTitle,
            }}
          />
        )}
        placement="left"
      />

      <CardContent>
        { renderContent(isLoading, [processesWorking, processesNotWorking], <ProcessesChart />) }
      </CardContent>
    </Box>
  );
};

export default Processes;
