import {
    CREATE_ORCHESTRATOR,
    DELETE_LIST_ORCHESTRATORS,
    DELETE_ORCHESTRATOR_BY_ID,
    FETCH_ORCHESTRATOR,
    FETCH_ORCHESTRATOR_CONFIG_STATE,
    FETCH_ORCHESTRATOR_STATUS,
    FETCH_ORCHESTRATORS,
    FETCH_ORCHESTRATORS_NAMES_AND_IDS,
    GET_REMAINING_LICENCES_FOR_ORCHESTRATOR,
    INIT_ORCHESTRATOR_DATABASE,
    INIT_ORCHESTRATOR_SYNCHRO,
    IS_ENOUGH_LICENSES,
    TEST_ORCHESTRATOR_CONNECTION,
    TRIGGER_UPDATE_IN_ORCHESTRATOR,
    UPDATE_ORCHESTRATOR,
    UPDATE_ORCHESTRATOR_STATUS,
    TEST_ORCHESTRATOR_UIPATH_CONNECTION,
    FETCH_VIEWS_COUNT,
    TEST_ORCHESTRATOR_IRIS_CONNECTION,
    FETCH_ORCHESTRATOR_PROCESSES
} from "redux/constants";
import {
 URL_BP_CONNECTOR, URL_ORCHESTRATOR, URL_IRIS_CONNECTOR, URL_SR_QUEUES_MNGT, URL_SYNCHRO, URL_UIPATH_CONNECTOR
} from "redux/constants/endpoints";

export const deleteOrchestrators = (idOrchestrators, onSuccess) => ({
    type: DELETE_LIST_ORCHESTRATORS,
    request: {
        url: `${URL_ORCHESTRATOR}/`,
        method: "delete",
        params: { idOrchestrators: idOrchestrators.join(",") },
    },
    meta: {
        onSuccess: () => {
            if (onSuccess) {
                onSuccess();
            }
        },
    },
});
export const initOrchestratorSynchro = (orchestratorId, onSuccess, onError) => ({
    type: INIT_ORCHESTRATOR_SYNCHRO,
    request: {
        url: `${URL_SYNCHRO}createConnectors/${orchestratorId}`,
        method: "post",
        data: {
            orchestratorId,
        },
        headers: { "Content-Type": "application/json" },
    },
    meta: {
        onSuccess: (response) => {
            if (onSuccess) onSuccess(response);
            return response;
        },
        onError: (error) => {
            if (onError) onError(error);
            return error;
        },
    },
});

export const resetOrchestratorSynchro = (
    orchestratorId,
    onSuccess,
    onError,
) => ({
    type: INIT_ORCHESTRATOR_DATABASE,
    request: {
        url: `${URL_SYNCHRO}deleteConnectors/${orchestratorId}`,
        method: "delete",
        data: {
            orchestratorId,
        },
        headers: { "Content-Type": "application/json" },
    },
    meta: {
        onSuccess: (response) => {
            if (onSuccess) onSuccess(response);
            return response;
        },
        onError: (error) => {
            if (onError) onError(error);
            return error;
        },
    },
});

export const initOrchestratorDatabase = (
    orchestratorId,
    onSuccess,
    onError,
) => ({
    type: INIT_ORCHESTRATOR_DATABASE,
    request: {
        url: `${URL_BP_CONNECTOR}initDatabase`,
        method: "post",
        data: {
            orchestratorId,
        },
        headers: { "Content-Type": "application/json" },
    },
    meta: {
        onSuccess: (response) => {
            if (onSuccess) onSuccess(response);
            return response;
        },
        onError: (error) => {
            if (onError) onError(error);
            return error;
        },
    },
});

export const initIrisOrchestratorDatabase = (
  orchestratorId,
  onSuccess,
  onError,
) => ({
  type: INIT_ORCHESTRATOR_DATABASE,
  request: {
      url: `${URL_IRIS_CONNECTOR}initDatabase`,
      method: "post",
      params: {
        orchestratorId,
      },
      headers: { "Content-Type": "application/json" },
  },
  meta: {
      onSuccess: (response) => {
          if (onSuccess) onSuccess(response);
          return response;
      },
      onError: (error) => {
          if (onError) onError(error);
          return error;
      },
  },
});

export const fetchOrchestratorConfigState = (orchestratorId) => ({
    type: FETCH_ORCHESTRATOR_CONFIG_STATE,
    request: {
        url: `${URL_ORCHESTRATOR}/configState/${orchestratorId}`,
    },
});
export const isEnoughLicenses = (orchestratorId) => ({
    type: IS_ENOUGH_LICENSES,
    request: {
        url: `${URL_ORCHESTRATOR}/licenses-check/${orchestratorId}`,
    },
})
export const fetchOrchestratorStatus = (orchestratorId) => ({
    type: FETCH_ORCHESTRATOR_STATUS,
    request: {
        url: `${URL_SYNCHRO}orchestratorStatus/${orchestratorId}`,
    },
});

export const testOrchestratorConnectionWithId = (orchId, onSuccess, onError) => ({
    type: TEST_ORCHESTRATOR_CONNECTION,
    request: {
        url: `${URL_BP_CONNECTOR}testConnection`,
        method: "get",
        headers: { "Content-Type": "application/json" },
        params: {
            orchestratorId: orchId
        }
    },
    meta: {
        onSuccess: (response) => {
            if (onSuccess) onSuccess(response);
            return response;
        },
        onError: (error) => {
            if (onError) onError(error);
            return error;
        },
    },
});
export const testOrchestratorConnection = (data, onSuccess, onError) => ({
    type: TEST_ORCHESTRATOR_CONNECTION,
    request: {
        url: `${URL_BP_CONNECTOR}testConnection`,
        method: "post",
        data,
        headers: { "Content-Type": "application/json" },
    },
    meta: {
        onSuccess: (response) => {
            if (onSuccess) onSuccess(response);
            return response;
        },
        onError: (error) => {
            if (onError) onError(error);
            return error;
        },
    },
});

export const testOrchestratorUipathConnection = (data, onSuccess, onError) => {
    const {
        organizationName, tenantName, orchestratorApiName, url, isCloudOrchestrator
    } = data;
    return {
        type: TEST_ORCHESTRATOR_UIPATH_CONNECTION,
        request: {
            url: `${URL_UIPATH_CONNECTOR}testConnection`,
            method: "get",
            params: {
                organizationName,
                tenantName,
                orchestratorApiName,
                orchestratorUrl: url,
                isCloudOrchestrator
            },
            headers: { "Content-Type": "application/json" },
        },
        meta: {
            onSuccess: (response) => {
                if (onSuccess) onSuccess(response);
                return response;
            },
            onError: (error) => {
                if (onError) onError(error);
                throw error;
            },
        },
    };
};

export const testOrchestratorIrisConnection = (data, onSuccess, onError) => {
    const {
        irisUsername, irisPassword, irisUrl,
    } = data;
    return {
        type: TEST_ORCHESTRATOR_IRIS_CONNECTION,
        request: {
          url: `${URL_IRIS_CONNECTOR}testConnection`,
          method: "get",
          params: {
            irisUsername,
            irisPassword,
            irisUrl
          },
          headers: { "Content-Type": "application/json" },
        },
        meta: {
            onSuccess: (response) => {
                if (onSuccess) onSuccess(response);
                return response;
            },
            onError: (error) => {
                if (onError) onError(error);
                throw error;
            },
        },
    };
};

export const fetchOrchestrators = (filter = {}) => {
    const {
        searchCriteria, pageNo, pageSize, status, sortBy, fleets
    } = filter;
    return {
        type: FETCH_ORCHESTRATORS,
        request: {
            url: encodeURI(`${URL_ORCHESTRATOR}/${searchCriteria ? `?searchCriteria=${searchCriteria}` : ""}`),
            params: {
                page: pageNo,
                size: pageSize,
                sort: sortBy,
                status: status ? status.join(",") : null,
                fleetIds: fleets?.join(),
            },
        },
    };
};
export const fetchOrchestratorsNamesAndIds = (fleetIds = []) => ({
    type: FETCH_ORCHESTRATORS_NAMES_AND_IDS,
    request: {
        url: `${URL_ORCHESTRATOR}/names`,
        method: "get",
        params: {
          fleetIds: fleetIds.join(),
      }
    },
});
export const enableOrDisableOchestrator = (
    id,
    orchestratorStatus,
    onSuccess,
    onError,
) => ({
    type: UPDATE_ORCHESTRATOR_STATUS,
    request: {
        url: `${URL_ORCHESTRATOR}/status/${id}/${orchestratorStatus === "ACTIVE" ? "enable" : "disable"
        }`,
        method: "put",
        headers: { "Content-Type": "application/json" },
    },
    meta: {
        onSuccess: (response) => {
            if (onSuccess) onSuccess(response);
            return response;
        },
        onError: (error) => {
            if (onError) onError(error);
            return error;
        },
    },
});
export const fetchOrchestrator = (id, onSuccess, onError) => ({
    type: `${FETCH_ORCHESTRATOR}`,
    request: {
        url: `${URL_ORCHESTRATOR}/${id}`,
    },
    meta: {
        onSuccess: (response) => {
            if (onSuccess) onSuccess(response);
            return response;
        },
        onError: (error) => {
            if (onError) onError(error);
            throw error;
        },
    },
});

export const createOrchestrator = (orchestrator, file, onSuccess, onError) => ({
    type: CREATE_ORCHESTRATOR,
    request: {
        url: `${URL_ORCHESTRATOR}/`,
        method: "post",
        data: JSON.stringify(Object.assign(orchestrator, file)),
        headers: { "Content-Type": "application/json" },
    },
    meta: {
        onSuccess: (response) => {
            if (onSuccess) onSuccess(response);
            return response;
        },
        onError: (error) => {
            if (onError) onError(error);
            return error;
        },
    },
});
export const modifyOrchestrator = (
    orchestratorId,
    orchestrator,
    file,
    onSuccess,
    onError,
) => ({
    type: UPDATE_ORCHESTRATOR,
    request: {
        url: `${URL_ORCHESTRATOR}/${orchestratorId}`,
        method: "put",
        data: JSON.stringify(Object.assign(orchestrator, file)),
        headers: { "Content-Type": "application/json" },
    },
    meta: {
        onSuccess: (response) => {
            if (onSuccess) onSuccess(response);
            return response;
        },
        onError: (error) => {
            if (onError) onError(error);
            throw error;
        },
    },
});

export const deleteOrchestrator = (id) => ({
    type: DELETE_ORCHESTRATOR_BY_ID,
    request: {
        url: `${URL_ORCHESTRATOR}/${id}`,
        method: "delete",
    },
});
export const getRemainingLicencesForOrchestrator = (orchestratorId) => ({
    type: GET_REMAINING_LICENCES_FOR_ORCHESTRATOR,
    request: {
        url: `${URL_SR_QUEUES_MNGT}/remainingLicences/${orchestratorId}`,
        method: "get",
    }
})
export const fetchViewsCount = (orchestratorId, onSuccess, onError) => ({
    type: FETCH_VIEWS_COUNT,
    request: {
        url: `${URL_BP_CONNECTOR}recordsCount`,
        method: "get",
        params: {
            orchestratorId
        }
    },
    meta: {
        onSuccess: (response) => {
            if (onSuccess) onSuccess(response);
            return response;
        },
        onError: (error) => {
            if (onError) onError(error);
            throw error;
        },
    }
})
export const fetchIRISViewsCount = (orchestratorId, onSuccess, onError) => ({
  type: FETCH_VIEWS_COUNT,
  request: {
      url: `${URL_IRIS_CONNECTOR}recordsCount`,
      method: "get",
      params: {
          orchestratorId
      }
  },
  meta: {
      onSuccess: (response) => {
          if (onSuccess) onSuccess(response);
          return response;
      },
      onError: (error) => {
          if (onError) onError(error);
          throw error;
      },
  }
})
export const triggerUpdateInOrchestrator = (
    orchestratorId,
    target,
    orchestratorType,
    onSuccess,
    onError,
) => ({
    type: TRIGGER_UPDATE_IN_ORCHESTRATOR,
    request: {
        url: `${orchestratorType === "uipath" ? URL_UIPATH_CONNECTOR : URL_BP_CONNECTOR}triggerUpdate`,
        method: "post",
        data: {
            orchestratorId,
            target,
        },
        headers: { "Content-Type": "application/json" },
    },
    meta: {
        onSuccess: (response) => {
            if (onSuccess) onSuccess(response);
            return response;
        },
        onError: (error) => {
            if (onError) onError(error);
            return error;
        },
    },
});

export const triggerUpdateInIRISOrchestrator = (
  orchestratorId,
  target,
  onSuccess,
  onError,
) => ({
  type: TRIGGER_UPDATE_IN_ORCHESTRATOR,
  request: {
      url: `${URL_IRIS_CONNECTOR}triggerUpdate`,
      method: "post",
      params: {
          orchestratorId,
          target,
      },
      headers: { "Content-Type": "application/json" },
  },
  meta: {
      onSuccess: (response) => {
          if (onSuccess) onSuccess(response);
          return response;
      },
      onError: (error) => {
          if (onError) onError(error);
          return error;
      },
  },
});

export const fetchOrchestratorProcesses = (id) => ({
    type: FETCH_ORCHESTRATOR_PROCESSES,
    request: {
        url: `${URL_ORCHESTRATOR}/${id}/processes`,
        method: "GET",
    },
});
