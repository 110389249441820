/* eslint-disable import/prefer-default-export */
import {
  CREATE_RESOURCE_GROUP, DELETE_RESOURCE_GROUP, FETCH_RESOURCE_GROUP_BY_ID, FETCH_RESOURCE_GROUPS, FETCH_RESOURCE_GROUPS_BY_ORCHESTRATOR_ID, UPDATE_RESOURCE_GROUP
} from "redux/constants";
import { URL_RESOURCE_GROUPS } from "redux/constants/endpoints";

export const fetchResourceGroupsByOrchestratorId = (orchestratorId) => ({
  type: FETCH_RESOURCE_GROUPS_BY_ORCHESTRATOR_ID,
  request: {
    url: `${URL_RESOURCE_GROUPS}/orchestrator/${orchestratorId}`,
    method: "GET",
  },
});

export const fetchResourceGroups = (params, onSuccess, onError) => ({
  type: FETCH_RESOURCE_GROUPS,
  request: {
      url: URL_RESOURCE_GROUPS,
      method: "GET",
      params,
  },
  meta: {
      onSuccess: (response) => {
          if (onSuccess) onSuccess(response);
          return response;
      },
      onError: (error) => {
          if (onError) onError(error);
          throw error;
      },
  }
});

export const updateResourceGroup = (resourceGroupDto, onSuccess, onError) => ({
  type: UPDATE_RESOURCE_GROUP,
  request: {
    url: `${URL_RESOURCE_GROUPS}/update`,
    method: "PUT",
    data: resourceGroupDto,
  },
  meta: {
    onSuccess: (response) => {
        if (onSuccess) onSuccess(response);
        return response;
    },
    onError: (error) => {
        if (onError) onError(error);
        throw error;
    },
}
});

export const createResourceGroup = (resourceGroupDto, onSuccess, onError) => ({
  type: CREATE_RESOURCE_GROUP,
  request: {
    url: `${URL_RESOURCE_GROUPS}/create`,
    method: "POST",
    data: resourceGroupDto,
  },
  meta: {
    onSuccess: (response) => {
        if (onSuccess) onSuccess(response);
        return response;
    },
    onError: (error) => {
        if (onError) onError(error);
        throw error;
    },
}
});

export const fetchResourceGroupById = (id, onSuccess, onError) => ({
  type: FETCH_RESOURCE_GROUP_BY_ID,
  request: {
    url: `${URL_RESOURCE_GROUPS}/${id}`,
    method: "GET",
  },
  meta: {
    onSuccess: (response) => {
        if (onSuccess) onSuccess(response);
        return response;
    },
    onError: (error) => {
        if (onError) onError(error);
        throw error;
    },
}
});

export const deleteResourceGroup = (id, onSuccess, onError) => ({
  type: DELETE_RESOURCE_GROUP,
  request: {
    url: `${URL_RESOURCE_GROUPS}/${id}`,
    method: "DELETE",
  },
  meta: {
    onSuccess: (response) => {
        if (onSuccess) onSuccess(response);
        return response;
    },
    onError: (error) => {
        if (onError) onError(error);
        throw error;
    },
}
});
