import React from "react";
import Grid from "@material-ui/core/Grid";
import OrchGraphContainer from "pages/OrchestrationDashboard/Components/OrchGraphContainer";
import Filter from "./Components/Filter";
import graphsInfos from "pages/OrchestrationDashboard/Utils/Constants";
import useStyles from "./style";

const OrchestrationDashboard = () => {
  const classes = useStyles();

  return (
    <Grid
      container
      direction="column"
      spacing={3}
      className={classes.analyticsContainer}
    >
      <Grid item xs={6}>
        <Filter />
      </Grid>
      <Grid container item spacing={3}>
        {graphsInfos.map((graph) => (
          <Grid item xs={6}>
            <OrchGraphContainer
                name={graph.name}
                graph={graph.graph}
                fetchAction={graph.fetchAction}
                config={graph.config}
                fetchActionTable={graph.fetchActionTable}
                exportAction={graph.exportAction}
            />
          </Grid>
          ))}
      </Grid>
    </Grid>
  );
};

export default OrchestrationDashboard;
