import React, { useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid"
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import isEmpty from "lodash/isEmpty";
import {
  fetchAssignedProcessesByResource,
  fetchProcessesByOrchestrator,
  fetchResource,
  fetchResourceCredentialsByUserId,
  testResourceConnection,
  updateResource,
} from "redux/actions/services/index";
import { RESOURCE_BASE_URL } from "util/index";
import ConfirmMessage from "components/ConfirmMessage";
import ProcessSelector from "../../ProcessSelector";
import CustomButton from "components/CustomButton";
import CircularLoader from "components/Loaders/CircularLoader";
import DialogWithTabs from "components/FormComponents/DialogWithTabs";
import makeStyles from "../style";
import CustomAutoComplete, { CustomTextField } from "components/FormFields/CustomAutoComplete";
import { isPermitted } from "components/HasPermission";
import {
  Box, Switch, Tooltip, Typography
} from "@material-ui/core";
import CustomSwitch from "pages/Services/components/CustomSwitch";
import CredentialForm from "../CredentialsManagement/CredentialForm";
import InformationIcon from "components/InformationIcon";

const DEFAULT_ORS_WIDTH = 1920;
const DEFAULT_ORS_HEIGHT = 1080;
const DEFAULT_ORS_DPI = 125;

export default function RobotForm(props) {
  const classes = makeStyles();
  const dispatch = useDispatch();
  const {
    setValue, formState: { dirtyFields, errors }, register, getValues, trigger, watch
  } = useForm();
  const history = useHistory();
  const { t } = useTranslation();
  const { idResource, mode = "view" } = useParams();
  const [availableProcesses, setAvailableProcesses] = useState([]);
  const [assignedProcesses, setAssignedProcesses] = useState([]);
  const [openMsgCancel, setOpenMsgCancel] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [openMsgConfirm, setOpenMsgConfirm] = useState(false);
  const [resourceDataLoading, setResourceDataLoading] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const disableFields = !(mode === "add" || mode === "edit");
  const [resourceCredentials, setResourceCredentials] = useState([]);
  const [openCredentialForm, setOpenCredentialForm] = useState(false);
  const [resource, setResource] = useState({
    resourceDisplayName: "",
  });
  const defaultInfrastructureFields = {
    host: "",
    winUser: "",
    winPassword: false,
    notUsable: false,
    canBeUsedWithOrs: false,
    bpResourceRestartable: false,
    winSessionRestartable: false,
    maxRestartTries: 1,
    resourceCredentialId: null,
    resourceCommandLinePort: 5986,
    bpResourceFilePath: ""
  };

    const tabs = [
        {
          id: "ressource-overview-tab",
          label: "fleet.formSection.fleetInfo",
        },
        {
          id: "ressource-process-tab",
          label: "fleet.formSection.processes",
        },
        {
          id: "infrastracture-tab",
          label: "Infrastructure orchestration",
          disabledTab: !watch("isOrsEnabled"),
        },
    ];
    const enabledTabs = tabs.filter((item) => !item.disabledTab);

  useEffect(() => {
    setResourceDataLoading(true);
    dispatch(fetchResource(idResource)).then((res) => {
      dispatch(fetchAssignedProcessesByResource(idResource));
      if (res?.data) {
        setResource(res.data);
        setValue("resourceId", res.data.resourceId);
        setValue("processesRunning", res.data.processesRunning);
        setValue("resourceDisplayName", res.data.resourceDisplayName);
        setValue("resourceName", res.data.resourceName);
        setValue("actionsRunning", res.data.actionsRunning);
        setValue("displayStatus", res.data.displayStatus ? t(res.data.displayStatus) : t("Offline"));
        setValue("resourceInfrastructure", res.data?.resourceInfrastructure || defaultInfrastructureFields);
        setValue("isOrsEnabled", res.data.orsDetail !== null);
        setValue("orsLogin", res.data.orsDetail?.username);
        setValue("orsPassword", res.data.orsDetail?.password);
        setValue("orsResourceVmIp", res.data.orsDetail?.host);
        setValue("orsScreenHeight", res.data.orsDetail?.height ?? DEFAULT_ORS_HEIGHT);
        setValue("orsScreenWidth", res.data.orsDetail?.width ?? DEFAULT_ORS_WIDTH);
        setValue("orsScreenDpi", res.data.orsDetail?.dpi ?? DEFAULT_ORS_DPI);
        setValue("orsResourceCredentialsId", res.data.orsDetail?.resourceCredentialId);
        setResourceDataLoading(false);
      }
    });
  }, [idResource, dispatch, setValue]);

  useEffect(() => {
    if (resource?.orchestrator?.id) dispatch(fetchProcessesByOrchestrator(resource.orchestrator.id)).then();
  }, [dispatch, resource]);

  const resourcePreAssignedProcesses = useSelector(
    ({ requests }) => requests.queries.FETCH_ROBOT_PROCESSES?.data,
  );

  const processList = useSelector(
    ({ requests }) => requests.queries.FETCH_PROCESSES_BY_ORCHESTRATOR?.data,
  );

  const currentUser = useSelector(
    ({ requests }) => requests.queries.FETCH_CURRENT_USER?.data,
  );

  useEffect(() => {
    if (resourcePreAssignedProcesses && processList) {
      setAssignedProcesses(resourcePreAssignedProcesses?.set || []);
      setAvailableProcesses(
        processList?.filter(
          (process) => !resourcePreAssignedProcesses.set?.find(
            (assigned) => assigned.id === process.id,
          ),
        ),
      );
    }
  }, [resourcePreAssignedProcesses, processList]);

  const getResourceCredentials = () => {
    dispatch(fetchResourceCredentialsByUserId(currentUser?.id)).then((res) => {
      if (res?.data) {
        setResourceCredentials(res.data);
      }
    });
  };

  useEffect(() => {
    if (currentUser) {
      getResourceCredentials();
    }
  }, [dispatch, currentUser]);

  const handleCredentialFormClose = () => {
    getResourceCredentials();
    setOpenCredentialForm(false);
  };

  const handleSaveClick = async () => {
    const shouldValidateOrsFields = watch("isOrsEnabled") && activeStep === enabledTabs.length - 1;
    const result = await trigger(shouldValidateOrsFields ? ["orsLogin", "orsPassword", "orsResourceVmIp"] : [])
    if (result) {
      if (activeStep === enabledTabs.length - 1) {
        setOpenMsgConfirm(true);
      }
      if (activeStep < enabledTabs.length - 1) {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      }
    }
  };

  const handleCancel = () => {
    if (isEmpty(dirtyFields)) {
      redirectToList();
      return;
    }
    setOpenMsgCancel(true);
  };

  const redirectToList = () => history.push(RESOURCE_BASE_URL);

  const handleBack = () => {
    if (activeStep <= 0) handleCancel();
    setActiveStep((prevActiveStep) => (prevActiveStep === 0 ? 0 : prevActiveStep - 1));
  };

  const cancelConfirm = () => {
    setOpenMsgConfirm(false);
  };

  const confirmSave = () => {
    setIsLoading(true);
    submitUpdate();
    toast.success(t("resource.formControl.succesUpdate"))
  };

  const submitUpdate = () => {
      let orsDetail = null
      if (getValues("isOrsEnabled")) { orsDetail = {
              username: getValues("orsLogin"),
              password: getValues("orsPassword"),
              host: getValues("orsResourceVmIp"),
              height: getValues("orsScreenHeight") ?? DEFAULT_ORS_HEIGHT,
              width: getValues("orsScreenWidth") ?? DEFAULT_ORS_WIDTH,
              dpi: getValues("orsScreenDpi") ?? DEFAULT_ORS_DPI,
              resourceCredentialId: getValues("orsResourceCredentialsId")
          } }
      const data = {
          processesId: assignedProcesses.map((el) => el.id),
          resourceName: getValues("resourceDisplayName"),
          orsDetail,
          resourceInfrastructure: getValues("resourceInfrastructure")
      }
    dispatch(
      updateResource(idResource, data),
    ).then(() => {
      setIsLoading(false);
      setOpenMsgConfirm(false);
      redirectToList();
    });
  };

  const handleAcceptCancelForm = () => {
    setOpenMsgCancel(false);
    redirectToList();
  };

  const handleRejectCancelForm = () => setOpenMsgCancel(false);

  const handleChange = (_, newValue) => {
    setActiveStep(newValue);
  };

  const handleNotification = (message, status) => {
    const msg = t(message);
    if (status) {
      toast.success(msg);
    } else {
      toast.error(msg);
    }
  }

  const testConnection = () => {
    const onSuccess = (res) => {
      if (res.data === true) {
        handleNotification("resource.management.testConnection.successMessage", true);
      } else {
        handleNotification("resource.management.testConnection.successMessage.no.file", true);
      }
    };
    const onError = () => {
      handleNotification("resource.management.testConnection.failureMessage", false);
    };
    const connectionCredentials = {
      host: getValues("orsResourceVmIp"),
      resourceCredentialId: getValues("orsResourceCredentialsId"),
      port: getValues("resourceInfrastructure.resourceCommandLinePort") ?? 5986,
      filePath: getValues("resourceInfrastructure.bpResourceFilePath"),
    };
    dispatch(testResourceConnection(connectionCredentials, onSuccess, onError));
  };

  const tabsContents = [
    {
      content: (
          resourceDataLoading ? (
            <CircularLoader height="100%" />
          ) : (
            <Grid container direction="column" justify="flex-start" spacing={7}>
              <Grid container item justify="space-between">
                <Grid item xs={5}>
                  <CustomTextField
                        {...register("resourceDisplayName")}
                        fullWidth
                        valued={getValues("resourceDisplayName")}
                        disabled={disableFields}
                        id="resourceDisplayName"
                        label={t("resource.management.formControl.resourceName")}
                        InputLabelProps={{
                          shrink: !!getValues("resourceDisplayName"),
                        }}
                        autoFocus
                    />
                </Grid>
                <Grid item xs={5}>
                  <CustomTextField
                        {...register("actionsRunning")}
                        fullWidth
                        id="actionsRunning"
                        name="actionsRunning"
                        label={`${t(
                            "resource.management.formControl.actionsRunning",
                        )} *`}
                        valued={getValues("actionsRunning") && !disableFields}
                        disabled
                        InputLabelProps={{
                          shrink: typeof getValues("actionsRunning") !== "undefined",
                        }}
                    />
                </Grid>
              </Grid>
              <Grid container item justify="space-between">
                <Grid item xs={5}>
                  <CustomTextField
                        {...register("resourceName")}
                        fullWidth
                        valued={getValues("resourceName") && !disableFields}
                        disabled
                        id="resourceName"
                        label={t("resource.management.formControl.resourceOriginalName")}
                        InputLabelProps={{
                          shrink: !!getValues("resourceName"),
                        }}
                    />
                </Grid>
                <Grid item xs={5}>
                  <CustomTextField
                        {...register("displayStatus")}
                        fullWidth
                        valued={getValues("displayStatus")}
                        disabled
                        id="displayStatus"
                        label={t("resource.management.displayStatus")}
                        InputLabelProps={{
                          shrink: !!getValues("displayStatus"),
                        }}
                    />
                </Grid>
              </Grid>
              <Grid container item justify="space-between">
                <Grid item xs={5}>
                  <CustomTextField
                        {...register("resourceId")}
                        fullWidth
                        valued={getValues("resourceId")}
                        disabled
                        id="resourceId"
                        label={t("resource.management.formControl.resourceId")}
                        InputLabelProps={{
                          shrink: !!getValues("resourceId"),
                        }}
                    />
                </Grid>
                <Grid item xs={5}>
                  <CustomTextField
                        {...register("processesRunning")}
                        fullWidth
                        valued={getValues("processesRunning")}
                        disabled
                        id="processesRunning"
                        label={`${t(
                            "resource.management.formControl.processesRunning",
                        )} *`}
                        InputLabelProps={{
                          shrink:
                              typeof getValues("processesRunning") !== "undefined",
                        }}
                    />
                </Grid>
              </Grid>
              <Grid item>
                <CustomSwitch
                  name="isOrsEnabled"
                  checked={watch("isOrsEnabled")}
                  handleChange={() => setValue(
                      "isOrsEnabled",
                      !watch("isOrsEnabled"),
                  )}
                  label={t("resource.management.formControl.activateOrs")}
                  disabled={disableFields}
                />
              </Grid>
            </Grid>
          )
      ),
      actions: (
        <>
          <CustomButton view="cancelModal" onClick={handleBack}>
            {t("resource.button.cancel")}
          </CustomButton>
          <CustomButton view="primary" onClick={handleSaveClick}>
            {t("next")}
          </CustomButton>
        </>
      ),
    },
    {
      content: (
        <Grid item xs={12}>
          <ProcessSelector
              availableProcesses={availableProcesses}
              selectedProcesses={assignedProcesses}
              isDisabled={mode === "view"}
              setAvailableProcesses={setAvailableProcesses}
              setSelectedProcesses={setAssignedProcesses}
            />
        </Grid>
      ),
      actions: (
        <>
          <CustomButton view="cancelModal" onClick={handleBack}>
            {t("fleet.add.previous")}
          </CustomButton>
          <CustomButton view="primary" onClick={handleSaveClick}>
            {t("next")}
          </CustomButton>
        </>
      ),
    },
    {
      content: (
          resourceDataLoading ? (
            <CircularLoader height="100%" />
          ) : (
            <Grid container direction="column" alignItems="center" spacing={5}>
              <Box className={classes.boxSection}>
                <Typography className={classes.boxSectionTitle}>
                  {t("fleet.formSection.fleetInfo")}
                </Typography>
                <Grid container item justify="space-between" className={classes.fieldsGrid}>
                  <Grid item xs={5}>
                    <CustomTextField
                          {...register("orsResourceVmIp", {
                            required: t("user.management.formControl.required"),
                          })}
                          fullWidth
                          disabled={disableFields}
                          id="hostIp"
                          label={t("Host/Ip")}
                          valued={getValues("orsResourceVmIp")}
                          InputLabelProps={{
                            shrink: !!watch("orsResourceVmIp"),
                          }}
                          autoFocus
                          error={!!errors.orsResourceVmIp}
                          helperText={errors.orsResourceVmIp && errors.orsResourceVmIp.message}
                      />
                  </Grid>
                </Grid>
                <Grid container item justify="space-between" alignContent="center" className={classes.fieldsGrid}>
                  <Grid item xs={5}>
                    <CustomAutoComplete
                          fullWidth
                          id="credentials"
                          label={t("resource.credentials")}
                          options={resourceCredentials ?? []}
                          value={resourceCredentials?.find((item) => watch("orsResourceCredentialsId") === item?.id)}
                          optionLabel="name"
                          onChange={(e) => setValue("orsResourceCredentialsId", e?.id)}
                          error={errors?.orsResourceCredentialsId?.message}
                          disabled={disableFields}
                      />
                  </Grid>
                  <Grid xs={5}>
                    <CustomButton view="primary" fullWidth onClick={() => setOpenCredentialForm(true)} className={classes.addCredentialButton} disabled={disableFields}>
                      {t("Create Credential")}
                    </CustomButton>
                  </Grid>
                </Grid>
                <Grid container justify="space-between" className={classes.fieldsGrid}>
                  <Grid container direction="row" xs={6} alignItems="center">
                    <Switch
                      disabled={disableFields}
                      checked={watch("resourceInfrastructure.notUsable")}
                      onClick={() => setValue("resourceInfrastructure.notUsable", !watch("resourceInfrastructure.notUsable"))}
                    />
                    <Typography variant="subtitle2" className={classes.switchText}>{t("Resource under maintenance not usable")}</Typography>
                  </Grid>
                  <Grid container direction="row" xs={5} alignItems="center">
                    <Switch
                      disabled={disableFields}
                      checked={watch("resourceInfrastructure.canBeUsedWithOrs")}
                      onClick={() => setValue("resourceInfrastructure.canBeUsedWithOrs", !watch("resourceInfrastructure.canBeUsedWithOrs"))}
                    />
                    <Typography variant="subtitle2" className={classes.switchText}>{t("Windows session managed by ORS")}</Typography>
                  </Grid>
                </Grid>
                <Grid container justify="space-between" className={classes.fieldsGrid}>
                  <Typography variant="subtitle2" className={classes.switchText}>{`${t("ORS parametres")} :`}</Typography>
                </Grid>
                <Grid container justify="space-between" className={classes.fieldsGrid}>
                  <Grid item xs={3}>
                    <CustomTextField
                    {...register("orsScreenHeight", {
                        required: t("user.management.formControl.required"),
                        min: {
                            value: 0,
                            message: t("restriction.form.minValue.error"),
                        },
                    })}
                    type="number"
                    fullWidth
                    valued={getValues("orsScreenHeight")}
                    disabled={disableFields}
                    id="orsScreenHeight"
                    name="orsScreenHeight"
                    label={`${t("resource.management.formControl.orsScreenHeight")} *`}
                    error={!!errors.orsScreenHeight}
                    helperText={errors.orsScreenHeight && errors.orsScreenHeight.message}
                />
                  </Grid>
                  <Grid item xs={3}>
                    <CustomTextField
                    {...register("orsScreenWidth", {
                        required: t("user.management.formControl.required"),
                        min: {
                            value: 0,
                            message: t("restriction.form.minValue.error"),
                        },
                    })}
                    fullWidth
                    id="orsScreenWidth"
                    name="orsScreenWidth"
                    label={`${t("resource.management.formControl.orsScreenWidth")} *`}
                    valued={getValues("orsScreenWidth")}
                    disabled={disableFields}
                    type="number"
                    error={!!errors.orsScreenWidth}
                    helperText={errors.orsScreenWidth && errors.orsScreenWidth.message}
                />
                  </Grid>
                  <Grid item xs={3}>
                    <CustomTextField
                        {...register("orsScreenDpi", {
                            required: t("user.management.formControl.required"),
                            min: {
                                value: 0,
                                message: t("restriction.form.minValue.error"),
                            },
                        })}
                        type="number"
                        fullWidth
                        valued={getValues("orsScreenDpi")}
                        disabled={disableFields}
                        id="orsScreenDpi"
                        name="orsScreenDpi"
                        label={`${t("resource.management.formControl.orsScreenDpi")} *`}
                        error={!!errors.orsScreenDpi}
                        helperText={errors.orsScreenDpi && errors.orsScreenDpi.message}
                    />
                  </Grid>
                </Grid>
              </Box>
              <Box className={classes.boxSection}>
                <Typography className={classes.boxSectionTitle}>
                  {t("In case of problems")}
                </Typography>
                <Grid container justify="space-between" className={classes.fieldsGrid}>
                  <Grid container direction="row" xs={5} alignItems="center">
                    <Switch
                      disabled={disableFields}
                      checked={watch("resourceInfrastructure.bpResourceRestartable")}
                      onClick={() => setValue("resourceInfrastructure.bpResourceRestartable", !watch("resourceInfrastructure.bpResourceRestartable"))}
                    />
                    <Typography variant="subtitle2" className={classes.switchText}>{t("Can restart BP ressource")}</Typography>
                    <InformationIcon
                      titleContent={t(
                          "resource.management.formControl.resource.bp.restartable",
                      )}
                    />
                  </Grid>
                  <Grid container direction="row" xs={5} alignItems="center">
                    <Switch
                      disabled={disableFields}
                      checked={watch("resourceInfrastructure.winSessionRestartable")}
                      onClick={() => setValue("resourceInfrastructure.winSessionRestartable", !watch("resourceInfrastructure.winSessionRestartable"))}
                    />
                    <Typography variant="subtitle2" className={classes.switchText}>{t("Can restart windows session")}</Typography>
                    <InformationIcon
                      titleContent={t(
                          "resource.management.formControl.resource.win.restartable",
                      )}
                    />
                  </Grid>
                </Grid>
                <Grid container item justify="space-between" className={classes.fieldsGrid}>
                  <Grid item xs={3}>
                    <CustomTextField
                          {...register("resourceInfrastructure.resourceCommandLinePort")}
                          fullWidth
                          InputProps={{
                            readOnly: disableFields,
                            endAdornment: (<InformationIcon
                              titleContent={t(
                                  "resource.management.formControl.resourceCommandLinePort",
                              )}
                            />),
                          }}
                          id="resourceCommandLinePort"
                          label={t("command line port")}
                          InputLabelProps={{
                            shrink: !!watch("resourceInfrastructure.resourceCommandLinePort"),
                          }}
                          type="number"
                      />
                  </Grid>
                  <Grid item xs={4}>
                    <CustomTextField
                          {...register("resourceInfrastructure.bpResourceFilePath")}
                          fullWidth
                          InputProps={{
                            readOnly: disableFields,
                            endAdornment: (<InformationIcon
                              titleContent={t(
                                  "resource.management.formControl.bpResourceFilePath",
                              )}
                            />),
                          }}
                          id="bpResourceFilePath"
                          label={t("bp.resource.bat.file.full.filepath")}
                          InputLabelProps={{
                            shrink: !!watch("resourceInfrastructure.bpResourceFilePath"),
                          }}
                      />
                  </Grid>
                  <Grid item xs={3}>
                    <CustomTextField
                          {...register("resourceInfrastructure.maxRestartTries")}
                          fullWidth
                          InputProps={{
                            readOnly: disableFields,
                            endAdornment: (<InformationIcon
                              titleContent={t(
                                  "resource.management.formControl.maxRestartTries",
                              )}
                            />),
                          }}
                          id="maxRetryalRestart"
                          label={t("Max retryal restart")}
                          InputLabelProps={{
                            shrink: !!watch("resourceInfrastructure.maxRestartTries"),
                          }}
                          type="number"
                      />
                  </Grid>
                </Grid>
              </Box>
            </Grid>
      )),
      actions: mode === "view" ? null : (
        <>
          <Tooltip title={t("orchestrator.management.testConnection.tooltip")}>
            <CustomButton view="primary" onClick={testConnection}>
              {t("test.resource.connection")}
            </CustomButton>
          </Tooltip>
          <CustomButton view="cancelModal" onClick={handleBack}>
            {t("fleet.add.previous")}
          </CustomButton>
          <CustomButton view="primary" onClick={handleSaveClick}>
            { idResource ? t("resource.button.update") : t("user.button.save")}
          </CustomButton>
        </>
      ),
      notUseBorder: true,
      disabledTab: !watch("isOrsEnabled"),
      fixedActions: mode === "view" ? (
        <Tooltip title={t("orchestrator.management.testConnection.tooltip")}>
          <CustomButton view="primary" onClick={testConnection}>
            {t("test.resource.connection")}
          </CustomButton>
        </Tooltip>
      ) : null,
    },
  ];

  return (
    <>
      <DialogWithTabs
          open={props?.open}
          entityBaseUrl={RESOURCE_BASE_URL}
          moduleName="ressources"
          tabs={enabledTabs}
          disableFields={disableFields}
          editCondition={isPermitted(currentUser, "Edit Resources")}
          tabsContents={tabsContents.filter((item) => !item.disabledTab)}
          idItem={idResource}
          handleChange={handleChange}
          activeStep={activeStep}
          height={disableFields ? 530 : 560}
        />
      {openMsgConfirm && (
      <ConfirmMessage
            message={t("resource.update.confirmMsg")}
            openStart={openMsgConfirm}
            onCancel={cancelConfirm}
            onConfirm={confirmSave}
            buttonConfirm={t("resource.button.update")}
            buttonCancel={t("resource.button.cancel")}
            isLoading={isLoading}
          />
        )}
      {openMsgCancel && (
      <ConfirmMessage
            message={t("resource.update.discard")}
            openStart={openMsgCancel}
            onCancel={handleRejectCancelForm}
            onConfirm={handleAcceptCancelForm}
            buttonConfirm={t("resource.button.discard")}
            buttonCancel={t("resource.button.cancel")}
            isLoading={false}
          />
        )}
      {openCredentialForm && (
        <CredentialForm
          open={openCredentialForm}
          handleClose={handleCredentialFormClose}
        />
      )}
    </>
  );
}
