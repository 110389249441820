import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    selectedDurationValue: "THIS_MONTH",
    process: [],
    tags: []
};

const filterOrchestrationDashboard = createSlice({
    initialState,
    name: "filterOrchestrationDashboard",
    reducers: {
        initFilter: () => ({
            ...initialState,
        }),
        updateProcessFilter: (state, action) => ({
            ...state,
            process: action.payload,
        }),
        updateDateFilter: (state, action) => ({
            ...state,
            selectedDurationValue: action.payload,
        }),
        updateTagsFilter: (state, action) => ({
            ...state,
            tags: action.payload,
        }),
    },
});

export const {
    initFilter, updateProcessFilter, updateDateFilter, clearProcessFilter, updateTagsFilter, updateFleetsFilter, updateMonitoringMode
} = filterOrchestrationDashboard.actions;
export default filterOrchestrationDashboard.reducer;
