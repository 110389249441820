// eslint-disable-next-line import/no-anonymous-default-export
export default {
  "dashboar.refresh": "Actualizar",
  "dashboard.refresh.desc": "Cada {{time}} {{timeUnit}}",
  "All rights reserved": "Todos los derechos reservados.",
  Dashboard: "Panel de rendimiento",
  Planner: "Planificadora",
  "Resource Planner": "Planificadora",
  Orders: "Órdenes",
  Invoices: "Facturas",
  Regulations: "Reglamentos",
  Suppliers: "Proveedores",
  Rapports: "Reportes",
  Search: "Buscar",
  "Add your Invoice": "Añadir tu factura",
  browse: "Navegar",
  "Show all": "Mostrar todos",
  Supplier: "Proveedor",
  "Invoice No.": "N² factura",
  "Release date": "Fecha de lanzamiento",
  "restriction.form.resources": "Recursos",
  "restriction.form.priority": "Prioridad",
  "restriction.form.priority.label": "Prioridad de su automatización",
  "restriction.form.allDays": "Todos los días",
  "restriction.form.executionTimeLine": "Cronología de ejecución",
  "restriction.form.to.error": "La fecha debe ser mayor que ",
  "restriction.form.estimationExecutaion.required.error":
    "Introduzca un tiempo de ejecución",
  "restriction.form.numberInteger.error": "Introduzca un número entero válido",
  "restriction.form.minValue.error": "El valor debe ser mayor o igual a 0.",
  "restriction.form.maxValue.error":
    "El número de dígitos debe ser menor o igual a 9.",
  "restriction.form.frequency": "Frecuencia",
  "restriction.form.occurence": "Ocurrencia",
  "restriction.form.priority.required.error": "Introduzca un número",
  "restriction.form.priority.minValue.error":
    "Introduzca un valor mayor o igual a 1",
  "restriction.form.priority.maxValue.error":
    "Introduzca un valor menor o igual a 100",
  "restriction.form.executionDay": "Día de la ejecución",
  "restriction.form.executionTime": "Tiempo de ejecución",
  "restriction.form.from": "De",
  "restriction.form.to": "En",
  "restriction.form.MinimumExecutionFrequency":
    "Frecuencia mínima de ejecución",
  Mon: "Lun",
  Tue: "Mar",
  Wed: "Mié",
  Thu: "Jue",
  Fri: "Vie",
  Sat: "Sáb",
  Sun: "Dom",
  "restriction.form.estimatedExecutionDuration": "Duración estimada de ejecución",
  "restriction.form.allowedSession": "Número de ejecuciones paralelas permitidas",
  "restriction.form.estimatedTime": "Tiempo estimado",

  "restriction.userGuide.resources":
    "Indica en qué máquinas (Recursos) se puede ejecutar este automatización. Para agregar recursos elegibles, comuníquese con su equipo de RPA.",
  "restriction.userGuide.priority":
    "Cuando todos los SLA son iguales, el automatización con la prioridad más alta (el número más bajo) se ejecutará primero.",
  "restriction.userGuide.executionTimeLine":
    "Elija los días y las horas en que se permite ejecutar este automatización.",
  "restriction.userGuide.estimatedExecutionTime":
    "El tiempo promedio que toma completar este automatización.",
  "restriction.userGuide.allowedSession":
    "La cantidad de ejecuciones paralelas permitidas para este automatización, si hay suficientes licencias y máquinas disponibles para ejecutarlas.",
  "restriction.userguide.frequencyOccurence":
    "La frecuencia mínima de ejecución que el automatización debe ejecutar.",
  "Due date": "Fecha de vencimiento",
  "Price WT": "Precio sin IVA",
  "Taxe Rate": "IVA",
  Price: "Precio",
  "Purchase order": "Orden de compra",
  "Last actions": "Últimas acciones",
  Today: "Hoy",
  Yesterday: "Ayer",
  "Update using historical data": "Actualizar usando datos históricos",
  Day: "Día",
  "no execution in this period": "No se encontró ninguna ejecución.",
  "3 DAYS": "3 días",
  Week: "Semana",
  "Last week": "Última semana",
  "Estimated Execution":
    "Con base en los criterios de selección, el Tiempo Estimado de Ejecución para este automatización es ",
  "Use Historical data": "Usar datos históricos?",
  "Choose the period":
    "Elija el período a tener en cuenta al calcular el Tiempo Estimado de Ejecución para este automatización :",
  Status: "Estado",
  Date: "Fecha",

  "My tasks": "Mis tareas",
  "Invoices to verify": "Facturas a verificar",
  "Invoices to be checked": "Facturas a comprobar",
  "Invoices to Payers": "Facturas a pagar",
  "Paid invoices": "Facturas pagadas",
  "Unpaid invoices": "Facturas sin pagar",

  "Total amount of invoices": "Importe total de facturas",
  "Total VAT amount": "Importe total IVA",
  "Statistical overview of purchases": "Resumen estadístico de las compras",
  "Total Purchases": "Total de compras",
  "Total of expenses": "total de gastos",
  Late: "Con Retraso",
  // "Late paiement": "Retraso del pago";  "Con Retraso"= "With Delay"
  Administrator: "Administrador",
  "administration.status": "Estado",
  "administration.company": "Empresa",
  "administration.userId": "ID de usuario",
  "administration.name": "Nombre de usuario",
  "administration.creationDate": "Fecha de creación",
  "administration.role": "Rol",
  "administration.email": "Correo electrónico",
  "administration.phone": "Teléfono",
  "administration.departement": "Departamento",
  "administration.subscription": "Suscripción",
  "administration.activate.deactivate.user.message":
    "¿Está seguro que desea activar / desactivar este usuario?",
  "administration.orchestrator.resource.list.not.available":
    "No hay recursos disponibles",
  "administration.orchestrator.process.list.not.available":
    "No hay automatización disponibless",
  "user.add.civility": "Estado civil",
  "user.add.lastName": "Apellido",
  "user.add.firstName": "Nombre",
  "user.add.creationDate": "Fecha de creación",
  "user.add.entreprise": "Empresa",
  "user.add.subscription": "Suscripción",
  "user.add.email": "Correo electrónico",
  "user.add.pass1": "Contraseña",
  "user.add.pass2": "Repetir contraseña",
  "user.add.comm.lang": "Lenguaje de comunicación",
  "user.add.comm.lang.helperText": "Todas las comunicaciones y correos electrónicos enviados desde SmartRoby estarán en el idioma seleccionado",
  "user.add.process": "Proceso",
  "user.add.availableProcess": "Automatización disponibles",
  "user.add.affectedProcess": "Automatización afectados",
  "user.add.button": "AGREGAR USUARIO",
  "user.add.mfa": "OTP requerido",
  "user.add.fleet.admin": "Administrador de banda",
  "user.status.active": "ACTIVO",
  "user.status.disable": "DESACTIVAR",
  "user.modify": "Modificar",
  "process.modify": "Modificar",
  "process.hidden": "Oculta",
  "user.button.save": "Confirmar",
  "user.button.cancel": "Cancelar",
  "user.add": "Agregar",
  "user.title.add": "Agregar usuario",
  "user.title.details": "Detalles del usuario",
  "user.button.update": "Actualizar",
  "user.update.confirmMsg":
    "¿Está seguro de que desea actualizar los detalles de este usuario?",
  "orchestrator.update.confirmMsg":
    "¿Está seguro de que desea actualizar los detalles de este orquestador?",
  "user.save.confirmMsg": "¿Está seguro que desea guardar este cliente?",
  "user.delete.confirmMsg": "¿Está seguro de que desea borrar este usuario?",
  "orchestrator.save.confirmMsg":
    "¿Está seguro de que desea agregar este orquestador?",
  "user.delete.discard": "¿Está seguro que desea eliminar este cliente?",
  "invalid.value": "Valor no válido",
  Required: "Requerido",
  "exception.type": "Tipo de excepción",
  "orchestrator.button.save": "Agregar orquestador",
  "exception.time": "Fecha de excepción",
  Mr: "Señor",
  Mrs: "Sra",
  Ms: "Srta",
  firstName: "Nombre",
  lastName: "Apellido",
  companyName: "Empresa",
  "Visualise Scheduler": "Visualizar el programa",

  "tooltip.action.delete": "Borrar",
  "tooltip.action.edit": "Modificar",
  "tooltip.action.view": "Ver",
  "tooltip.action.add": "Agregar",
  "720 heurs/an": "720 hora/año",
  "1800 heurs/an": "1800 hora/año",
  "60 jours/an": "60 día/año",
  "120 jours/an": "120 día/año",
  "3 mois/an": "3 meses/año",
  "5 mois/an": "5 meses/año",
  "720 hours/year": "720 hora/año",
  "1800 hours/year": "1800 hora/año",
  "60 days/year": "60 día/año",
  "120 days/year": "120 día/año",
  "3 months/year": "3 meses/año",
  "5 months/year": "5 meses/año",
  "user.roles.admin": "Administrador",
  "user.roles.user": "Usuario",
  "New suppliers": "Nuevos proveedores",
  "sorted descending": "Ordenados descendentes",
  "sorted ascending": "Ordenados ascendentes",
  Invoicing: "Facturación",
  Alerts: "Alertas",
  "User Management": "Gestión de usuarios",
  "Audit Logs": "Registros de auditoría",
  Documentation: "Documentación",
  Services: "Servicios",
  "National identity card": "Documento nacional de identidad",
  "driving licence": "Permiso de conducir",
  Passport: "Pasaporte",
  "Green card": "Green card",
  "registration card": "Tarjeta de registro",
  "Car registration papers": "Registro de vehículos",
  Facture: "Factura",
  Invoice: "Factura",
  RIB: "RIB",
  "Order form": "Solicitud de pedido",
  payslip: "Nómina",
  "": "Extracto de nómina",
  "Notice of assessment": "Aviso de impuestos",
  "Tax notice": "Aviso de impuestos",
  "My Services": "Mis servicios",
  Tutorials: "Tutoriales",
  "Statistical overview of success and exception rates":
    "Análisis estadístico de las tasas de éxito y de excepción",
  "Executed Processes": "Automatización ejecutados",
  "Execution Time": "Tiempo de ejecución",
  "Process execution duration": "Duración de la ejecución",
  "Item execution duration": "Duración de ejecución del elemento",
  "Latest Exceptions": "Últimas excepciones",
  "planned.executions": "Ejecuciones planificadas",
  resource: "Recurso",
  "planner.fullScreenExit": "Wyjście w trybie pełnoekranowym",
  "planner.fullScreen": " Otwórz planer w pełnoekranowym programie ",
  "Risk management": "Gestión de riesgos",
  "planner.risktime": "Tiempo de riesgo",
  "Insurance Validation and Entry": "Validación y entrada del seguro",
  "Resource Leveling": "Nivelación de recursos",
  "Least Executed Processes": "Automatización menos ejecutados",
  "Most Executed Processes": "Automatización más ejecutados",
  "My Active Orchestrators": "Mis orquestadores activos",
  "Last Transcriptions": "Últimas transcripciones",
  "Total API Calls": "Total de llamadas a la API",
  "Remaining Balance": "Saldo restante",
  "Success rate": "Tasa de éxito",
  "Exception rate": "Tasa de excepción",
  "Use of APIs": "Consumo de APIs",
  "My Active Services": "Mis servicios activos",

  // smart recursos -> My Processes
  "Invoice Management": "Gestión de facturas",
  "Client Onboarding": "Integración del cliente",
  "Bank Reconciliation": "Reconciliación bancaria",
  "Expense Report Management": "Gestión del informe de gastos",
  "Salary Statement Monitoring": "Supervisión del informe de nómina",
  "Virtual Assistant for agents": "Asistente virtual para agentes",
  "Trigger Details": "Detalles del disparador",
  "Start Process": "Iniciar automatización",
  "Stop Process": "Detener automatización",
  Schedule: "Calendario",
  Prioritize: "Priorizar",
  Sheet: "Hoja",
  Parameters: "Parámetros",
  "no entity currently": "No hay entidad actualmente.",
  "Time saved": "Tiempo ahorrado",
  "Process overview": "Tiempo de ejecución por automatización",
  "Resource overview": "Tiempo de ejecución por recurso",
  process: "automatización",
  "of the total": "del total",
  "Number of executions": "Número de ejecuciones",
  "Execution time / Time saved": "Tiempo de ejecución / Tiempo ahorrado",
  "Latest Transcriptions": "Últimas transcripciones",
  "Processes launcher": "Iniciador de Automatización",
  "Drop process": "Deposite aquí para iniciar el Proceso",
  "Break-even": "Rentabilidad",
  "Return on Investment": "Retorno de inversión",

  // smart ocr cards
  "Sage Interface": "Interfaz de Sage",
  "SAP Interface": "Interfaz de SAP",
  "Central Banking Data Integration":
    "Integración de los datos del banco central",
  "Salesforce Interaction": "Interfaz del equipo de ventas",
  soon: "Próximamente",
  "under construction": "En construcción.",
  // smart ocr services
  "Analyze a new file": "Analizar un archivo nuevo",
  Receipt: "Recibo",
  "EDF proof": "Prueba EDF",
  "Click here or drop a file to download":
    "Haga clic aquí o suelte un archivo para descargar",
  "Loaded File": "Archivo cargado",
  "File preview": "Vista previa del documento",
  Informations: "Informaciones",
  "Document name": "Nombre del documento",
  "File content type": "Tipo de contenido de archivo",
  userId: "ID de usuario",
  "First name": "Nombre",
  "Last name": "Apellido",
  CIN: "CIN",
  "Date of Birth": "Fecha de nacimiento",
  "Place of birth": "Lugar de nacimiento",
  "Expiration date": "Fecha de expiración",
  Address: "Dirección",
  Gender: "Género",
  CvilStatusNo: "Estado civil",
  Overview: "Visión general",
  Historical: "Histórico",
  "General Information": "Información general",
  "Schedule Information": "Información del calendario",
  Description: "Descripción",
  processOriginalName: "Nombre original del automatización",
  Priority: "Prioridad",
  "Start date": "Fecha de inicio",
  "End date": "Fecha de fin",
  "End Date": "Fecha de fin",
  Cron: "Cron",
  "Unit cost": "Costo unitario",
  "Number of units": "Número de unidades",
  "Process cost": "Costo del automatización",
  "add.schedule": "Crear horario",
  "add.process": "Agregar automatización",
  Reset: "Reiniciar",
  Delete: "Borrar",
  Update: "Actualizar",
  Save: "Guardar",
  Cancel: "Cancelar",
  Confirm: "Confirmar",
  Add: "Agregar",
  "Are you sure delete this row ?":
    "¿Estás seguro de que quieres borrar esta línea?",
  "This process was started successfully": "Este automatización se inició con éxito",
  "The process has been added to the SR Queues.": "La ejecución solicitada se ha agregado a la cola de SR.",
  "An execution trigerred manually on the same resource has already been requested for this process": "Ya se ha solicitado una ejecución manual en el mismo recurso para este automatización",
  "The process start failed! Please try again later":
    "¡El inicio del automatización falló! Por favor, inténtelo nuevamente más tarde",
  ERRORS_EMPTY_FIELD: "El campo no puede estar vacío.",
  ERRORS_LESS_THAN_MIN_FIELD: "Por favor introduce un valor mayor que {{ limit }}",
  ERRORS_INVALID_DATA: "Ingrese datos válidos.",
  ERRORS_NO_DATA: "Datos no encontrados!",
  WEIGHTS_SUM_MUST_BE_100: "La suma de los pesos debe ser 100%",
  "Start date must be less than the end date":
    "La fecha de inicio debe ser menor que la fecha de finalización",
  "End date must be greater than the start date":
    "La fecha de finalización debe ser mayor que la fecha de inicio",
  "Start time": "Hora de inicio",
  "End time": "Hora de finalización",
  "How much time does it take an employee to execute this process?":
    "¿Cuánto tiempo le toma a un empleado ejecutar este automatización?",
  "How much time does it take an employee to execute an execution in this process? (in minutes)":
    "¿Cuánto tiempo le toma a un trabajador realizar una ejecución de este automatización? (en minutos)",
  "How much does the hour cost for an employee executing this task?":
    "¿Cuánto cuesta la hora para un empleado que ejecuta esta tarea?s",
  "How much does this process cost per month ?":
    "¿Cuánto cuesta este automatización por mes?",
  Recurrence: "Recurrencia",
  Previous: "Previo",
  "Document Details": "Detalles del documento",
  "document details": "Detalles del documento",
  "Document Overview": "Resumen del documento",
  "document overview": "Resumen del documento",
  Validate: "Validar",
  validate: "Validar",
  "The document was updated successfully":
    "El documento se actualizó con éxito",
  Upload: "Cargar",
  upload: "Cargar",

  // authentication page
  "Enter your mail": "Ingrese su correo",
  "Confirm your mail": "Confirme su correo",
  "Enter your password": "Ingresa tu contraseña",
  Login: "Iniciar sesión",
  "Sign up to SmartRoby": "Regístrate en SmartRoby",
  Register: "Registrar",
  "You are already signed up": "Ya estás registrado",
  "Go to SmartRoby": "Ir a SmartRoby",
  "Registration was successfull, you will be redirected":
    "El registro fue exitoso, será redirigido",
  "Registration failed": "Registro fallido",
  Close: "Cerrar",
  "Aws registration token missing": "Falta el token de registro de Aws",
  "Email not matching": "El correo no coincide",
  "Forgot your password ?": "¿Olvidaste tu contraseña?",
  "Login or password incorrect !": "Usuario o contraseña incorrecta!",
  "Copyright © Smart-Roby  All rights reserved.":
    "Copyright © Smart-Roby  Todos los derechos reservados.",
  English: "Inglés",
  French: "Francés",
  Spanish: "Español",
  Polish: "Polaco",
  Germany: "Alemán",
  Arabic: "Árabe",
  "Process executions": "Ejecuciones",
  Ready: "Lista",
  Executing: "Ejecutando",
  "Executed by": "Ejecutado por",
  "you want to modify this data ?": "¿Quieres modificar estos datos ?",
  Yes: "Si",
  No: "No",
  "Data saved with success": "Datos guardados con éxito",
  "Data not saved": "Datos no guardados",
  "Hour(s)": "Hora(s)",
  "Day(s)": "Día(s)",
  "Week(s)": "Semana(s)",
  "Month(s)": "Mes(es)",
  "Year(s)": "Año(s)",
  Year: "Año",
  Every: "Cada",
  "Choose period": "Elige un período",
  "Your input is required": "Tu entrada es requerida",
  "The process": "El automatización",
  "is running, try again when it's completed.":
    "se está ejecutando, vuelva a intentarlo cuando esté completado.",
  "Are you sure you want to execute this process ?":
    "¿Está seguro que quiere ejecutar este automatización?",
  Domain: "Dominio",
  "Process name": "Nombre del automatización",
  "Process reference": "Referencia del automatización",
  "Number used of systems and applications":
    "Número de sistemas y aplicaciones utilizados",
  "SAP environments (if applicable)": "Entornos SAP (si aplica)",
  Frequency: "Frecuencia",
  Volume: "Volumen",
  "Rule-based": "Reglamentado",
  Standardization: "Estandarización",
  Others: "Otras",
  "Structured data": "Datos estructurados",
  "IT environment": "Entorno IT",
  "Test enabled": "Prueba habilitada",
  "Documentation Readiness": "Disponibilidad de la documentación",
  "Authentication mode": "Modo de autenticación",
  "Process UoM": "Unidad de medida del automatización",
  "Time spent for one execution": "Tiempo empleado para una ejecución",
  "Frequency of occurence": "Frecuencia de ocurrencia",
  "Time Spent": "Tiempo empleado",
  "Time granularity": "Unidad de tiempo de ejecución",
  "Occurence time granularity": "Unidad de tiempo de ocurrencia",
  Occurrence: "Ocurrencia",
  "Occurrence driver": "Impulsor de la ocurrencia",
  "Total time spent in hours per year":
      "Tiempo (en horas) devuelto al negocio por año",
  "What does an execution represent?": "¿Qué representa una ejecución?",
  "Re-usability": "Reutilización",
  "Expected qualitative benefits": "Beneficios cualitativos esperados",
  "Process Stability": "Estabilidad del automatización",
  "Number of steps": "Número de pasos",
  "Number of windows": "Número de ventanas",
  "Business Rules": "Regla del negocio",
  "Business Applications": "Aplicaciones del negocio",
  "Complex functions - Unstructured data / OCR":
    "Funciones complejas - Datos no estructurados / OCR",
  "Complex functions - DataBase": "Funciones complejas - Base de Datos",
  "Complex functions - Powershell": "Funciones complejas - Powershell",
  "Complex functions - Active Directory":
    "Funciones complejas - Active Directory",
  "Complex functions - VB Script": "Funciones complejas - VB Script",
  "Complex functions - Web Services": "Funciones complejas - Web Services",
  "Type of applications - Web application":
    "Tipo de aplicaciones - Aplicación web",
  "Type of applications - Windows application":
    "Tipo de aplicaciones - Aplicación de Windows",
  "Type of applications - Mainframe application":
    "Tipo de aplicaciones - Aplicación Mainframe ",
  "Type of applications - Citrix application":
    "Tipo de aplicaciones - Aplicación Citrix",
  "Automation without RPA": "Automatización sin RPA",
  "FTE Time to Process": "FTE Tiempo Para Procesar",
  "FTE Cost": "El costo de FTE",

  Accounting: "Contabilidad",
  Controlling: "Controlador",
  Other: "Otros",
  "assessment.Yes - Completely based on objective criteria":
    "Sí - Completamente basado en criterios objetivos",
  "assessment.No - Completely judgment-based":
    "No - Completamente basado en el juicio",
  "assessment.Completely optimized process, managed at Group level":
    "Proceso completamente optimizado, gestionado a nivel de grupo",
  "assessment.Largely standardized process, mostly managed at group level":
    "Proceso ampliamente estandarizado, gestionado en su mayor parte a nivel de grupo",
  "assessment.Good level of standardisation, managed regionaly":
    "Buen nivel de estandarización, gestionado regionalmente",
  "assessment.Low degree of standardization and split responsibilities":
    "Bajo grado de normalización y división de responsabilidades",
  "assessment.Disaggregated process and split responsibilities":
    "Proceso desglosado y división de responsabilidades",
  "assessment.Yes - Completely structured (e.g. in a form/table/spreadsheet)":
    "Sí - Completamente estructurado (por ejemplo, en un formulario/tabla/hoja de cálculo)",
  "assessment.No - Completely un-structured e.g. non standardized email":
    "No - Completamente desestructurado, por ejemplo, correo electrónico no estandarizado",
  "assessment.Few simple applications (Excel, Outlook, …)":
    "Pocas aplicaciones simples (Excel, Outlook, ...)",
  "assessment.Few simple applications with a unique ERP instance or another / custom application":
    "Pocas aplicaciones simples con una instancia única de ERP u otra aplicación personalizada",
  "assessment.Few simple applications with a unique ERP instance custom application":
    "Pocas aplicaciones simples con una aplicación personalizada de instancia de ERP única",
  "assessment.No - Judgment based": "No - basado en juicio",
  "assessment.Yes - Based on objective criteria":
    "Sí - basado en criterios objetivos",
  "assessment.Few complex / custom applications or ERP instances":
    "Pocas aplicaciones complejas o personalizadas o instancias de ERP",
  "assessment.Several complex / custom applications, and a cloud application":
    "Varias aplicaciones complejas / personalizadas, y una aplicación en la nube",
  "assessment.Large number of applications, (i.e. several ERP instances)":
    "Gran número de aplicaciones, (es decir, varias instancias de ERP)",
  "assessment.Yes - Data-sets fully available to conduct multiple tests":
    "Sí - Los datos están totalmente disponibles para llevar a cabo múltiples pruebas",
  "assessment.No - No Data-sets available to conduct multiple tests":
    "No - No hay datos disponibles para llevar a cabo múltiples pruebas",
  "assessment.A detailed documentation is available":
    "Se dispone de una documentación detallada",
  "assessment.A high level documentation is available":
    "Se dispone de una documentación de alto nivel",
  "assessment.No documentation": "Sin documentación",
  "assessment.Standard authentication mode (ID and password)":
    "Modo de autenticación estándar (ID y contraseña)",
  "assessment.SSO only authentication mode":
    "Modo de autenticación solamente SSO",
  "assessment.Additional authentication required (e.g. USB key)":
    "Se requiere una autenticación adicional (por ejemplo, una clave USB)",
  "assessment.Basic documentation is available":
    "La documentación básica está disponible",
  "assessment.1": "1",
  Seconds: "Segundos",
  Minutes: "Minutos",
  Minute: "Minuto",
  Hours: "Horas",
  Days: "Días",
  months: "Meses",
  Months: "Meses",
  Weeks: "Semanas",
  Years: "Años",
  days: "días",
  weeks: "semanas",
  years: "años",
  left: "restantes",
  ago: "Desde {{value}} {{unit}}",
  "unit.manday": "d/h",
  "assessment.Fully Core process compliant":
    "Cumple totalmente con el automatización del núcleo",
  "assessment.Partially Core process compliant":
    "Cumple parcialmente con el automatización del núcleo",
  "assessment.Local (zone or country specific)":
    "Local (específico de la zona o del país)",
  "assessment.Major": "Mayor",
  "assessment.High": "Alto",
  "assessment.Medium": "Medio",
  "assessment.Low": "Bajo",
  "assessment.Minor": "Menor",
  "assessment.No change is planned": "No se planea ningún cambio",
  "assessment.No change is planned in the next 2 years":
    "No se planea ningún cambio en los próximos 2 años",
  "assessment.Process/system may change in next 2 years":
    "El automatización/sistema puede cambiar en los próximos 2 año",
  next: "Siguiente",
  "assessment.Process/system update planned in next 2 years":
    "El automatización/sistema tiene una actualización prevista en los próximos 2 años",
  "assessment.Frequent changes occur in the process":
    "Se producen frecuentes cambios en el automatización",
  "assessment.1-16": "1-16",
  "assessment.17-45": "17-45",
  "assessment.46-94": "46-94",
  "assessment.More than 94": "Más de 94",
  "assessment.1-4": "1-4",
  "assessment.5-10": "5-10",
  "assessment.11-20": "11-20",
  "assessment.More than 20": "Más de 20",
  "assessment.Between 2 and 3": "Entre 2 y 3",
  "assessment.Between 4 and 10": "Entre 4 y 10",
  "assessment.More than 10": "Más de 10",
  "assessment.Between 4 and 5": "Entre 4 y 5",
  "assessment.More than 5": "Más de 5",
  true: "verdadero",
  false: "falso",
  Process: "Proceso",
  Planned: "Planificado",
  Paused: "Pausado",
  "Rows Per page": "Filas por página",
  "pagination.of": "de",
  Completed: "Completado",
  Running: "Ejecutando",
  RUNNING: "Ejecutando",
  "How long will it take an employee to complete this process?":
    "¿Cuánto tiempo le tomará a un empleado completar este automatización?",

  "How much does it cost per hour for an employee to perform this task?":
    "¿Cuánto cuesta por hora que un empleado realice esta tarea?",
  "user has exception item": "Esta cuenta de usuario tiene excepciones adjuntas.",
  "Unexpected Error": "Error Inesperado.",
  unknown: "Desconocido",
  Unknown: "Desconocido",
  UNKNOWN: "Desconocido",
  "This Week": "Últimos 7 días",
  "This Month": "Últimos 30 días",
  "This Year": "Últimos 365 días",
  "All time": "Todo el tiempo",
  THIS_WEEK: "Esta semana",
  THIS_MONTH: "Este mes",
  THIS_YEAR: "Este año",
  Discard: "Descartar",
  "Exception Reason": "Razón de la excepción",
  "Exception Workflow Status": "Estado",
  "Exception Assignee": "Asignar a",
  More: "Más",
  Less: "Menos",
  "Hide filters": "Ocultar filtros",
  READY: "No manejado",
  DONE: "Manejado",
  TAKEN: "Galardonado",
  "kpi.parameter.ConsiderBusinessExceptionsAsCompletedItems": "Considere las excepciones comerciales como elementos completos en el monitoreo de KPI.",
  "kpi.parameter.ConsiderBusinessExceptionsAsCompletedItems.tooltip": "Todas las excepciones comerciales se considerarán como completadas con éxito",
  "users deleted successfully": "Usuario(s) se ha(n) eliminado correctamente.",
  "fleet deleted successfully": "Banda eliminado exitosamente.",
  "orchestrator deleted successfully": "orquestador eliminado con éxito.",
  "queue deleted successfully": "Cola SR eliminada con éxito",
  "resource.hidden.success": "Recurso {{status}} exitoso",
  hidden: "oculto",
  unhidden: "no oculto",
  "Schedule Name": "Nombre del calendario",
  "header.kpi.exceptions": "Excepciones a tratar",
  Period: "Período",
  Exceptions: "Excepciones",
  Actions: "Acciones",
  Reason: "Razón",
  "Exception Time": "Tiempo de excepción",
  "Loaded Time": "Tiempo de carga",
  "How much time does it take an employee to execute an Item in this process? (in minutes)":
    "¿Cuánto tiempo le toma a un empleado ejecutar un elemento en este automatización? (en minutos)",
  "How much does this Process cost to maintain per Year?":
    "¿Cuánto cuesta mantener este automatización por año?",
  "Financial Parameters": "Parámetros financieros",
  "Cost Shared": "Costes compartidos",
  Weight: "Peso",
  "Allocation Amount": "Importe de la asignación",
  "Cost Configuration": "Configuración del coste",
  "Save ": "Guardar",
  "Cancel ": "Cancelar",
  "Time Saved per Item": "Tiempo ahorrado por artículo:",
  "Time saved for each completed item of this process (in minutes)":
  "Tiempo ahorrado por cada elemento completado de este automatización (en minutos)",
  "Time Saved per Execution": "Tiempo ahorrado por ejecución:",
  "Cost Name": "Coste Nombre",
  " Time saved for each execution of this process excluding the time saved from every item (in minutes)":
  " Tiempo ahorrado por cada ejecución de este automatización excluyendo el tiempo ahorrado de cada elemento (en minutos)",
  "Cost Category": "Categoría de costes",
  "Add tag": "Añadir etiqueta",
  "Edit tag": "Editar etiqueta",
  "Delete tag": "Eliminar etiqueta",
  "Tags Module": "Etiquetas",
  Category: "Categoría",
  "Delete Item": "Eliminar el elemento",
  "Delete cost": "Suprimir coste",
  "Edit cost": "Editar coste",
  "Are you sure you want to delete this cost ?": "¿Seguro que quieres borrar este coste?",
  "This cost is shared with multiple processes, if you would like to remove the cost allocated to this process individually, please edit the cost and remove the processes from the Sharing paramteres. If you are sure you want to delete this cost from all processes, proceed with the delete.":
  "Este coste está compartido con múltiples automatización, si desea eliminar el coste asignado a este automatización individualmente, por favor edite el coste y elimine los automatización de los parámetros Compartidos. Si está seguro de que desea eliminar este coste de todos los automatización, proceda con la eliminación.",
  "Add cost": "Añadir coste",
  "Cost Allocation": "Asignación de costos",
  recurring: "Periódica",
  "one-shot": "Un disparo",
  exclusive: "Exclusivo",
  "FTE hourly cost": "Coste hora ETC",
  Type: "Tipo",
  shared: "Compartido",
  "Cost Amount": "Coste Importe",
  "Cost Type": "Tipo de coste",
  "Are you sure you want to update the parameters ?":
    "¿Está seguro de que desea actualizar los parámetros?",
  "Are you sure you want to create this schedule ?":
    "¿Estás seguro de que deseas crear este calendario?",
  "Are you sure you want to update this schedule ?":
    "¿Estás seguro de que quieres actualizar este horario?",
  "The schedule was created successfully": "Su solicitud se envió a BP y el horario se creará pronto",
  "Failed to create the schedule! Please try again later":
    "¡No se pudo crear el calendario! Por favor, inténtelo nuevamente más tarde",
  "Failed to update parameters": "Error al actualizar los parámetros",
  "Are you sure you want to disable this schedule ?":
      "¿Estás seguro de que quieres desactivar este horario ?",
  "Are you sure you want to enable this schedule ?":
      "¿Estás seguro de que quieres activar este horario ?",
   "button.deactivate.confirm":
      "Desactivar",
   "button.activate.confirm":
      "Activar",
  hours: "horas",
  Once: "Una vez",
  Hourly: "Por hora",
  Daily: "Diario",
  Weekly: "Semanal",
  Monthly: "Mensual",
  Yearly: "Anual",
  Quarterly: "Cuatrimestral",
  Trimestrial: "Trimestral",
  "Create schedule": "Editar el calendario",
  "Edit schedule": "Elegir el recurso",
  "Confirm frequency": "Confirmar frecuencia",
  "Update frequency": "Actualizar la frecuencia",
  "Choose Process": "Elegir el automatización",
  "Choose Resource": "Elegir el recurso",
  "Save changes": "Guardar cambios",
  "Start Date": "Fecha de inicio",
  "Are you sure you want to save the changes ?":
    "¿Está seguro que quiere guardar los cambios ?",
  "Failed to modify schedule": "No se pudo modificar el calendario",
  "Are you sure you want to disregard the changes ?":
    "¿Está seguro que desea descartar los cambios?",
  "Changes disregarded": "Cambios descartados",
  "Are you sure you want to delete this exception ?":
    "¿Estás seguro de que quieres ocultar esta excepción?",
  "Are you sure you want to show this exception ?":
    "¿Estás segura de que quieres mostrar esta excepción?",
  "Are you sure you want to delete these exception ?":
    "¿Está seguro que desea borrar estas excepciones ?",
  "Exception Deleted Successfully": "Excepción oculta con éxito!",
  "Exception Restored Successfully": "Excepción restaurada con éxito!",
  "Exceptions Deleted Successfully": "Excepciones ocultas con éxito!",
  "Are you sure you want to delete": "¿Estás seguro de que quieres esconder",
  "Are you sure you want to show X exceptions?":
    "¿Estás seguro de que quieres mostrar {{count}} excepcione(s)?",
  exceptions: "excepciones",
  "exceptions successfully deleted": "excepciones ocultas con éxito",
  "X exceptions successfully restored":
    "{{count}} excepcione(s) restauradas con éxito",

  // rpa qual
  Accepted: "Aceptada",
  accepted: "aceptada",
  Rejected: "Rechazada",
  rejected: "rechazada",
  Submitted: "Enviado",
  submitted: "enviado",
  Pending: "Pendiente",
  PENDING: "Pendiente",
  DELETED: "Eliminada",
  MISSED: "Omitido",
  MISSED_P: "Pendiente (Omitido)",
  PENDING_SR: "Pendiente SR",
  pending: "pendiente",
  Draft: "Borrador",
  "Not submitted": "No enviada",
  "Are you sure?": "¿Está seguro?",
  "You won't be able to revert this!": "¡No podrás revertir esto!",
  warning: "Advertencia",
  "Yes, submit it!": "Sí, envíalo.",
  "Yes, save it!": "Sí, guárdalo.",
  "Submit later": "Validar más tarde",
  "rpaqual.submit": "Validar",
  Back: "Atrás",
  Result: "Resultado",
  "Impact/Feasibility Matrix": "Matriz de factibilidad/impacto",
  Feasibility: "Factibilidad",
  "Red zone": "Zona roja",
  "Impact and feasibility are limited": "El automatización es complejo para automatizar con un impacto mínimo",
  "The process is likely to be reprioritized": "Se recomienda despriorizar este automatización a menos que se tengan en cuenta otros criterios durante la arbitraje",
  "Yellow zone": "Zona amarilla",
  "Here either the impact or the feasibility is relevant": "El impacto de la automatización o su viabilidad muestra un alto potencial",
  "Depending on the case and arbitration, the process could be deprioritized": "La priorización de este automatización debe ser arbitraje con otros criterios",
  "Green zone": "Zona verde",
  "Both impact and feasibility show some great signs": "El impacto de la automatización y su viabilidad muestran un alto potencial",
  "The process is likely to be prioritized": "Se recomienda priorizar este automatización",
  Roi: "ROI",
  "Saving (per year)": "Estimación de ganancias (por año)",
  "FTE Gains": "Ganancias FTE",
  "FTE Costs": "Costos FTE",
  "Total in $": "Total en $",
  "Developement costs": "Estimación de los costos de desarrollo (en d/h)",
  Developer: "Desarrollador",
  "Business analyst": "Analista del negocio",
  "Running costs (per year)": "Costos de funcionamiento (por año)",
  "Running costs in $": "Costos de funcionamiento en $",
  "Payback period (year)": "Período de amortización (año)",
  "Yes, accept it": "Sí, acepto",
  "Yes, reject it": "Sí, rechazo",
  "Yes, delete it": "Sí, bórralo",
  NAME: "NOMBRE",
  "CREATED BY": "Creado por",
  "Date created": "Fecha de creación",
  "Last update": "Última fecha de modificación",
  Impact: "Impacto",
  Edit: "Modificadora",
  Exit: "Salida",
  "Home - RPA Qualification Tool By Novelis innovation":
    "Home - Herramienta de calificación RPA de Novelis innovation",
  "No opportunities found !": "¡No se encontraron oportunidades!",
  "My Cases": "Mis Casos",
  "create.opportunity": "Crear oportunidad",
  "No cases found !": "¡No se encontraron casos!",
  "No options": "No hay opciones",
  "Tool for discovering, evaluating and classifying business processes":
    "Herramienta de descubrimiento, evaluación y clasificación de automatización del negocio",
  "Sign in": "Iniciar sesión",
  "Invalid email address": "Dirección de correo electrónico inválida",
  "Email Address": "Correo electrónico",
  "Password is required": "Se requiere una contraseña",
  "Password must contains at least 8 characters":
    "La contraseña debe contener al menos 8 carácteres",
  Password: "Contraseña",
  "No network": "Sin red",
  "Please verify your internet connection and try again !":
    "Por favor, verifique su conexión a Internet e intente de nuevo.",
  OK: "OK",
  "New process": "Nuevo automatización",
  "Edit process": "Para editar el automatización",
  "Page not found": "Página no encontrada",
  "Sorry, the page you are looking for cannot be found !":
    "Lo siento, la página que está buscando no se encuentra.",
  Reject: "Rechazar",
  Submit: "Enviar",
  "rpaQual.action.submit": "Validar",
  submit: "enviar",
  Accept: "Aceptar",
  "priority.error": "El valor ya existe, elija otro valor.",
  "priority.error.unhide": "El valor de prioridad de este automatización ya existe. Su automatización se mostrará sin ningún conjunto de prioridades.",
  "assessment.submit": "Enviar",
  "Select a Department": "Seleccione un departamento",
  "name your process": "Nombre su automatización",
  "describe briefly your process": "Describa brevemente su automatización",
  "Select a resource": "Seleccione un recurso",
  "Select if process is rule-based":
    "Seleccione si el automatización está basado en reglas",

  "Select if process is standardized":
    "Seleccione si el automatización está estandarizado",

  "Select if data used in the process is structured ":
    "Seleccione si los datos están estructurados",

  "Select if testing will be possible":
    "Seleccione si las pruebas serán posibles ",

  "the documentation level that fits the most with the context of your process":
    "el nivel de documentación que más se ajuste al contexto de su automatización",

  "what authentication mode is applied for the systems in scope":
    "¿Qué método de autenticación se aplica para los sistemas en cuestión?",

  "Time required for a single but complete process execution":
    "El tiempo necesario para la ejecución de un automatización único pero completo",

  "Time granularity of the above mentionned time":
    "La granularidad del tiempo mencionado anteriormente",

  "What is the value that would drive a round of process executions":
    "¿Cuál es el valor que impulsaría una ronda de ejecuciones",

  "eg: Weekly=52, Monthly=12, Daily=220":
    "por ejemplo: Semanal=52, Mensual=12, Diario=220 ",

  "This field is populated automatically from the details time spent below":
    "Este campo se rellena automáticamente a partir de los detalles del tiempo de permanencia a continuación",

  "Assess re-usability to the best of your knowledge":
    "Evaluar la reutilización según su conocimiento",

  "Explain what are the benefits expected, e.g. better quality":
    "Explique cuáles son los beneficios esperados, por ejemplo, una mejor calidad",

  "Select the sentence that fits the most with the context of your process":
    "Seleccione la frase que más se ajuste al contexto de su automatización",

  "Select the number of steps": "Seleccione el número de pasos",

  "number of screens that change during the execution ":
    "Número de pantallas que cambian durante la ejecución",

  "number of  the business rules governing the process":
    "Número de reglas del negocio que rigen el automatización",

  "number of business applications involved in the process":
    "Número de aplicaciones del negocio que participan en el automatización",

  "whether unstructured data or any OCR processing will be processed":
    "Si se procesarán datos no estructurados o cualquier procesamiento de OCR",

  "communication with a database to establish or any sql commands will be executed":
    "Comunicación con una base de datos para establecer o cualquier comando sql será ejecutado",

  "if any Powershell script to install or any powershell code to execute in the command line":
    "Si cualquier script de Powershell a instalar o cualquier código de Powershell a ejecutar en la línea de comandos",

  "if the resources will have a Windows account and communicate by email or Teams":
    "Si los recursos tendrán una cuenta en Windows y se comunicarán por correo electrónico o por equipos",

  "if there is a need to create VB scripts or run macros directly on blue prism ":
    "Si hay necesidad de crear scripts VB o ejecutar macros directamente en BluePrism ",

  "if there is a possibility to use web services or to expose them for other processes":
    "Si existe la posibilidad de utilizar los servicios web o de exponerlos para otros automatización",

  "if the process interacts with web applications via browsers":
    "Si el automatización interactúa con las aplicaciones web a través de los navegadores",

  "if the process interacts with Windows applications installed on the computer":
    "Si el automatización interactúa con las aplicaciones de Windows instaladas en la computadora",

  "if  there is any application that we access via an emulator ":
    "Si hay alguna aplicación a la que se accede a través de un emulador ",

  "if  there is any application that we access via the cloud":
    "Si hay alguna aplicación a la que se accede a través la nube",

  "the number of steps": "Número de pasos ",

  Comment: "Comentarios",
  Name: "Nombre",
  createdBy: "Creado por",
  updatedBy: "Ultima modificacion por",
  "Last Update ": "Última actualización",

  // scheduler
  "Are you sure you want to disregard this schedule ?":
    "¿Realmente quieres descartar este calendario?",
  "Schedule disregarded!": "¡Calendario descartado!",
  "Feasibility/Impact": "Factibilidad/Impacto",

  // kpi
  January: "Enero",
  February: "Febrero",
  March: "Marzo",
  April: "Abril",
  May: "Mayo",
  June: "Junio",
  July: "Julio",
  August: "Agosto",
  September: "Septiembre",
  October: "Octubre",
  November: "Noviembre",
  December: "Diciembre",

  "7 Weeks ago": "7 Semanas Atrás",
  "6 Weeks ago": "6 Semanas Atrás",
  "5 Weeks ago": "5 Semanas Atrás",
  "4 Weeks ago": "4 Semanas Atrás",
  "3 Weeks ago": "3 Semanas Atrás",
  "2 Weeks ago": "2 Semanas Atrás",
  "Last Week": "Última semana",

  "hours ago": "hace #NB# horas",
  "1 hour ago": "hace 1 hora",
  xHoursAgo: "hace {{hours}} horas",

  Monday: "Lunes",
  Tuesday: "Martes",
  Wednesday: "Miércoles",
  Thursday: "Jueves",
  Friday: "Viernes",
  Saturday: "Sábado",
  Sunday: "Domingo",
  "My Processes": "Automatizaciones",
  "Choose frequency": "Elija la frecuencia",
  "Default Alerts": "Alertas predeterminadas",
  "View default alerts": "Ver alertas predeterminadas",
  "Edit default alerts": "Editar alertas predeterminadas",
  "Delete default alert": "Eliminar alerta predeterminada",
  "Add default alert": "Agregar alerta predeterminada",
  "Resource Administration": "Administración de recursos",
  "Manage resource": "Gestionar recurso",
  "Custom Alerts": "Alertas personalizadas",
  "View custom alerts": "Ver alertas personalizadas",
  "Edit custom alerts": "Editar alertas personalizadas",
  "Delete custom alert": "Eliminar alerta personalizada",
  "Add custom alert": "Agregar alerta personalizada",
  "Process Exceptions": "Excepciones de automatización",
  "Item Exceptions": "Excepciones de elemento",
  Analytics: "Reportes",
  Administration: "Administración",
  "Alerts Management": "Alertas",
  "Process Assessment": "Evaluación de oportunidades",
  "title.processassessment": "EVALUACIÓN DE PROCESOS",

  "Delete Schedule": "Borrar el calendario",
  Schedules: "Calendarios",
  Loading: "Cargando",
  Disregard: "Descartados",
  EXCEPTION: "Excepción",
  "Retry Exceptions": "Reintentar excepciones",
  "Exception Type": "Tipo de excepción",
  "Show less": "Mostrar menos",
  "Filter List": "Filtrar la lista",
  "Process Mining": "Minería de automatización",
  Definition: "Definición",
  Profile: "Perfil",
  "Choose your timezone": "Elija su zona horaria",
  "IT Review": "Complejidad",
  "save as draft": "guardar como borrador",
  "Save as draft": "Guardar como borrador",

  "Total executions": "Total de ejecuciones",
  "Number of process executions": "Número de ejecuciones",
  "Latest activities": "Últimas actividades",

  "Creation date (Descending)": "Fecha de creación (descendente)",
  "Creation date (Ascending)": "Fecha de creación (ascendente)",
  "Exception Time (Descending)": "Fecha de excepción (descendente)",
  "Exception Time (Ascending)": "Fecha de excepción  (ascendente)",
  "End date (Descending)": "Fecha final (descendente)",
  "End date (Ascending)": "Fecha final (ascendente)",
  "Deferral date (Descending)": "Fecha de aplazamiento (descendente)",
  "Deferral date (Ascending)": "Fecha de aplazamiento (ascendente)",
  "Deferral date": "Fecha de aplazamiento",
  "Creation date": "Fecha de creación",
  "Exception date": "Fecha de excepción",
  "Sort By": "Ordenar por",
  "Document Name": "Nombre del documento",
  "Document Status": "Estado del documento",
  Preview: "Vista previa",
  "The document was sent to the orchestrator":
    "El documento fue enviado al orquestadore.",
  "The process has started successfully": "El automatización ha comenzado con éxito",
  "Are you sure you want to delete this document ?":
    "¿Está seguro de que desea borrar este documento?",
  "Document Deleted Successfully": "Documento eliminado con éxito",
  "Show empty fields": "Mostrar campos vacíos",
  "Are you sure you want to delete this Opportunity ?":
    "¿De verdad quieres borrar esta oportunidad?",
  "Opportunity deleted successfully": "Oportunidad eliminada con éxito",
  // Added By Zineb

  START: "Inicio",
  Start: "COMIENZO",

  Of: "De",
  FOREVER: "SIEMPRE",
  "Are you sure you want to delete this schedule ?":
    "¿Está seguro que quiere borrar este calendario?",
  "Are you sure you want to delete this frequency ?": "¿Está seguro de que desea eliminar esta frecuencia?",
  "Schedule Deleted Successfully": " El calendario se ha eliminado con éxito",
  "Failed to delete schedule": "Error al eliminar el calendario",
  "SAUVEGARDER COMME BROUILLON": "SALVAR COMO BORRADOR",
  calculate: "calcular",
  Calculate: "Calcular",

  // Process Assessment Help By Zineb

  "Can a defined rule be applied to each step of this process?":
    "¿Existe una regla definida que pueda aplicarse en cada etapa de este automatización?",
  "To what extent is the process standard across locations? The more optimized & centrally managed the process is, the easier the automation tests & implementation will be.":
    "¿Hasta qué punto es el automatización estándar en todos los sitios? Cuanto más centralizado y gestionado esté el automatización, más fácil será probar e implementar la automatización.",
  "The more optimized & centrally managed the process is, the easier the automation tests & implementation will be.":
    "Cuanto más centralizado y gestionado esté el automatización, más fácil será probar e implementar la automatización.",
  "Are the process inputs provided in a structured manner?":
    "¿La entrada de los automatización se proporciona de manera estructurada?",
  "Is a full data set available to test this process ?":
    "¿Se dispone de un conjunto completo de datos para probar este automatización?",
  "What is the level of IT environment complexity?":
    "¿Qué tan complejo es el entorno IT?",
  "What level of documentation exists to support this process?":
    "¿Qué nivel de documentación existe para apoyar este automatización?",
  "What is scale of the security challenge for authentication?":
    "¿Cuál es la escala de la autenticación de seguridad?",

  "What is the Time required for a single but complete process execution?":
    "¿Cuánto tiempo se tarda en completar un único pero completo automatización?",
  "What is the frequency which this process occures?":
    "¿Con qué frecuencia se produce este automatización?",
  "What is the value that would drive a round of process executions?":
    "¿Cuál es el valor que llevaría a una serie de ejecuciones?",
  "Please indicate the nature of the driver for the occurrence of the process (number of company codes, accounts, postings, etc.)":
    "Por favor, indique la naturaleza del conductor para la ocurrencia del automatización (número de empresas, cuentas, entradas, etc.).",
  "Weekly=52, Monthly=12, Daily=220, etc. For specific frequencies, please add the details of your calculations in the comment section":
    "Semanal = 52, Mensual = 12, Diario = 220, etc. Para frecuencias específicas, por favor agregue los detalles de sus cálculos en la sección de comentarios.",

  "This field is populated automatically from the details from the Time Spent Section":
    "Este campo se rellena automáticamente a partir de los datos de la sección tiempo empleado",
  "Select gravity of benefits and mention in the comment area what are the benefits expected, e.g. better quality, productivity gains, reduced lead time etc.":
    "Seleccione la gravedad de los beneficios y mencione en el campo de comentarios los beneficios esperados, por ejemplo, mejora de la calidad, aumento de la productividad, reducción de los plazos de entrega, etc. ",
  "To what extent is this same process performed worldwide?":
    "¿En qué medida se aplica este mismo automatización en todo el mundo?",
  "Are systems/tool changes planned that would impact this process? (i.e. new report development)":
    "¿Se ha previsto algún cambio en los sistemas e instrumentos que pueda afectar a este automatización? (por ejemplo, la elaboración de nuevos informes)",

  "Select the number of steps (Clicks, navigation, reading and writing in each application window), if necessary precise in comments":
    "Seleccione el número de pasos (Clicks, navegación, lectura y escritura en cada ventana de la aplicación), si es necesario especifique en los comentarios",
  "Select the number of windows (screens) that change during the execution of the process, if necessary precise in comments":
    "Seleccione el número de ventanas (pantallas) que cambian durante la ejecución del automatización, si es necesario especifique en los comentarios",
  "Select the number of  the business rules governing the process, if necessary precise in comments":
    "Seleccione el número de reglas de negocio que rigen el automatización, si es necesario especifique en los comentarios",
  "Select the number of business applications involved in the process (Number of objects to be created), if necessary precise in comments":
    "Seleccione el número de aplicaciones empresariales que participan en el automatización (Número de objetos a crear), si es necesario especifique en los comentarios",
  "Select whether unstructured data (PDF, Excel, e-mail, Txt file) or any OCR processing will be processed, if necessary precise in comments":
    "Elija si se procesarán datos no estructurados (PDF, Excel, correo electrónico, archivo Txt) o cualquier procesamiento de OCR, si es necesario especifique en los comentarios",
  "Select whether a communication with a database to establish or any sql commands will be executed if necessary precise in comments":
    "Elija si se va a establecer una comunicación con una base de datos o si se va a ejecutar cualquier comando sql, si es necesario especifique en los comentarios",
  "Select if there is any Powershell script to install or any powershell code to execute in the command line, if necessary precise in comments":
    "Seleccione si hay un script de Powershell para instalar o un código de PowerShell para ejecutar en la línea de comandos, si es necesario especifique en los comentarios",
  "Select if the resources will have a Windows account and communicate by email or Teams, if necessary precise in comments":
    "Elija si los recursos tendrán una cuenta de Windows y se comunicarán por correo electrónico o por equipos, si es necesario especifique en los comentarios",
  "Select if there is a need to create VB scripts or run macros directly on blue prism if necessary precise in comments":
    "Seleccione si es necesario crear scripts VB o ejecutar macros directamente en el prisma azul si es necesario especifique en los comentarios",
  "Select if there is a possibility to use web services or to expose them for other processes, if necessary precise in comments":
    "Seleccione si existe la posibilidad de utilizar los servicios web o de exponerlos a otros automatización, si es necesario especifique en los comentarios",
  "Select if the process interacts with web applications via browsers (IE, Chrome, Firefox), if necessary precise in comments":
    "Indique si el automatización interactúa con aplicaciones web a través de navegadores (IE, Chrome, Firefox), si es necesario especifique en los comentarios",
  "Select if the process interacts with Windows applications installed on the computer, if necessary precise in comments":
    "Indique si el automatización interactúa con las aplicaciones de Windows instaladas en la computadora, si es necesario especifique en los comentarios",
  "Select if  there is any application that we access via an emulator (IBM, AS400, HUB 5250), if necessary precise in comments":
    "Seleccione si hay una aplicación a la que accedemos a través de un emulador (IBM, AS400, HUB 5250), si es necesario especifique en los comentarios",
  "Select if  there is any application that we access via the cloud (image), if necessary precise in comments":
    "Seleccione si hay una aplicación a la que accedemos a través de la nube (imagen), si es necesario especifique en los comentarios",
  "Select if this process can be automated without the use of RPA technologies":
    "Seleccione si este automatización puede ser automatizado sin el uso de la tecnología RPA",

  "Are you sure you want to save this case as a draft ?":
    "¿Está seguro que quiere guardar este caso como un borrador?",
  "Case saved as a draft": "Caso guardado como un borrador",
  "Are you sure you want to save and submit this case later ?":
    "¿Está seguro que quiere guardar y presentar este caso más tarde?",
  "Case saved but not submitted": "Caso guardado pero no presentado",
  "Are you sure you want to save and submit this case ?":
    "¿Está seguro que quiere salvar y presentar este caso?",
  "Case saved and submitted for approval":
    "Caso guardado y presentado para su aprobación",
  "rpaQual.transition.accepted":
    "¿Está seguro que quiere aceptar este caso como una oportunidad para la automatización?",
  "Case accepted as an opportunity": "Caso aceptado como una oportunidad",
  "Are you sure you want to reject this case ?":
    "¿Está seguro que quiere rechazar este caso?",
  "Case rejected": "Caso rechazado",
  "This case is not yet submitted. Are you sure you want to delete it ?":
    "Este caso aún no se ha presentado. ¿Está seguro que quiere borrarlo?",
  "Case deleted successfully": "Caso eliminado con éxito",
  "Case archived successfully": "Caso archivado con éxito",
  "This case is submitted and awaiting approval. Are you sure you want to delete it ?":
    "Este caso está presentado y en espera de aprobación. ¿Está seguro que quiere borrarlo?",
  "This case is an accepted opportunity for automation. Are you sure you want to delete it ?":
    "Este caso es una oportunidad aceptada para la automatización. ¿Está seguro que quiere borrarlo?",
  "Are you sure you want to delete this case ?":
    "¿Está seguro que quiere borrar este caso?",
  "Your progress will be lost, are you sure you want to exit ?":
    "Tu progreso se perderá, ¿Está seguro que quiere salir?",
  "Total in man-days": "Total en días-hombre",
  "Running costs in man-days": "Costes de mantenimiento en días-hombre",
  Logout: "Cierre de sesión",
  "Execution Count": "Ejecuciones",
  "Exception.count": "Excepciones",
  "point.amortissage.negative": "{{value}} {{unit}} restantes",
  "point.amortissage.positive": "{{value}} {{unit}} restantes",
  SearchProcess: "Búsqueda de automatización",
  searchUser: "Buscar usuario",
  status: "Estado",
  sortBy: "Ordenar por",
  "Execution per": "Ejecución por",
  "no.exception.message": "No se detectaron excepciones",
  "show.all.exception.button": "Mostrar todo",
  "Failed To create User": "No se puede crear el usuario",
  "Failed To create Scheduler": "No se ha podido crear el programa",
  "user.keycloak.exists":
    "El usuario ya existe en keycNo se ha podido crear el planificadorloak",
  "user.email.exists": "El usuario ya existe",
  "User with same email already exists": "Usuario con el mismo email ya existe",
  "user.update.fail": "Error en la actualización del usuario",
  "user.update.success": "¡Usuario actualizado con éxito!",
  deleteUsersActionMessage:
    "¿Está seguro de que desea borrar a estos usuarios?",
  "profile.update-password.current-password-required":
    "Se requiere contraseña actual",
  "profile.update-password.new-password-required":
    "Se requiere nueva contraseña",
  "profile.update-password.password-required": "Se requiere contraseña",
  "profile.update-password.password-not-matched":
    "Las contraseñas no coinciden",
  "profile.update-password.current-password": "Contraseña actual",
  "profile.update-password.new-password": "Nueva contraseña",
  "profile.update-password.confirm-password": "Confirmar nueva contraseña",
  "profile.update-password.reset-password": "Restablecer contraseña",
  "profile.update-password.password-reset": "Restablecimiento de contraseña",
  "profile.update-password.password-reset-success":
    "Su contraseña fue cambiada exitosamente",
  "profile.update-password.password-reset-done": "Hecho",
  "profile.update-password.update-password": "Cambiar la contraseña",
  "profile.update-password.email": "Tu correo electrónico actual",
  "profile.update-password.tokenError":
    "Token no válido, la contraseña ya está actualizada.",
  "profile.update-password.password-email-send":
    "Un correo electronico ha sido enviado. Por favor, revise su correo.",
  "profile.update.password.success": "Contraseña cambiada con éxito",
  "profile.update.password.wrong.current.password": "La contraseña actual es incorrecta",
  "token.validation.noteExist": "Solicitud de cambio de contraseña ya enviada",
  "Are you sure you want to start this process ?":
    "¿Está seguro de que desea ejecutar este automatización?",
  "Process Occurence": "Artículos completos",
  "Process Item": "Elementos",
  "Completed time": "Tiempo completado",
  "traited item": "Elementos procesados",
  "Items Count": "Artículos procesados",
  "process.devCost.edit.cancel":
    "Sus cambios se perderán, ¿está seguro que desea cancelar?",
  "process.devCost.edit.confirm":
    "¿Está seguro que quiere guardar tus cambios?",
  "process.status.pending": "PENDIENTE",
  draft: "BORRADOR",
  "not submitted": "NO ENVIADA",
  "Process CompletedItems": "Completos",
  "assessment.Accounting & Finance": "Contabilidad y Finanzas",
  "assessment.Compliance": "Conformidad",
  "assessment.Customer Service": "Atención al cliente",
  "assessment.Purchasing": "Compra",
  "assessment.IT Operations": "Operaciones de TI",
  "assessment.Maintenance": "Mantenimiento",
  "assessment.Sales": "Ventas",
  "assessment.Marketing": "Márketing",
  "assessment.Research and Development": "Investigación y desarrollo",
  "assessment.Production": "Producción",
  "assessment.Human Resources Management ": "Gestión de recursos humanos",
  "assessment.Software Development": "Desarrollo de software",
  "assessment.Other": "Otro",
  "assessment.Seconds": "Segundos",
  "assessment.Minutes": "Minutos",
  "assessment.Hours": "Horas",
  "assessment.Days": "Dias",
  "assessment.Months": "Meses",
  "assessment.Years": "Años",
  "assessment.Weeks": "Semanas",
  "Are you sure you want to accept this case as an opportunity for automation ?":
    "¿Estás seguro que desea aceptar este caso como una oportunidad para la automatización?",
  "Are you sure you want to save the changes?":
    "¿Está seguro que quiere guardar los cambios?",
  "Schedule saved successfully": "Frecuencia guardada con éxito",
  "Frequency Deleted Successfully": "Frecuencia Eliminado con éxito",
  "Schedule modified successfully": "Su solicitud se ha enviado a BP y el horario se actualizará pronto",
  "Failed to update the schedule! Please try again later":
    "¡Error al actualizar el calendario!Por favor, inténtelo de nuevo más tarde",
  "orchestrator.management.menu.title": "Orquestadores",
  "resource.management.menu.title": "Recursos",
  "orchestrator.management.add.button": "Conectar el orquestador",
  "orchestrator.management.delete.confirmMsg":
    "¿Está seguro de que desea eliminar el orquestador ? Todos los datos se perderán de forma permanente.",
  "orchestrator.management.deleteOrchestratorsActionMessage":
    "¿Está seguro de que desea borrar los orquestadores seleccionados?",
  "orchestrator.management.enaDes.confirmMsg":
    "¿Está seguro de que desea habilitar el orquestador ? Se reiniciará la conexión a su entorno RPA",
  "orchestrator.management.status": "Estado",
  "orchestrator.management.name": "Nombre",
  "orchestrator.management.solutionProvider": "Proveedor de soluciones",
  "orchestrator.management.isActionable": "Accionable",
  "orchestrator.management.orchestratorIp": "IP pública",
  "orchestrator.management.isAcionableToggle":
    "¿Estás seguro que quiere activar el indicador \"Accionable\"?",
  "orchestrator.management.editHeader": "Modificar el orquestadore",
  "orchestrator.management.addHeader": "Añadir un orquestadore",
  "orchestrator.management.displayHeader": "Ver el orquestadore",
  "orchestrator.management.formControl.required": "Campo obligatorio",
  "orchestrator.management.formControl.orchestratorType": "Tipo de orquestador",
  "orchestrator.management.formControl.orchestratorId": "ID de orquestadore",
  "orchestrator.management.formControl.orchestratorName":
    "Nombre del orquestadore",
  "orchestrator.management.formControl.orchestratorName.information":
    "El nombre del orquestador",
  "orchestrator.management.formControl.description.information":
    "Cualquier información que necesite agregar aquí",
  "orchestrator.management.formControl.orchestratorIp.information":
    "IP pública o privada de la máquina Orchestrator",
  "orchestrator.management.formControl.sessionNumber.information":
    "El número de sesiones permitidas en la licencia BP activa actual",
  "orchestrator.management.formControl.databaseServer.information":
    "El nombre de host del servidor de la base de datos",
  "orchestrator.management.formControl.serverPort.information":
    "Esto debe coincidir con el puerto de escucha configurado en el servidor SQL",
  "orchestrator.management.formControl.databaseName.information":
    "El nombre de la base de datos utilizada por el BluePrism",
  "orchestrator.management.formControl.dbUsername.information":
    "El nombre de usuario de la base de datos para usar al conectarse.El usuario debe tener permisos DB_DatarAader y DB_Datawriter en la base de datos de Blue Prism",
  "orchestrator.management.formControl.dbPassword.information":
    "La contraseña del nombre de usuario utilizado al conectarse",
  "orchestrator.management.formControl.apiClientId.information": "El ID de la aplicación proporcionado por UiPath al crearla",
  "orchestrator.management.formControl.apiClientSecret.information": "El secreto de la aplicación proporcionado por UiPath al crearla",
  "orchestrator.management.formControl.organizationName.information": "El nombre de la organización del Orchestrator de UiPath",
  "orchestrator.management.formControl.tenantName.information": "El nombre del inquilino desde el cual se recuperarán los datos",
  "orchestrator.management.formControl.orchestratorApiName.information": "El nombre del Orchestrator de UiPath",
  "orchestrator.management.formControl.apiClientId": "ID de la aplicación API",
  "orchestrator.management.formControl.apiClientSecret": "Secreto de la aplicación API",
  "orchestrator.management.formControl.solutionProvider":
    "Proveedor de soluciones",
  "orchestrator.management.formControl.orchestratorIp": "IP del orquestadore",
  "orchestrator.management.formControl.yes": "Sí",
  "orchestrator.button.update": "Actualizar orquestador",
  "orchestrator.management.formControl.no": "No",
  "orchestrator.management.formControl.orchestratorId.actionnable":
    "Accionable",
  "orchestrator.delete.discard":
    "¿Está seguro de que desea descartar estos cambios?",
  "orchestrator.management.formControl.apiPath": "Ruta API",
  "user.management.menu.title": "Usuarios",
  "orchestrator.management.save.confirmMsg":
    "¿Está seguro que quiere guardar a este orquestadore?",
  "orchestrator.management.formControl.description": "Descripción",
  "orchestrator.management.formControl.databaseServer":
    "Servidor de base de datos",
  "orchestrator.management.databaseServer": "Servidor de base de datos",
  "orchestrator.management.formControl.serverPort": "Puerto",
  "orchestrator.management.serverPort": "Puerto",
  "orchestrator.management.formControl.databaseName":
    "Nombre de la base de datos",
  "orchestrator.management.formControl.dbUsername": "Nombre de usuario",
  "orchestrator.management.formControl.dbPassword": "Clave",
  "orchestrator.management.formControl.invalidPort":
    "El número de puerto está entre 1 y 65,535",
    "orchestrator.management.formControl.organizationName": "Nombre de la organización Uipath",
    "orchestrator.management.formControl.tenantName": "Nombre del inquilino Uipath",
    "orchestrator.management.formControl.orchestratorApiName": "Nombre del orquestador Uipath",
  "orchestrator.management.testConnection": "Prueba conexión",
  "orchestrator.management.testConnection.successMessage": "Conexión exitosa",
  "orchestrator.management.testConnection.failureMessage": "Conexión fallida",
  "user.enaDes.confirmMsg": "¿Está seguro que quiere borrar el usuario?",
  apply: "APLICAR",
  reset: "REINICIAR",
  processes: "Automatización",
  ACTIVE: "Activo",
  IDLE: "Inactiva",
  PRIVATE: "Privado",
  WORKING: "Trabajando",
  OFFLINE: "Desconectada",
  Idle: "Inactiva",
  Working: "Trabajando",
  Offline: "Desconectada",
  DISABLE: "Inactivo",
  DISABLED: "Inactivo",
  Missing: "Falta",
  Client: "Cliente",
  Disabled: "Deshabilitado",
  orchestrator: "Orquestador",
  "Disregard changes": "Descartar",
  "Process status": "Estado del automatización",
  "timeUnite.millis": " ms",
  "timeUnite.sec": " seg(s)",
  "timeUnite.min": " min(s)",
  "timeUnite.hrs": " HR(s)",
  "timeUnite.days": " día(s)",
  "timeUnite.manDay": "d/h",
  "timeUnite.fte": "FTE",
  "Choose icon": "Elegir icono",
  "Edit Icon": "Modificar el icono",
  export: "Exportar",
  "user.management.add.button": "Crear usuario",
  "user.management.delete.confirmMsg":
    "¿Está seguro de que desea borrar a este usuario?",
  "user.management.deleteUsersActionMessage":
    "¿Está seguro de que desea borrar usuarios seleccionados?",
  "user.management.enaDes.confirmMsg":
    "¿Está seguro que quiere guardar a este usuario?",
  "user.management.enaDes.button.confirm": "Activar",
  "user.management.desaDes.confirmMsg":
    "¿Estás seguro que quiere desactivar este usuario?",
  "user.management.desaDes.button.confirm": "Desactivar",
  "orchestrator.management.enaDes.button.confirm": "Activar",
  "orchestrator.management.desaDes.confirmMsg":
    "¿Está seguro de que desea deshabilitar el orquestador ? Se perderá la conexión con su entorno RPA.",
  "orchestrator.management.desaDes.button.confirm": "Desactivar",
  "orchestrator.management.isAcionableToggle.confirm": "Sí, estoy seguro",
  "orchestrator.management.isNotAcionableToggle":
    "¿Está seguro que quiere que este orquestadore sea accionable?",
  "orchestrator.management.isNotAcionableToggle.confirm":
    "Hacer que se pueda actuar",
  depuis: "Desde",
  "depuis la premiere execution": "la primera ejecución encontrada",
  "kpi.parameter.be": "Incluir datos desde",
  "kpi.parameter.includeException":
    "Incluir las excepciones comerciales de artículos en KPI financieros",
  "kpi.parameter.executionsIncludeException":
    "Incluir los ejecuciones de excepción in los cálculos financieros de los KPI",
  "kpi.parameter.invalidRate": "Tasa inválida",
  "kpiParam.update.success": "Configuración actualizada correctamente",
  "kpiParam.update.error":
    "Fallo en la actualización de los parámetros del KPI",
  "kpi.parameter.includeSince.tooltip":
    "Elija cuándo SmartRoby empezará a utilizar y mostrar los datos de los automatización en los módulos y los indicadores clave de rendimiento en todo el portal.",
  "kpi.parameter.includeExceptFinancial.tooltip":
    "Incluye un porcentaje del tiempo ahorrado para cada elemento que fue procesado con una excepción comercial",
  "kpi.parameter.includeExceptFinancialKPI.tooltip":
    "Incluye un porcentaje del tiempo ahorrado para cada ejecución que se terminó con una excepción comercial",
  "kpi.parameter.breakEvenCalculationPeriod.tooltip":
    "El período de tiempo a considerar al estimar el punto de equilibrio para esta automatización",
  // ROUTES
  "routes.home": "Inicio",
  "routes.orchestrators": "orquestadores",
  "routes.orchestrators.processAssessment": "Proceso Evaluación",
  "routes.orchestrators.processAssessment.rpa": "rpa",
  "routes.orchestrators.processAssessment.rpa.view": "ver",
  "routes.orchestrators.processAssessment.rpa.create": "crear",
  "routes.orchestrators.processAssessment.rpa.edit": "editar",
  "routes.orchestrators.smartResources": "smart recursos",
  "routes.orchestrators.myProcesses": "Automatización",
  "routes.orchestrators.smartResources.documents": "documentos",
  "routes.orchestrators.smartResources.documents.details": "detalles",
  "routes.orchestrators.administration": "administración",
  "routes.orchestrators.administration.users": "usuarios",
  "routes.orchestrators.administration.users.add": "añadir",
  "routes.orchestrators.administration.users.edit": "editar",
  "routes.orchestrators.administration.users.view": "ver",
  "routes.orchestrators.protected": "protegido",
  "routes.orchestrators.protected.user": "usuario",
  "user.management.formControl.required": "Campo requerido",
  "routes.orchestrators.protected.user.profile": "perfil",
  "routes.orchestrators.administration.orchestrators": "orquestadores",
  "routes.orchestrators.administration.orchestrators.add": "añadir",
  "routes.orchestrators.administration.orchestrators.edit": "editar",
  "routes.orchestrators.administration.orchestrators.view": "ver",
  "routes.orchestrators.smartocr": "smart ocr",
  "routes.orchestrators.smartocr.identity_card": "documento de identidad",
  "routes.orchestrators.smartocr.passport": "pasaporte",
  "routes.orchestrators.smartocr.driver_licence": "permiso de conducir",
  "routes.orchestrators.smartocr.car_registration": "registro de vehículos",
  "routes.orchestrators.smartocr.green_card": "green_card",
  "routes.orchestrators.smartocr.rib": "rib",
  "routes.orchestrators.smartocr.invoice": "factura",
  "routes.orchestrators.smartocr.purchase_order": "orden de compra",
  "routes.orchestrators.smartocr.tax_notice": "aviso de impuestos",
  "routes.orchestrators.smartocr.receipt": "recibo",
  "routes.orchestrators.smartocr.edf": "edf",
  "routes.orchestrators.smartocr.payroll_statemenet":
    "declaración de la nómina",
  "routes.orchestrators.exceptions": "Excepción",
  "routes.orchestrators.process-occurrence": "Ocurrencias del automatización",
  "routes.orchestrators.exceptions.processes": "Excepciones de automatización",
  "routes.orchestrators.exceptions.items": "Excepciones de elementos",
  // ALERT MANAGEMENT ROUTES
  "routes.orchestrators.alertsManagement": "Alertas",
  "routes.orchestrators.alertsManagement.defaultAlerts":
    "Alertas predeterminadas",
  "routes.orchestrators.alertsManagement.customAlerts":
    "Alertas personalizadas",
  "routes.orchestrators.alertsManagement.defaultAlerts.add": "añadir",
  "routes.orchestrators.alertsManagement.defaultAlerts.edit": "editar",
  "routes.orchestrators.alertsManagement.defaultAlerts.view": "Ver",
  "routes.orchestrators.alertsManagement.customAlerts.add": "añadir",
  "routes.orchestrators.alertsManagement.customAlerts.edit": "editar",
  "routes.orchestrators.alertsManagement.customAlerts.view": "Ver",
  "exceptions.item.tab.title": "Excepciones",
  "exceptions.processes.tab.title": "Excepción de automatización",
  "routes.orchestrators.process-occurence": "ocurrencia del automatización",
  "routes.orchestrators.profile": "perfil",
  "routes.orchestrators.administration.fleets": "clientes",
  "routes.orchestrators.administration.fleets.add": "añadir",
  "routes.orchestrators.administration.fleets.edit": "editar",
  "routes.orchestrators.administration.fleets.view": "ver",
  "save draft": "GUARDAR BORRADOR",
  "user.title.editHeader": "Cambiar de usuario",
  "fleet.management.formControl.phone": "No se acepta el formato de número de teléfono",
  "user.management.formControl.phone": "Formato de teléfono incorrecto",
  "fleet.management.status": "Estado",
  "fleet.management.formControl.invalidDate": "Formato de fecha no válido",
  "fleet.management.companyName": "Nombre del grupo",
  "fleet.management.email": "Correo electrónico",
  "fleet.management.clientSince": "Grupo creado desde",
  "fleet.management.menu.title": "bandas",
  groups: "Bandas",
  "fleet.management.add.button": "Crear banda",
  "fleet.management.delete.confirmMsg":
    "¿Está seguro que quiere borrar el orquestadore?",
  "fleet.management.deleteClientsActionMessage":
    "¿Está seguro de que desea borrar clientes seleccionados?",
  "fleet.management.enaDes.confirmMsg":
    "¿Seguro que quieres activar el cliente?",
  "fleet.management.enaDes.button.confirm": "activar",
  "fleet.management.desaDes.confirmMsg":
    "¿Está seguro que quiere desactivar orquestadore?",
  "fleet.management.desaDes.button.confirm": "desactivar",
  "fleet.management.addHeader": "añadir cliente",
  "fleet.management.editHeader": "editar cliente",
  "fleet.management.displayHeader": "mostrar al cliente",
  "fleet.management.formControl.required": "Campo obligatorio",
  "fleet.management.formLabel.companyName": "Nombre del grupo",
  "fleet.management.formControl.email":
    "formato de correo electrónico incorrecto",
  "fleet.management.formControl.siret.notValid": "SIRET no válido",
  "fleet.management.formControl.siren.notValid": "SIREN no válido",
  "fleet.management.formLabel.email": "Correo electrónico",
  "fleet.management.formLabel.phoneNumber": "Número de teléfono",
  "fleet.management.formLabel.vatNumber": "Número de IVA",
  "fleet.management.formControl.vatNumber.notValid": "El valor debe ser un número",
  "fleet.management.formLabel.address": "Dirección",
  "fleet.management.formLabel.subscription": "Suscripción",
  "fleet.management.formLabel.clientSince": "Cliente desde",
  "fleet.button.update": "Actualización",
  "fleet.button.save": "Añadir",
  "fleet.button.cancel": "Cancelar",
  "user.permission.save": "ahorrar",
  "user.add.template": "Plantilla",
  "user.permissions.template.createBtn": "Crear plantilla",
  "user.permission.confirmSave":
    "¿Está seguro de que desea actualizar los permisos de los usuarios?",
  "user.permission.confirmDelete":
    "¿Estás seguro de que quieres borrar este modelo?",
  "user.permission.saveAsTemplace": "Guardar y crear plantilla",
  "user.permission.saveAsTemplaceTitle": "Nueva plantilla",
  "user.permission.templateCreate.success": "Modelo creado con éxito",
  "user.permission.templateDelete.success": "Modelo eliminada con éxito",
  "user.permission.templateCreate.error": "El nombre de la plantilla ya existe",
  "permission.action.applyTemplate": "Aplicar plantilla",
  "user.permissions.template.name": "Nombre de la plantilla",
  "user.permissions.default": "Culpa",
  "user.add.permissions": "Permisos",
  "fleet.update.confirmMsg":
    "¿Está seguro que quiere actualizar la información del grupo?",
  "fleet.save.confirmMsg": "¿Está seguro que quiere añadir un grupo?",
  "fleet.delete.discard": "¿Está seguro que quiere descartar los cambios?",
  "fleet.button.discard": "Descartar",
  "fleet.formControl.updateSuccess":
    "Información del cliente actualizada con éxito",
  "fleet.formControl.updateError":
    "Error al actualizar la información del cliente",
  "fleet.formControl.saveError": "Error al añadir un nuevo cliente",
  "orchestrator.formControl.updateSuccess":
    "Información de Orquestadore actualizada con éxito",
  "user.management.formLabel.fleet": "Banda",
  "processesSelector.availableProcess": "Automatización disponibles",
  "processesSelector.assignedProcess": "Automatización asignados",
  "processTagSelector.assignedProcessTags": "Automatización y etiquetas asignados",
  "tagSelector.availableTags": "Etiquetas disponibles",
  "user.formControl.saveError": "Error al añadir un nuevo usuario",
  "user.formControl.updateError":
    "Error al actualizar la información del usuario",
  "user.formControl.updateSuccess":
    "Información del usuario actualizada con éxito",
  "user.formControl.saveSuccess": "Nuevo usuario añadido con éxito",
  "fleet.formControl.saveSuccess": "Nuevo cliente añadido con éxito",
  "orchestrator.formControl.saveSuccess":
    "Nuevo orquestadore añadido con éxito",
  "user.permissions.title": "Permisos de usuario",
  "fleet.formControl.unassignProcessError":
    "El automatización ya está asignado a {{users}} usuarios",
  "fleet.management.delete.warning":
    "Al eliminar un cliente, se borrarán automáticamente todos sus usuarios y se desasignarán sus automatización.",
  "user.administration.fleet": "Banda",
  "user.administration.lastLogin": "Última conexión",
  "tooltip.action.enable": "Activar",
  "tooltip.action.disable": "Desactivar",
  "tooltip.action.permissions": "Permisos",
  "fleet.formSection.fleetInfo": "Información general",
  "fleet.formSection.clientParams": "Parámetros del cliente",
  "fleet.formSection.subscriptionInfo": "Información de suscripción",
  "fleet.formSection.clientParameters": "Configuración del banda",
  "fleet.management.formLabel.rate": "Calificar",
  "fleet.management.formLabel.overConsumptionRate": "Tasa de consumo excesivo",
  "fleet.management.formLabel.subscriptionPeriod": "Período de suscripción",
  "fleet.management.formLabel.paymentTerm": "Plazo de pago",
  "fleet.formSection.processes": "Automatización",
  "fleet.formSection.processesTags": "Automatización y etiquetas",
  "fleet.formSection.summary": "Resumen de la suscripción activa",
  "fleet.management.formLabel.rateUnit": "SAR/hora",
  "fleet.management.formLabel.subscriptionPeriodUnit": "meses",
  "fleet.management.formLabel.siret": "Siret",
  "fleet.management.formLabel.siren": "Siren",
  "fleet.management.formLabel.manDayValue": "1 Día de hombre (dh)",
  "fleet.management.formLabel.fullTime": "1 Empleado a tiempo completo (ETC)",
  "fleet.management.formLabel.bePeriod": "Período de cálculo de equilibrio",
  "fleet.management.formLabel.recommendedOccupancy": "Ocupación recomendada",
  "fleet.management.be.period.1": "El mes Último",
  "fleet.management.be.period.2": "Últimos 3 meses",
  "fleet.management.be.period.3": "Últimos 6 meses",
  "fleet.management.be.period.4": "El año pasado",
  "fleet.add.previous": "Anterior",
  "process.filter.fleet": "Cliente",
  "process.enabled": "Desplegada",
  "process.filter.showAll": "Mostrar ({{count}}) automatización ocultos",
  Unremarkable: "< 5 Años",
  "fleet.management.formControl.number": "El formato del número de IVA no es compatible",
  "fleet.management.formControl.occupancyNumber": "La ocupación recomendada tiene que ser un número",
  "fleet.management.formControl.occupancyHigherThan": "La ocupación recomendada no puede ser superior a 24",
  "fleet.management.formControl.occupancyLowerThan": "La ocupación recomendada no puede ser inferior a 0",
  "fleet.management.hoursPerDay": "Horas al día",
 "reporting.copyToClipboard": "Copiar al portapapeles",
  "reporting.process": "Proceso",
  "reporting.kpi": "KPI",
  "reporting.reportType": "Tipo de reporte",
  "reporting.date": "Fecha",
  "reporting.table": "Mesa",
  "reporting.lineChart": "Gráfico en línea",
  "reporting.pieChart": "Gráfico circular",
  "reporting.barChar": "Gráfico de barras",
  "reporting.columnChart": "Gráfico de columnas",
  "reporting.totalExecution": "Ejecuciones totales",
  "reporting.totalException": "Excepciones totales",
  "reporting.timeSaved": "Tiempo ahorrado",
  "reporting.executionTime": "Tiempo de ejecución",
  "reporting.itemExecutionTime": "Tiempo de ejecución del artículo",
  "reporting.breakEven": "Rentabilidad",
  "reporting.roi": "Retorno de inversión",
  "reporting.roiPercentage": "ROI",
  "reporting.occupancyRate": "Tasa de ocupación",
  "reporting.executedProcesses": "Automatización ejecutados",
  "reporting.save": "Exportar como imagen (PNG)",
  "reporting.copyClipboard": "Copia en el Portapapeles",
  "reporting.exportPDF": "Exportar como PDF",
  "reporting.clear": "Limpiar",
  "reporting.from": "De",
  "reporting.to": "Para",
  "reporting.exceptionCount": "Cantidad de excepciones",
  "reporting.reportTitle": "entre {{from}} y {{to}}",
  "reporting.and": " y",
  "reporting.presetDeletionSuccess": "El preset se eliminó correctamente",
  "reporting.presetDeletionError": "No se pudo eliminar el preset",
  "reporting.presetCreationSuccess": "El preset se crea correctamente",
  "reporting.presetCreationError": "No se pudo crear el preset",
  "reporting.customKpiCreationOrUpdateSuccess": "Se guardó correctamente el KPI personalizado",
  "reporting.customKpiCreationOrUpdateError": "No se pudo guardar el KPI personalizado",
  "reporting.customKpiDeletionSuccess": "Se eliminó correctamente el KPI personalizado",
  "reporting.customKpiDeletionError": "No se pudo eliminar el KPI personalizado",
  for: "para",
  "Results for": "Resultados para",
  "reporting.cumul": "Resultado acumulativo",
  "Process launcher": "Iniciador de automatización",
  "Execution detail": "Detalle de ejecución",
  "Business Exception": "Excepción del negocio",
  "System Exception": "Excepción del sistema",
  Unspecified: "No especificada",
  Internal: "Interna",
  "Process.list.names.by.orchestrator": "Automatización",
  "Resource.list.names.by.orchestrator": "Recursos",
  "fleet.management.formLabel.effectiveStartDate": "Fecha de inicio efectiva",
  "rpaQual.transition.delete": "Borrar",
  "rpaQual.transition.deleteMsg":
    "¿Estás seguro de que quieres borrar esta oportunidad?",
  "rpaQual.button.delete":
    "¿Está seguro de que desea borrar esta calificación?",
  "rpaQual.transition.reconsider": "Reconsiderar",
  "rpaQual.transition.reconsiderMsg":
    "¿Estás seguro de que quieres reconsiderar este caso?",
  "rpaQual.transition.archive": "Archivo",
  "rpaQual.transition.archiveMsg":
    "¿Estás seguro de que quieres archivar este asunto?",
  "rpaQual.process.archived.success": "Estuche archivado",
  "rpaQual.process.roadMap.success": "Añadido a la hoja de ruta",
  "rpaQual.process.reconsider.success": "Oportunidad reconsiderada",
  "rpaQual.transition.addToRoadMap": "Añadir a la hoja de ruta",
  "rpaQual.transition.addToRoadMapMsg":
    "¿Está seguro de que desea agregar este caso a su hoja de ruta?",
  "rpaQual.filter.show": "Incluir archivada",
  "rpaQual.filter.hide": "Excluir archivados",
  "rpaQual.display.nonArchived": "No archivado",
  "rpaQual.display.archived": "Archivada",
  "rpaQual.display.all": "Todos",
  "breadcrumbs.label.details": "Entradas",
  "rpaQual.opportunity.list.empty": "La lista de oportunidades está vacía !",
  "rpaQual.case.list.empty": "",
  "rpaQual.status.submitted": "Pendiente",
  "rpaQual.status.reconsider": "Pendiente",
  "rpaQual.status.accepted": "Aceptada",
  "rpaQual.status.draft": "Borrador",
  "rpaQual.status.not submitted": "No validado",
  "rpaQual.status.rejected": "Rechazada",
  "rpaQual.status.roadMap": "RoadMap",

  "filter.graph": "Perspectivas",
  "filter.export": "Exportar",

  // Analytics - help
  "help.process": "Elija los automatización afectados por el análisis",
  "help.tags": "Por favor, elija el(los) tag(s) relacionado(s) con la analítica",
  "help.kpi": "Elija los KPI (s)",
  "help.reportType":
    "La lista mostrada de tipo de informe se basa en los KPI elegidos",

  "Select All": "Seleccionar todo",
  // permissions

  "User Administration": "Usuarios",
  "Group Administration": "Administración de bandas",
  "Tag Administration": "Administración de etiquetas",
  "Client Administration": "Administración de clientes",
  "Orchestrator Administration": "Administración de orquestadores",
  "Start process": "Iniciar automatización",
  "Schedule process": "Programar automatización",
  "KPI parameters": "Configuración",
  "Edit process details": "Editar detalles del automatización",
  "Enable/Disable process": "Mostrar/ocultar automatización",
  "Item exceptions": "Exceptions des items",
  "Delete item exceptions": "Ocultar excepciones de automatización",
  "Retry item exceptions with changed data": "Reintentar excepciones de elementos con datos modificados",
  "Retry item exceptions": "Reintentar las excepciones de los elementos",
  Workflow: "Flujo de trabajo",
  "Process execution exceptions": "Excepciones de ejecución",
  "Delete process execution exceptions":
    "Eliminar excepciones de ejecución",
  "View process exceptions": "Ver excepciones de automatización",
  "Delete process exceptions": "Ocultar excepciones de automatización",
  "View item exceptions": "Ver excepciones de elementos",
  "Add opportunity": "Agregar oportunidad",
  "Accept / Reject / Reconsider": "Aceptar / Rechazar / Reconsiderar",
  "Archive / Backlog": "Archivo / Backlog",
  "Delete opportunity": "Eliminar oportunidad",
  "Add fleet": "Agregar cliente",
  "Manage fleet": "Gestionar cliente",
  "Delete fleet": "Eliminar cliente",
  "Add user": "Agregar usuario",
  "Edit user": "Editar usuarios",
  "Delete user": "Borrar usuario",
  "User Permissions": "Permisos de usuario",
  "Add Orchestrator": "Agregar del orquestador",
  "Edit Orchestrator": "Editar orquestadores",
  "Delete Orchestrators": "Eliminar del orquestador",

  "process-occurence.cutsomdate": "Fecha personalizada",
  "exception.analytics.by.process": "Excepciones por automatización",
  "exception.analytics.by.type": "Resumen de tipos de excepción",
  "exception.analytics.by.reason": "Razones principales de la excepción",
  "exception.analytics.by.trend": "Tendencia de las excepciones",

  "Execution Time (Descending)": "Duración de la ejecución (descendente)",
  "Execution Time (Ascending)": "Duración de la ejecución (ascendente)",

  "no.process.message": "No se detectaron automatización",
  "no.queue.message": "No se detectaron colas",
  "no.completedItems.message": "No se han detectado elementos completados",
  "no.pendingItems.message": "No hay elementos pendientes detectados",
  "no.execution.message": "No se encontró ejecución",
  "no.scheduler.message": "No se ha encontrado ningún programa",
  "no.type.message": "No se encontró ningún tipo",

  "no.orchestrator.message": "No se encontró ningún orquestadors",
  "no.resource.message": "No se encontró ningún recurso",
  "reporting.roiShort": "REY",
  "no.fleets.message": "No se encontraron bandas",
  "no.users.message": "No se encontraron usuarios",
  "no.resources.message": "No se encontraron recursos",
  "no.items.exception.message": "No se encontraron excepciones de artículos",
  "no.process.exception.message": "No se encontró ninguna excepción de automatización",
  "no.analytics.message": "Ningún automatización detectado",
  "no.planned.execution.message":
    "No se han encontrado ejecuciones planificadas",
  "no.activities.message": "No se encontró actividad",
  "no.logs.message": "No se encontró ningún registro",

  // ALERT MANAGEMENT
  "alerts.default.tab.title": "Alertas predeterminadas ",
  "alerts.custom.tab.title": "Alertas personalizadas  ",
  // DEFAULT ALERTS TABLE HAEDER CELLS
  "alert.status": "Estado",
  "alert.alertName": "Nombre",
  "default.alert.event": "Evento",
  "alert.type": "Tipo de alerta",
  "report.type": "Tipo de informe",
  "no.alerts.message": "¡No se encontraron alertas !",
  "alerts.default.addButton": "Crear alerta",

  // CUSTOM ALERTS TABLE HAEDER CELLS
  "custom.alert.KPI": "KPI",
  "custom.alert.process": "Proceso",
  "alerts.custom.addButton": "Add Alert",
  "alert.management.delete.confirmMsg":
    "Are you sure you want to delete this alert?",

  // Add & Edit headers alert
  "alerts.addHeader": "Nueva alerta",
  "alerts.editHeader": "Editar alerta",
  "alerts.displayHeader": "Detalles de la alerta",

  // error messages
  "alerts.management.required.fields": "Campo requerido",

  // confirmation buttons
  "alerts.management.button.update": "Actualización",
  "alerts.management.button.add": "Agregar",
  "alerts.management.button.cancel": "Cancelar",
  "alerts.management.button.save": "Añadir",
  "alerts.management.button.exit": "salida",
  "alerts.management.button.discard": "Descartar",

  "alerts.management.trigger": "Generar",
  "alerts.management.triggers": "Disparadores",
  "alerts.management.general": "General",
  "alerts.management.checking.frequence": "Frecuencia de verificación",
  "alerts.management.advanced.settings.alert.text": "SmartRoby comprobará todos {{days}} a partir de {{date}} a las {{time}}",
  "week day": "los días",
  "alerts.management.advanced.settings.btn": "Configuración avanzada",
  "alerts.management.simple.settings.btn": "Configuración sencilla",
  "alerts.management.advanced.settings.days.label": "Durante los días siguientes",
  "alerts.management.advanced.settings.time.label": "Durante los siguientes plazos",
  "alerts.management.advanced.settings.all.days.label": "Todos los días",
  "alerts.management.advanced.settings.profile.time.zone": "Perfil Zona horaria",
  "alerts.management.notifications": "Notificaciones",
  "alerts.management.subscribers": "Suscriptores",
  "alerts.management.name.placeholder": "Ingrese el nombre de la alerta",
  "alerts.management.name.label": "Nombre de alerta *",
  "alerts.management.condition.label": "Valor *",
  "alerts.management.alertDescription.placeholder":
    "Ingrese la descripción de la alerta",
  "alerts.management.alertDescription.label": "Descripción",
  "alerts.management.when.placeholder": "Cuando *",
  "alerts.management.operator.placeholder": "Es *",
  "alerts.management.process.placeholder": "De *",
  "alerts.management.of.placeholder": "De *",

  "alerts.management.email.invalidEmail":
    "Formato de dirección de correo electrónico no válido",
  "alerts.management.email.invalidEmails":
    "Formato de direcciones de correo electrónico no válido",
  "alerts.management.email.invalidPhoneNumber":
    "Formato de número de teléfono no válido",
  "alerts.management.email.invalidPhoneNumbers":
    "Formato de números de teléfono no válido",
  "alerts.management.severity": "Tipo de alerta *",
  "alerts.management.save.confirmMsg":
    "¿Estás seguro de que quieres guardar esta alerta?",
  "alerts.management.update.confirmMsg":
    "¿Está seguro de que desea actualizar la información de alerta?",
  "alerts.management.delete.discard":
    "¿Estás seguro de que quieres descartar tus cambios?",
  "Alert Frequency": "Frecuencia",
  "alert.management.formControl.saveSuccess": "Nueva alerta agregada con éxito",
  "alert.management.formControl.updateSuccess":
    "Información de alerta actualizada con éxito",
  "alert.management.formControl.delete":
    "La notificación ha sido eliminada",
  "alert.management.enable.email.notification":
    "¿Está seguro de que desea habilitar las notificaciones por correo electrónico para esta alerta?",
  "alert.management.disable.email.notification":
    "¿Está seguro de que desea deshabilitar las notificaciones por correo electrónico para esta alerta?",
  "alert.management.enable.sms.notification":
    "¿Está seguro de que desea habilitar las notificaciones por sms para esta alerta?",
  "alert.management.disable.sms.notification":
    "¿Estás seguro que quieres deshabilitar las notificaciones por sms para esta alerta?",
  "alert.management.subscription.status.success": "Estado de la suscripción actualizado",
  "alert.management.subscription.status.error": "Error al actualizar el estado de la suscripción",
  "New opportunity created": "Oportunidad creada",
  "Opportunity accepted": "Oportunidad aceptada",
  "Opportunity rejected": "Oportunidad rechazada",
  "Opportunity added to the road map": "Oportunidad añadida a la hoja de ruta",
  "Opportunity archived": "Oportunidad archivada",
  "Process activated": "Proceso activado",
  "Process disabled": "Proceso deshabilitado",
  "Orchestrator enabled": "Orquestador habilitado",
  "Orchestrator disabled": "Orquestador deshabilitado",
  "Orchestrator actionable": "Orquestador procesable",
  "Orchestrator not actionable": "Orquestador no procesable",
  "Schedule added": "Horario añadido",
  "Total Executions": "Total de ejecuciones",
  "Success Rate": "Tasa de éxito",
  "Items success rate": "Items success rate",
  "Exceptions Rate": "Tarifa de excepciones",
  "Items exception rate": "Tasa de éxito de los artículos",
  "Time Saved": "Tiempo ahorrado",
  "Total time saved": "Tiempo total ahorrado",
  "Break-Even": "Balance de gastos",
  "Number of items completed with exceptions":
    "Número de elementos completados con excepciones",
  "Number of completed items": "Número de elementos completados",
  "Number of process executions with exceptions":
    "Número de ejecuciones con excepciones",
  "Number of process executions completed successfully":
    "Número de ejecuciones completadas con éxito",
  "create.scheduler": "Crear programador",
  "Edit Scheduler": "Editar programa",
  "First day of month": "primer día del mes",
  "Last day of month": "último día del mes",
  "Day number": "Número de día",
  "alert.management.email.helper":
    "Puede validar la adición de su correo electrónico haciendo clic en Entrar o Espacio.",
  "alert.management.sms.helper":
    "Puede validar la adición de su número de teléfono haciendo clic en Entrar o Espacio.",
  "alert.management.trigger.helper":
    "En caso de múltiples disparadores, todos los disparadores deben ser verdaderos para que se envíe la notificación.",
  "alerts.management.trigger.exceptionReasons.not.found": "No se encontraron motivos de excepción existentes para el automatización y el tipo de excepción seleccionados",
  Active: "Activo",
  info: "Información",
  error: "Error",
  EQUAL: "IGUAL",
  TRUE: "CIERTO",
  FALSE: "FALSO",
  "GREATER THAN": "Mayor que",
  "LESS THAN": "Menos que",
  "GREATER OR EQUAL TO": "Mayor o igual a",
  "LESS OR EQUAL TO": "Menor o igual a",
  "alerts.management.alertName.maxLength.error":
    "Este campo no debe superar los 55 caracteres.",
  "alerts.management.alertDescription.maxLength.error":
    "Este campo no debe superar los 65535 caracteres.",
  "select.all": "Todo seleccionar",
  Clear: "Limpiar",
  "add.client.form.vat.number.email.company.name.already.exist":
    "Ya existe un cliente con la misma dirección de correo electrónico, número de IVA y nombre de la empresa",
  "add.client.form.vat.number.email.already.exist":
    "Ya existe un cliente con la misma dirección de correo electrónico y el mismo número de IVA",
  "add.client.form.vat.number.company.name.already.exist":
    "Ya existe un cliente con el mismo número de IVA y razón social",
  "add.client.form.email.company.name.already.exist":
    "Ya existe un cliente con la misma dirección de correo electrónico y nombre de empresa",
  "add.client.form.email.already.exist":
    "Ya existe un cliente con la misma dirección de correo electrónico",
  "add.client.form.vat.number.already.exist":
    "Ya existe un cliente con el mismo número de IVA",
  "add.client.form.company.name.already.exist":
    "Ya existe un cliente con el mismo nombre de empresa",

  "add.client.form.vat.number.email.company.name.siren.already.exist":
    "Ya existe un cliente con la misma dirección de correo electrónico, número de IVA, nombre de empresa y SIREN",
  "add.client.form.vat.number.email.siren.already.exist":
    "Ya existe un cliente con la misma dirección de correo electrónico, número de IVA y SIREN",
  "add.client.form.vat.number.company.name.siren.already.exist":
    "Ya existe un cliente con el mismo número de IVA, nombre de empresa y SIREN",
  "add.client.form.vat.number.siren.already.exist":
    "Ya existe un cliente con el mismo número de IVA y la misma SIREN",
  "add.client.form.email.company.name.siren.already.exist":
    "Ya existe un cliente con la misma dirección de correo electrónico, nombre de empresa y SIREN",
  "add.client.form.email.siren.already.exist":
    "Ya existe un cliente con la misma dirección de correo electrónico y la misma SIREN",
  "add.client.form.company.name.siren.already.exist":
    "Ya existe un cliente con el mismo nombre de empresa y la misma SIREN",
  "add.client.form.siren.already.exist":
    "Ya existe un cliente con la misma SIREN",

  // Recurso Management
  "resource.management.name": "Nombre",
  "resource.management.formControl.actionsRunning": "Acciones en ejecución",
  "resource.management.formControl.processesRunning": "Automatización en ejecución",
  "resource.management.formControl.resourceName": "Nombre del recurso",
  "resource.management.formControl.resourceOriginalName": "Nombre original del recurso",
  "resource.management.formControl.statusDescription": "Descripción del estado",
  "resource.management.formControl.resourceId": "Identificación del recurso",
  "resource.management.formControl.unitsAllocated": "Unidades asignadas",
  "resource.management.solutionProvider": "Proveedor de soluciones",
  "resource.management.displayStatus": "Estato",
  "resource.management.orchestratorName": "Orquestador",
  "resource.management.attributeName": "Atributo  de recurso",
  "resource.management.processesRunning": "Automatización en ejecución",
  "resource.management.resourceId": "Identificación del recurso",
  "resource.management.resourceName": "Nombre del recurso",
  "Routes.orchestrators.administration.resources": "Recursos",
  "resource.management.displayHeader": "Detalles del recurso",
  "resource.management.enable.confirmMsg":
    "¿Está seguro que quiere guardar a este recurso ?",
  "resource.management.disable.confirmMsg":
    "¿Está seguro que quiere guardar a este recurso ?",
  "resource.management.disable.button.confirm": "Desactivar",
  "resource.management.enable.button.confirm": "Activar",
  "resource.button.update": "Actualizar",
  "resource.button.cancel": "Cancelar",
  "resource.button.discard": "Descartados",
  "resource.update.confirmMsg":
    "¿Está seguro de que desea actualizar los detalles de este recurso ?",
  "resource.update.discard": "¿Está seguro que desea eliminar este recurso ?",
  "resource.modify": "Modificar",
  "routes.orchestrators.administration.resources.view": "ver",
  "routes.orchestrators.administration.resources.edit": "editar",
  "routes.orchestrators.administration.resources": "Recursos",
  "administration.detail.entity.processes.title": "Automatización",
  Quantification: "Cuantificación",
  "Export as a Pdf": "Exportar como PDF",
  "start.process.no.resource.assigned": "No hay recursos asignados o los recursos asignados están fuera de línea",
  "no.process.planned": "Sin automatización planificados",
  "kpi.header.breakEven.tooltip":
    "El punto de amortización se estima sobre la base de datos históricos. Responde a la pregunta ¿cuándo se adquiere en su totalidad la inversión en los automatización? Los resultados se basan en la configuración financiera configurada para cada automatización y el filtro de tiempo del tablero.",
  "kpi.header.roi.tooltip": "Responde a la pregunta ¿cuánto de la inversión inicial ya se ha ganado? Los resultados se calculan en función de los parámetros financieros configurados para cada automatización y el filtro de período del tablero.",
  "kpi.header.breakEven.tooltip.nodata":
    "Esperando más ejecuciones para estimar el punto de equilibrio.",
  "kpi.header.roi.tooltip.nodata":
    "A la espera de más ejecuciones para calcular el ratio de rentabilidad.",
  "kpi.header.success.rate.tooltip": "La tasa de éxito se calcula para los elementos. Los resultados se basan en la configuración configurada para cada automatización y el filtro de período de tiempo del tablero.",
  "kpi.header.exception.rate.tooltip": "La tasa de excepción se calcula para los elementos. Los resultados se basan en la configuración configurada para cada automatización y el filtro de período de tiempo del tablero.",
  "dashboard.graph.treatedItems": "Artículos completos",
  "dashboard.graph.pendingItems": "Items pendientes",
  "dashboard.graph.exceptionItems": " Artículos de excepción",
  "dashboard.graph.totalItems": "Items totales",
  "Submitting...": "Sumisión...",
  "NOT VALIDATED": "NO VALIDADA",
  "tooltip.action.hide": "Ocultar",
  "tooltip.action.show": "Mostrar",
  "resource.management.deleteResourceActionMessage": "Los elementos seleccionados tendrán su visibilidad cambiada a oculta o visible.",
  "tooltip.action.showHiddenItems": "Mostrar oculta",
  "exception.analytics.typeNotFound": "Tipo de excepción no especificado",
  "orchestrator.management.formControl.sessionNumber":
    "Número de sesiones permitidas",
  "profile.user.general.title": "Geinformación Neral ",
  "profile.user.general.security": "Seguridad",
  "rpa.export.rpa": "RPA",
  "rpa.export.qualification": "CALIFICACIÓN",
  "rpa.create.businessApplication": "Enumera las aplicaciones aquí",
  "add.schedule.same.date.constraint": "Ya existe una programación con la misma fecha de inicio",
  "reporting.date.errorFuture": "El valor debe ser mayor que {{dateValue}}",
  "reporting.date.errorPast": "El valor debe ser menor que {{dateValue}}",
  "user.add.admin": "Usuario administrativo",
  "Items status by process": "Estado de los items por automatización",
  "Average Handling Time": "Tiempo promedio de manejo",
  "Current Period": "Período actual",
  "Last Period": "Último periodo",
  "use intervals": "Utilizar intervalos",
  "use cumulative": "Uso acumulativo",
  "sla.update.success": "Información de SLA actualizada con éxito",
  "please enter at least one email to enable email notification":
    "Por favor, introduzca al menos un correo electrónico para activar la notificación por correo electrónico.",
  "please enter at least one phone number to activate the sms notification":
    "Introduzca al menos un número de teléfono para activar la notificación por sms.",
   "sla.update.error": "Error al actualizar los SLA.",
  Exception: "Excepción",
  Success: "Éxito",
  "The calculation of the KPIs will be based on an interval":
    "El cálculo de los KPIs se basará en un intervalo.",
  "Calculation of the KPIs for the whole time":
    "Cálculo de los KPI para todo el tiempo.",
  "Receive alerts from the following date":
    "Recibir alertas a partir de la siguiente fecha.",
  "Stop receiving alerts from the following date":
    "Dejar de recibir alertas a partir de la siguiente fecha.",
  "dashboard.tooltip.timesaved":
    "Este gráfico muestra la distribución del tiempo ahorrado (en horas) durante el período seleccionado en el filtro de período del tablero. Los resultados se calculan en función de la configuración financiera configurada para cada automatización y el filtro de automatización del tablero.",
  "dashboard.tooltip.processOverview":
    "Este gráfico muestra la distribución del tiempo de ejecución de cada automatización frente a todos los automatización. Los resultados se basan en el filtro de período de tiempo del tablero.",
  "dashboard.tooltip.itemsProcess":
    "Este gráfico muestra el desglose del estado de los artículos para los principales automatización. Los resultados se basan en el filtro de período de tiempo del tablero.",
  "dashboard.tooltip.executionsProcess":
    "Este gráfico muestra la distribución de las ejecuciones durante el período seleccionado en el filtro de período del tablero.",
  "dashboard.tooltip.averageTime":
    "El tiempo promedio de procesamiento (AHT) es el tiempo promedio que tomó ejecutar un artículo en este automatización durante el período seleccionado en el filtro de período.",
  STOPPED: "Detenida",
  COMPLETED: "Terminada",
  DEBUGGING: "Depuración",
  ARCHIVED: "Archivada",
  STOPPING: "Parada",
  WARNING: "Advertencia",
  "status.running.with.warning": "En ejecución con una advertencia",
  "process.history.duration": "Duración de la ejecución",
  "process.history.completedItems": "Artículos completos",
  "process.history.exceptionItems": "Artículos de excepción",
  "process.history.resource": "Recurso",
  AUTOMATIC: "Automática",
  MANUAL: "Manual",

  "Process data mapping": "Mapeo de datos",
  "Are you sure you want to update the mapping ?":
    "¿Está seguro de que desea actualizar la asignación ?",
  "no.data-mapping.message":
    "La asignación solo se puede realizar después del procesamiento del primer elemento del automatización.",
  Data: "Datos",
  "New name": "Nuevo nombre",
  Display: "Mostrar",
  "display.in.items.list": "Mostrar en la lista de elementos",
  "Orchestrator configuration": "Configuración del orquestador",
  "SmartRoby will add columns and create views and triggers on your orchestrator database":
    "SmartRoby añadirá columnas y creará vistas y desencadenantes en la base de datos del orquestador.",
  "Initialize connectors to synchronize data between your orchestrator and SmartRoby":
    "Inicialice los conectores para sincronizar los datos entre su orquestador y SmartRoby.",
  "sync.Execute": "Ejecutar",
  "sync.Initialize": "Inicializar",
  "Database configuration": "Configuración de la base de datos",
  "Orchestrator/SmartRoby synchronization":
    "Sincronización Orquestador/SmartRoby",
  "Orchestrator status": "Orchestrator status",
  "sync.Refresh": "Actualizar",
  "sync.General": "General",
  "Database connection": "Conexión a la base de datos",
  "Synchronization engine": "Motor de sincronización",
  "sync.Synchronization": "Sincronización",
  "sync.process": "Automatización",
  "sync.execution": "Ejecuciones",
  "sync.resource": "Recursos",
  "sync.schedule": "Calendarios",
  "sync.work_queue_item": "Artículos",
  "Number of items with Business Exceptions":
    "Número de artículos con Business Exceptions",
  "Number of items with System/Internal Exception":
    "Número de elementos con System/Internal Exception",
  "Number of process executions with Business Exceptions":
    "Número de ejecuciones con Business Exceptions",
  "Number of process executions with System/Internal Exception":
    "Número de ejecuciones con with System/Internal Exception",
  "sync.deleted_record": "Eliminación de registros",
  "sync.license": "Licencias",
  "sr.sync.license": "Licencias SmartRoby",
  "resource.management.hide.confirmMsg":
    "¿Estás seguro de que quieres ocultar este recurso?",
  "resource.management.show.confirmMsg":
    "¿Estás seguro de que quieres mostrar este recurso?",
  "filter.clear": "Vaciar los filtros",
  Reasons: "Razones",
  "List of conflicts for": "Lista de conflictos para ",
  allowedSessionsExceededForAnOrchestrator:
      "{{parallelExecutions}} tareas paralelas fueron encontradas mientras que el orquestador '{{OrhcestratorName}}' solo permite {{allowedSessions}} sesión(es).",
  concurrentExecutionInTheSameResourceMessage:
    "Se ha encontrado más de una ejecución en el recurso '{{resourceName}}'. Las demás ejecuciones planificadas comenzarán después de que se haya completado la primera según este orden : ",
  "Number of pending items": "Número de partidas pendientes",
  "kpi.parameter.allowScheduling": "Se puede programar",
  "kpi.parameter.allowManual": "Puede iniciarse manualmente",
  "kpi.parameter.retryable": "Las excepciones se pueden relanzar",
  "kpi.parameter.retryableWithData": "Las excepciones se pueden relanzar con datos diferentes",
  "kpiParam.reset.success": "Configuración restablecida con éxito",
  "kpiParam.reset.error": "Error al restablecer los parámetros de KPI",
  "kpiParam.schedule.warning":
    "Este automatización ya tiene horarios. Si procede, se borrarán todas.",
  "Warnings Management": "Gestión de advertencias",
  "processStore.year": "Año",
  "processStore.years": "Años",
  "can.be.edited.for.retry": "Puede ser editado para un reintento",
  retry: "Reintentar",
  "retry-dialog.title.one-item": "¿Está seguro de que quiere reintentar esta excepción?",
  "retry-dialog.title.multiple-items": "¿Está seguro de que quiere reintentar {{count}} excepciones?",
  "retry-dialog.text.one-item": "El elemento se reintentará la próxima vez que se ejecute el automatización.",
  "retry-dialog.text.multiple-items": "Los elementos se reintentarán la próxima vez que se ejecuten sus automatización.",
  "retry-dialog.pending-alert": "Ya existe un reintento pendiente para este elemento",
  "retry-dialog.pending-alert-multiple": "Ya existen reintentos pendientes para estos elementos",
  "retry-dialog.message.warning.pending": " (Los elementos que ya tienen un reintento pendiente no se someterán a otro reintento)",
  "retry-dialog.not-retryable": "Los elementos seleccionados pertenecen a un automatización que no se puede volver a intentar",
  "retry-dialog.radio.same-data": "Reintentar con los mismos datos",
  "retry-dialog.radio.changed-data": "Reintento con datos modificados",
  "retry-dialog.table.name": "Nombre",
  "retry-dialog.table.old-value": "Valor antiguo",
  "retry-dialog.table.new-value": "Nuevo valor",
  "retry-dialog.message.success.one-item": "El elemento de reintento se crea con éxito",
  "retry-dialog.message.success.multiple-items": "Se crean {{count}} elementos de reintento con éxito",
  "retry-dialog.message.error.one-item": "No se pudo crear el elemento de reintento",
  "retry-dialog.message.error.multiple-items": "No se han creado los elementos de reintento",
  "restriction.form.hour.maxValue.error": "El valor debe ser menor o igual a 23.",
  "restriction.form.min.sec.maxValue.error": "El valor debe ser menor o igual a 59.",
  attempt: "Intento",
  "Time period": "Período",

  "reporting.processExecutions": "Ejecuciones",
  "reporting.itemsProcessed": "Artículos tratados",
  "reporting.itemsCompleted": "Artículos completados",
  "reporting.successRate": "Tasa de éxito del artículo",
  "reporting.exceptionRate": "Tasa de excepción de artículo",
  "reporting.itemsBusinessExceptions": "Artículos de excepción trabajo",
  "reporting.itemsSystemExceptions": "Artículos de excepción del sistema",

  "attempt.chip.tooltip": "Este es el {{attempt}} intento de este elemento",
  "exception-workflow.status.ready": "No manejado",
  "exception-workflow.status.taken": "Asignado ",
  "exception-workflow.status.done": "Manejado",
  "exception-workflow.action.take": "Tome",
  "exception-workflow.action.assign": "Asignar",
  "exception-workflow.action.release": "Liberar",
  "exception-workflow.action.reopen": "Reabrir",
  "exception-workflow.action.markAsDone": "Marcar como manejado",
  "exception-workflow.log.take": "Tomado por <b>{{user.firstname}} {{user.lastname}}</b>",
  "exception-workflow.log.assign": "Asignado a <b>{{targetUser.firstname}} {{targetUser.lastname}}</b> por <b>{{user.firstname}} {{user.lastname}}</b>",
  "exception-workflow.log.markAsDone": "Terminado por <b>{{user.firstname}} {{user.lastname}}</b>",
  "exception-workflow.log.release": "Liberado por <b>{{user.firstname}} {{user.lastname}}</b>",
  "exception-workflow.log.reopen": "Reabierto por <b>{{user.firstname}} {{user.lastname}}</b>",
  "exception-workflow.snackbar.success": "La acción se ha realizado con éxito",
  "exception-workflow.snackbar.error:": "No se ha podido realizar la acción",
  "exception-workflow.confirm.take": "¿Estás seguro de que quieres hacer esta excepción?",
  "exception-workflow.confirm.assign": "¿Estás seguro de que quieres asignar esta excepción a {{selectedUser.firstName}} {{selectedUser.lastName}}?",
  "exception-workflow.confirm.markAsDone": "¿Está seguro de que quiere marcar esta excepción como hecha?",
  "exception-workflow.confirm.release": "¿Está seguro de que quiere liberar esta excepción?",
  "exception-workflow.confirm.reopen": "¿Está seguro de que quiere reabrir esta excepción?",
  "exception-workflow.open": "flujo de trabajo",
  "exception-workflow.open-bulk": "flujo de trabajo",
  "exception-workflow.no-bulk-action-possible": "No es posible realizar ninguna acción para la combinación de excepción seleccionada",
  "exception-workflow.selected-message": "Se seleccionan {{count}} excepciones",
  "exception-workflow.confirm.bulk-take": "¿Estás seguro de que quieres tomar {{count}} excepciones?",
  "exception-workflow.confirm.bulk-assign": "¿Estás seguro de que quieres asignar {{count}} excepciones a {{selectedUser.firstName}} {{selectedUser.lastName}}?",
  "exception-workflow.confirm.bulk-markAsDone": "¿Estás seguro de que quieres marcar {{count}} excepciones como hechas?",
  "exception-workflow.confirm.bulk-release": "¿Estás seguro de que quieres lanzar {{count}} excepciones?",
  "exception-workflow.confirm.bulk-reopen": "¿Estás seguro de que quieres reabrir {{count}} excepciones?",
  "exception-workflow.choose-user": "Elegir un usuario",
  "exception-workflow.snackbar.success.take":
    "La excepción fue tomada con éxito",
  "exception-workflow.snackbar.success.assign":
    "La excepción se asignó correctamente",
  "exception-workflow.snackbar.success.assign.notify":
    "El cesionario ha sido notificado",
  "exception-workflow.snackbar.success.release":
    "La excepción se liberó con éxito",
  "exception-workflow.snackbar.success.markAsDone":
    "La excepción se terminó con éxito",
  "exception-workflow.snackbar.success.reopen":
    "La excepción se reabrió con éxito",
  "exception-workflow.snackbar.success.bulkTake":
    "Las excepciones se tomaron con éxito",
  "exception-workflow.snackbar.success.bulkAssign":
    "Las excepciones fueron asignadas con éxito",
  "exception-workflow.snackbar.success.bulkRelease":
    "Las excepciones se liberaron con éxito",
  "exception-workflow.snackbar.success.bulkMarkAsDone":
    "Las excepciones se terminaron con éxito",
  "exception-workflow.snackbar.success.bulkReopen":
    "Las excepciones fueron reabiertas con éxito",
  Details: "Detalles",
  "processe.schedules.onDay.first":
    " - El día {{startDay}}",
  "processe.schedules.onDay.second":
    " - El día {{startDay}}",
  "processe.schedules.onDay.third":
    " - El día {{startDay}}",
  "processe.schedules.onDay":
    " - El día {{startDay}}",
  "Go to the home page":
    "Ir a la página de inicio",
  "Sorry the page you are looking for could not be found":
    "Lo sentimos, no se ha encontrado la página que busca.",
  "orchestrator.license.originalId": "ID de licencia original",
  "orchestrator.license.starts": "Empezar",
  "orchestrator.license.expires": "Expirar",
  "orchestrator.license.installedOn": "Instalado",
  "orchestrator.license.maxConcurrentSessions": "Número de sesiones",
  "orchestrator.license.status": "Estado",
  "orchestrator.license.status.ACTIVE": "Activa",
  "orchestrator.license.status.EXPIRED": "Expirada",
  "orchestrator.license.status.NOT_YET_ACTIVE": "Aún no activa",
  "A license is one month away from expiry": "Una licencia caduca en un mes",
  "A license is two months away from expiry": "Una licencia vence en dos meses",
  "tooltip.action.license": "Licencias",
  "Orchestrator licenses": "Licencias de orquestador",
  "process.laststage": "ÚLTIMA FASE: {{lastStage}}",
  "analytics.preset.button.label": "Preajustes",
  "analytics.preset.load": "Cargar preajuste existente",
  "analytics.preset.create": "Crear nuevo preajuste",
  "analytics.preset.noPresets": "No hay ajustes preajustes guardados",
  "analytics.preset.dialog.label": "Nuevo preajuste",
  "analytics.preset.dialog.field.label": "Nombre de preajuste",
  "analytics.preset.dialog.field.submit": "Crear preajuste",
  "analytics.preset.dialog.delete.label": "Eliminar preajuste",
  "analytics.preset.dialog.delete.text": "¿Está seguro de que desea eliminar este preajuste?",
  "analytics.preset.dialog.delete.submit": "Eliminar preajuste",
  "resource.tooltip.hide": "¿Estás seguro de que quieres mostrar {{count}} recurso(s)?",
  "recurso.tooltip.unhide": "¿Está seguro de que quieres ocultar {{count}} resource(s)?",
  "export.confirmation.radio.grouped": "Exportar con los detalles del artículo agrupados.",
  "export.confirmation.radio.columns": "Exportar con detalles del artículo en columnas.",
  "export.confiramtion.warnning": "Para habilitar esta opción, debe seleccionar un automatización.",
  FINISHED: "TERMINADO",
  EXPORTING: "EXPORTACIÓN",
  COLLECTING: "RECOLECCIÓN",
  "exporting.title": "Exportando archivos",
  "exporting.no.data": "No hay archivos para descargar",
  "exporting.btn.download.all": "Descargar todo",
  "exporting.btn.download": "Descargar",
  "exporting.btn.delete.all": "Borrar todo",
  Executions: "Ejecuciones",
  "routes.orchestrators.executions": "Ejecuciones",
  "no.executions.message": "No se encontraron ejecuciones",
  Resource: "Recurso",
  Trigger: "Gatillo",
  MANUALLY: "A mano",
  SCHEDULED: "Programado",
  KEEP: "Para mantener en cola",
  DELETE: "Eliminar de la cola",
  Dates: "Fechas",
  "Launching date": "Fecha de lanzamiento",
  "Launching date (Descending)": "Fecha de lanzamiento (descendente)",
  "Launching date (Ascending)": "Fecha de lanzamiento (ascendente)",
  "SR Queues": "Colas de SR",
  "queue.management.status": "Estado",
  "queue.management.name": "Nombre",
  "queue.management.choose.resource": "Algunos horarios no están asignados a ningún recurso, al migrarlos al orquestador original asígnelos a:",
  "queue.management.creation-date": "Fecha de creación",
  "queue.management.active-since": "Activo desde",
  "queue.management.nb-licences": "Número de licencias",
  "queue.management.orchestrator": "Orquestador",
  "queue.management.search": "Búsqueda",
  "queue.management.new": "Crear cola SR",
  "queue.management.add": "Agregar cola",
  "queue.management.delete.confirm-msg": "¿Estás seguro de que quieres eliminar la cola seleccionada?",
  "queue.management.bulk-delete.confirm-msg": "¿Estás segura de que quieres eliminar las colas seleccionadas?",
  "queue.management.enable.confirm-msg": "¿Estás segura de que quieres activar esta cola?",
  "queue.management.enable.confirm": "Activar cola",
  "queue.management.disable.confirm-msg": "¿Estás segura de que quieres desactivar esta cola?",
  "queue.management.disable.confirm": "Inhabilitar cola",
  "queue.management.section.info": "Información general",
  "queue.management.section.processes": "Processes",
  "queue.management.section.resources": "Recursos",
  "queue.management.form.save.success": "La cola SR se creó con éxito",
  "queue.management.form.update.success": "La cola de SR se actualizó con éxito",
  "queue.management.form.save.error": "No se pudo crear la cola de SR",
  "queue.management.form.update.error": "No se pudo actualizar la cola de SR",
  "queue.management.form.validation.required": "Este campo es obligatorio",
  "queue.management.form.validation.negative-nb-licences": "El número de licencias no puede ser negativo",
  "queue.management.update": "Cola de actualización",
  "queue.management.save.confirm-msg": "¿Está seguro de que desea crear esta cola de SR?",
  "queue.management.update.confirm-msg": "¿Está seguro de que desea actualizar esta cola de SR?",
  "queue.management.reset.confirm-msg": "¿Está seguro de que desea eliminar todas las ejecuciones perdidas de esta cola de SR?",
  "restriction.form.missedExecutionsPolicy": "Política de ejecuciones fallidas",
  "restriction.userGuide.missedExecutionsPolicy": "Especifica si una ejecución perdida debe mantenerse o eliminarse de la cola de SR.",
  "queue.management.assign.process.part1": "Está a punto de delegar la ejecución de {{this_process}} a las colas de SR. Para garantizar el rendimiento esperado, absténgase de programar y ejecutar ejecuciones fuera de SmartRoby.",
  "queue.management.assign.process.part2": "Se encontró una programación para el {{this_process}} {{at_its}} Orchestrator original, ¿le gustaría migrarlo para usarlo con SR Queues?",
  "queue.management.assign.process.part2_plural": "Se encontraron cronogramas {{nbSchedules}} para el orquestador {{this_process}} {{at_its}} original, ¿desea migrarlos para usarlos con SR Queues?",
  "queue.management.unassign.process.part1": "Está a punto de anular la delegación de la ejecución de {{this_process}} de las colas de SR. En el futuro, todas las ejecuciones y programaciones pueden provenir de SmartRoby o de su orquestador original.",
  "queue.management.unassign.process.part2": "Se encontró una programación de SR Queues, ¿le gustaría migrarla al orquestador original?",
  "queue.management.unassign.process.part2_plural": "Se han encontrado {{nbSchedules}} programaciones de SR Queues, ¿le gustaría migrarlas al orquestador original?",
  thisProcess: "este automatización",
  thisProcess_plural: "esos automatización",
  atIts: "en su",
  atIts_plural: "en su",
  some: "algunos",
  "resource-selector.available-resources": "Recursos disponibles",
  "resource-selector.assigned-resources": "Recursos asignados",
  "queue.management.form.validation.nb-licences-bigger-than-remaining": "Hay {{count}} licencias restantes en el orquestador seleccionado",
  "queue.management.no-data": "No hay colas todavía",
  "queue.management.open-queue": "Abrir en cola SR",
  "queue.management.reset-queue": "Restablecer cola de SR",
  "execution.manager.title": "Cola SR",
  "queue reseted successfully": "SR Queue se ha restablecido con éxito.",
  "error while reseting queue": "No se pudo restablecer la cola de SR.",
  "Add SR Queue": "Añadir cola SR",
  "Edit SR Queue": "Editar cola de SR",
  "Delete SR Queue": "Eliminar cola de SR",
  "process.assigned.srqueue.tooltip": "Este automatización está asignado a una cola SR.",
  "process.not.assigned.srqueue.tooltip": "Este automatización no está asignado a ninguna cola SR",
  "number of runing executions singular": "Ya hay una ejecución en curso",
  "number of runing executions plural": "Algunas ejecuciones ya están en curso",
  "not enough licenses":
    ", no tiene ninguna licencia disponible. Póngase en contacto con su administrador de RPA.",
  "check.connectivity":
    "Verifique la conectividad con el servidor de aplicaciones {{serverName}}, parece que se perdió la conexión.",
  "deleted by sr policy": "La política de colas de SR elimina un programa",
  "execution is deleted": "Una ejecución de SR Queue eliminada manualmente",
  "execution is missed": "Falta una ejecución de SR Queue",
  "missed raison": "Perdido debido a",
  UNAVAILABLE_ROBOTS: "Recursos no disponibles",
  NOT_ENOUGH_LICENCES: "No hay suficientes licencias",
  BP_UNREACHABLE: "BP inalcanzable",
  BP_UNRESPONSIVE: "BP no responde",
  "resource.formControl.succesUpdate": "Información del recurso actualizada con éxito",
  "cant perform multi queue migration": "Se han encontrado algunos horarios de SR Queues para las colas seleccionadas. No se pueden migrar al orquestador original.",
  "something went wrong": "Algo salió mal al procesar su consulta, vuelva a intentarlo.",
  Continue: "Seguir",
  "Something.Wrong": "Ocurre algo",
  "export.successful.notification": "Su archivo se ha agregado a la sección de archivos de exportación y ahora está listo para verlo y descargarlo. La adición fue exitosa.",
  revoke: "revocar",
  "usersSelector.availableUsers": "Usuarios disponibles",
  "usersSelector.assignedUsers": "Usuarios asignados",
  "usersSelector.availableAdmins": "Administradores disponibles",
  "usersSelector.assignedAdmins": "Administradores asignados",
  "custom.kpi.item": "Artículos",
  "custom.kpi.process": "Proceso",
  "custom.kpi.processExecution": "Ejecuciones",
  "custom.kpi.pending": "Pendiente",
  "custom.kpi.completed": "Completados con éxito",
  "custom.kpi.completedWithException": "Completados con excepción",
  "custom.kpi.completedWithBusinessException": "Completados con excepción comercial",
  "custom.kpi.completedWithIntSysException": "Completados con excepción de sistema/interna",
  "custom.kpi.executionTimeHours": "Tiempo de ejecución (horas)",
  "custom.kpi.timeSavedHours": "Tiempo ahorrado (horas)",
  "reporting.createKpi": "Crear nuevo KPI",
  "custom.kpi.dialog.create.label": "Nuevo KPI",
  "custom.kpi.dialog.field.name": "Nombre del KPI",
  "custom.kpi.dialog.field.element": "Para cada",
  "custom.kpi.dialog.field.detail": "Detalle",
  "custom.kpi.dialog.field.allocation": "asignar",
  "custom.kpi.dialog.field.unitofmesure": "Unidad de medida",
  "custom.kpi.formControl.required": "Campo requerido",
  "custom.kpi.formControl.pattern": "Por favor, ingrese un número",
  "custom.kpi.dialog.create.field.submit": "CREAR KPI",
  "custom.kpi.dialog.delete.label": "Eliminar KPI",
  "custom.kpi.dialog.delete.text": "¿Estás seguro de que quieres eliminar este KPI?",
  "custom.kpi.dialog.delete.submit": "Eliminar KPI",
  "custom.kpi.dialog.create.label.edit": "Editar KPI",
  "custom.kpi.dialog.create.field.edit": "EDITAR KPI",
  "fleets.fleet": "Banda",
  "no.fleet.message": "Aún no hay bandas",
  "instance.owner": "Propietario de la instancia",
  "instance.owner.confirmMsg": "¿Estás seguro de que deseas proceder con la asignación de un nuevo propietario de la instancia?/nEsta acción hará que tu conjunto de recursos pierda su estado de propietario de la instancia.",
  "instance.owner.can.not.be.disabled": "El propietario de la instancia no puede ser deshabilitado",
  "orchestrator.management.formControl.isPrivate": "Orquestador privado",
  "orchestrator.management.formControl.automatic.tags.creation": "Creación automática de etiquetas",
  "orchestrator.management.formControl.is.orchestration.enabled": "Habilitar la orquestación inteligente",
  "orchestrator.management.formControl.automatic.tags.creation.information": "Al activar esta opción se generarán automáticamente etiquetas en Orchestrator para cada grupo de automatización en Blue Prism. Todas las automatizaciones dentro de un grupo se asignarán a la etiqueta correspondiente",
  private: "Privado",
  "fleet.with.no.users": "Esta banda no tiene usuarios, no se puede asignar como propietario de la instancia",
  "user.fleet.disabled.message": "La banda de este usuario está desactivada, no se puede habilitar a este usuario.",
  "instance.owner.only.active.admin.can.not.be.disabled": "El único administrador activo del propietario de la instancia no se puede deshabilitar",
  "fleet.with.no.admins": "Esta banda no tiene ningún administrador, no se puede convertir en propietario de la instancia",
  "search.available.processes": "buscar automatización disponibles",
  "search.assigned.processes": "buscar automatización asignados",
  "search.available.tags": "buscar etiquetas disponibles",
  "search.available.users": "buscar usuarios disponibles",
  "search.assigned.users": "buscar usuarios asignados",
  "search.available.resources": "buscar recursos disponibles",
  "search.assigned.resources": "buscar recursos asignados",
  "drag.and.drop.here": "Arrastra y suelta aquí",
  "tooltip.action.archive": "archivo",
  "archive.confirm.msg": "El/Los tag(s) seleccionado(s) será/serán archivado(s)",
  "tag.management.btn.confirm": "Archivar",
  "tag.archived.success": "Tag(s) archivado con éxito",
  "tag.management.deleteTagActionMessage": "Eliminar etiqueta(s) seleccionada(s)",
  "tag.management.archived": "Archivado",
  Tags: "Etiquetas",
  "tag.management.add.button": "Crear etiqueta",
  "tag.status": "Estado",
  "tag.icon": "Icono",
  "tag.name": "Nombre de Etiqueta",
  "tag.description": "Descripción de la Etiqueta ",
  "tag.management.name": "Nombre",
  "tag.management.formControl.desc": "Descripción",
  "no.tags.message": "Aún no hay etiquetas",
  "wrong.file.type": "Tipo de archivo inválido",
  "invalid license file": "Archivo de licencia inválido",
  "invalid signature": "Firma inválida",
  "empty file": "Archivo vacío",
  "wrong file extension": "Extensión de archivo incorrecta",
  "missing file": "Falta archivo",
  "the submitted license file is invalid": "El archivo de licencia enviado es inválido",
  "the signature of the license is invalid": "La firma de la licencia es inválida",
  "the submitted file is empty": "El archivo enviado está vacío",
  "we only accept .lic files": "Solo aceptamos archivos .lic",
  "please upload a license file": "Por favor, sube un archivo de licencia",
  activation: "Activación",
  license: "Licencia",
  "import your license here": "Importa tu licencia aquí...",
  activate: "Activar",
  "licence.id": "ID de licencia",
  "licence.licensee": "Licenciatario",
  "licence.start.at": "Comienza en",
  "licence.end.at": "Termina en",
  "licence.validation.valid": "Su licencia está válida",
  "licence.validation.expire": "Por favor, renueve su licencia ya que ha expirado.",
  "licence.goback.btn": "VOLVER A INICIO",
  "tag.management.hide.confirmMsg": "¿Estás seguro de que deseas eliminar la etiqueta? Todos los datos se perderán de forma permanente.",
  "productivity.hrs": "Productividad",
  Occupancy: "Ocupación (%)",
  "robots.occupancy": "Ocupación",
  "dashboard.monitoring.mode": "Modo de monitoreo",
  "HRS Saved": "HRS Guardadas",
  "Resource Productivity": "Productividad del Recurso",
  "Process Activity": "Actividad del Proceso",
  "Working Time (HRS)": "Tiempo de Trabajo (HRS)",
  "Processed Items": "Elementos Procesados",
  "HRS Runtime": "Tiempo de Ejecución (HRS)",
  mins: "mins",
  "recently.used.processes": "Automatización más utilizados",
  "process.total.cost": "Costo total del automatización",
  "tag.management.form.save.success": "Etiqueta guardada exitosamente",
  "tag.management.form.update.success": "Etiqueta actualizada exitosamente",
  "tag.management.form.save.error": "Error al crear la etiqueta",
  "tag.management.form.update.error": "Error al actualizar la etiqueta",
  "scheduler.is.required": "Se requiere un horario",
  "tooltip.action.close": "Cerrar",
  "tag.deleted.success": "Etiquetas eliminadas con éxito",
  "try.active.alert": "El automatización {{processName}} está oculto, para activar esta alerta por favor muestrela.",
  "filter by": "Filtrado por",
  showing: "Mostrando del",
  to: "al",
  from: "de",
  entries: "entradas",
  "Data mapping": "Mapeo de datos",
  Temps: "Tiempo",
  "process.start.restriction": "Se supone que el automatización que está iniciando no debe ejecutarse hoy en función de sus SLA, ¿está seguro de que desea continuar?",
  "process.start.require.input": "Este automatización requiere una entrada para iniciarse.",
  "process.inputs.button.reset": "Reiniciar",
  "process.inputs.button.save": "Agregar",
  "process.inputs.save.blank.values.title": "Se han detectado algunos parámetros con valores de entrada en blanco.",
  "process.inputs.save.blank.values.message": "¿Está seguro de que desea iniciar estas sesiones con los valores proporcionados ?",
  Now: "Ahora",
  "server Type": "Tipo de servidor",
  "queue.management.resource.has.pendingExecutions": "Tiene {{count}} ejecuciones pendientes utilizando este recurso, por favor, elimínelas si desea proceder con esta operación.",
  "queue.management.resource.has.scheduled.pendingExecutions": "Se han programado {{count}} ejecuciones, por favor, revise los horarios asignados a este recurso.",
  "Automation Impact": "Impacto de la automatización",
  ERRORS_NO_DATA_AVAILABLE: "No hay datos disponibles",
  "occupancy.waiting.more": "Esperando más ejecución para calcular la ocupación del recurso",
  "select.process": "Seleccionar un automatización",
  "changes.saved.successfully": "Cambios guardados exitosamente",
  "changes.saving.failed": "Error al guardar los cambios",
  "process.owner": "Propietario del automatización",
  "end date cannot be earlier than the start date": "La fecha de finalización no puede ser anterior a la fecha de inicio",
  "end date cannot be in the future": "La fecha de finalización no puede estar en el futuro",
  "start date cannot be newer than the end date": "La fecha de inicio no puede ser más reciente que la fecha de finalización",
  "Start date cannot be in the future": "La fecha de inicio no puede estar en el futuro",
  "no.period.message": "No se encontró período",
  "no.status.message": "No se encontró estado",
  "pending.executions.warning": "Advertencia: Este automatización tiene ejecuciones pendientes. Eliminarlo ahora eliminará todas ellas.",
  "calendar.formControl.saveRiskSuccess": "Margen de riesgo añadido con éxito",
  "export.confirm.msg.delete.all": "¿Está seguro de que desea eliminar todos los archivos ?",
  "export.confirm.msg.delete.selected": "¿Está seguro de que desea eliminar los archivos seleccionados ?",
  "export.confirm.msg.delete.one": "¿Está seguro de que desea eliminar este archivo ?",
  on: "En",
  language: "Idioma",
  "Choose.the.checking.frequency": "Agregar la frecuencia de verificación",
  "resources.show.hidden": "Mostrar recursos ocultos",
  "resources.hide.hidden": "Ocultar recursos ocultos",
  "exceptions.show.hidden": "Mostrar excepciones ocultas",
  "user.logo.allowed.extensions": "Solo se permiten extensiones jpg, jpeg, png y bmp",
  "allowed.extensions": "Solo se permiten extensiones {{extensions}}",
  "max.size.allowed": "El tamaño máximo de archivo permitido es {{size}} MB",
  "items.pending.locked": "Se está trabajando en este item actualmente",
  "items.pending.deferred": "Este elemento ha sido diferido hasta el {{date}}",
  "export.limit": "Es posible exportar únicamente los 100 000 primeros registros, pero para los resultados más los resumenes, tiene la posibilidad de precisar los criterios de búsqueda.",
  "queue.create.update.restriction": "Cada cola debe tener al menos 1 licencia, 1 automatización asignado y 1 recurso asignado.",
  "custom.kpi.dialog.field.type": "Tipo de KPI",
  FINANCIAL_KPI: "KPI Financiero",
  PRODUCTIVITY_KPI: "KPI de productividad",
  EFFICIENCY_KPI: "KPI de eficiencia",
  "reporting.financialImpact": "Impacto financiero",
  "reporting.resourceProductivity": "Productividad de recursos",
  "reporting.avgHandlingTime": "Tiempo promedio de manejo",
  "max.datamap.reached": "Puede elegir sólo 3 campos para mostrar en la lista de elementos",
  "retry.mark.done": "Reintentar y marcar como manejado",
  "fleet.management.formLabel.currency": "Divisa",
  "tooltip.visit.link": "Visitar enlace",
  "tooltip.edit.link": "Editar enlace",
  "tooltip.delete.link": "Borrar enlace",
  "no.link.message": "No se ha encontrado ningún enlace",
  "confirm.delete.link": "¿Está seguro de que desea eliminar este enlace?",
  "add.link": "Insertar nuevo enlace",
  "create.link": "Crear enlace",
  "confirm.add.link": "¿Seguro que quieres crear este enlace?",
  "insert.link": "Insertar",
  "link.validate.url": "Por favor, introduzca una URL válida",
  "link.hypertextUrl": "Hipervínculo del documento externo",
  "link.name": "Nombre",
  "link.url": "Enlace",
  "link.description": "Descripción",
  "link.delete.success": "Enlace eliminado con éxito",
  "link.add.success": "Enlace creado con éxito",
  "link.update.success": "Enlace modificado con éxito",
  "link.add.failure": "¡No se ha podido crear el enlace! Vuelva a intentarlo más tarde",
  "link.update.failure": "¡No se ha podido actualizar el enlace! Vuelva a intentarlo más tarde",
  "link.tab": "documentación externa",
  "View external documentation": "Mostrar la documentación externa",
  "Manage external documentation": "Gestión la documentación externa",
  "Description (ascending)": "Descripción (ascendente)",
  "Description (descending)": "Descripción (descendente)",
  "Name (ascending)": "Nombre (ascendente)",
  "Name (descending)": "Nombre (descendente)",
  "disconnection.period": "Por más de (minutos)",
  "help.exception.type": "Por favor, elija un tipo de excepción",
  "audit.object": "Objeto",
  "audit.user": "Usuario",
  "audit.action": "Acción",
  "audit.time.filter": "Filtro de tiempo",
  "audit.sort.by": "Ordenar por",
  Audit: "Registro de auditoría",
  "audit.module": "Módulo",
  "audit.times.stamp": "Marca de tiempo",
  "audit.search.logs": "Buscar registros",
  "no.object.message": "No se encontró ningún objeto",
  "no.user.message": "No se encontró ningún usuario",
  "no.module.message": "Ningún módulo encontrado",
  "no.action.message": "No se encontró ninguna acción",
  "no.time.filter.message": "No se encontró ningún filtro de tiempo",
  "no.sort.message": "No se encontró ningún orden",
  "audit.module.descending": "Módulo (Descendente)",
  "audit.module.ascending": "Módulo (Ascendente)",
  "audit.user.descending": "Usuario (Descendente)",
  "audit.user.ascending": "Usuario (Ascendente)",
  "fleet.user.delete.current": "No puede eliminar grupos de usuarios activos o usuarios con excepciones asignadas",
  "user.delete.current": "No puede eliminar al usuario activo",
  "users.delete.has.exception": "Usuario(s) tiene(n) excepciones asociadas",
  "Split equally": "Dividir equitativamente",
  deletedSuccessfullySingle: "{{objectNature}} {{ObjectName}} ha sido eliminado con éxito.",
  deletedSuccessfullyDouble: "{{objectNature}} {{firstObjectName}} y {{secondObjectName}} se han eliminado con éxito.",
  deletedSuccessfullyMultiple: "{{twoFirstObjectName}} y {{remainingCount}} otro {{objectNatureRemaining}} se han eliminado con éxito.",
  usuarioSingle: "usuario",
  userPlural: "usuarios",
  groupSingle: "grupo",
  groupPlural: "grupos",
  tagSingle: "etiqueta",
  tagPlural: "etiquetas",
  orchestratorSingle: "orchestrator",
  orchestratorPlural: "orquestadores",
  queueSingle: "cola",
  queuePlural: "colas",
  "time.zone": "Zona horaria",
  "profile.update-password.rewrite.email": "Reescribe el correo electrónico",
  "profile.update-password.wrong.password": "La contraseña no coincide",
  Preferences: "Preferencias",
  "user.information": "Información del usuario",
  "change.your.password": "Cambia tu contraseña",
  "Tag already exist with the same name": "La etiqueta ya existe con el mismo nombre.",
  "title.confirm.message.delete": "Suprimir un grupo",
  "items.pending": "Pendiente",
  configuration: "Configuración",
  "field.is.required": "{{field}} es obligatorio",
  "Setup Automatic Assignment": "Configuración de la asignación automática",
  "Assignement Rules": "Normas de asignación",
  "Automatic Exception Assignment": "Asignación automática de excepciones",
  "Rule Configuration": "Configuración de reglas",
  "Rule Name": "Nombre de la regla",
  "When an exception occurs for": "Cuando ocurre una excepción para",
  "logical Connector": "Conector lógico",
  "Item Data": "Datos del ítem",
  Condition: "Condición",
  Value: "Valor",
  "Assign to": "Asignar a",
  "User List": "Lista de usuarios",
  "Group List": "Lista de grupos",
  "Alert on assignment": "Alerta en la asignación",
  Summary: "Resumen",
  "New Rule": "Nueva regla",
  "Exception Type/Reason": "Tipo/Razón de la excepción",
  GREATER: "Es mayor que",
  SMALLER: "Es menor que",
  CONTAINS: "Contiene",
  "IS.EQUAL": "Es igual a",
  "rules.already.exists.with.processes": "Ya existe una regla con los mismos automatización. ¿Desea eliminar la regla antigua?",
  "confirm.delete.rule": "¿Estás seguro de que quieres eliminar esta regla?",
  "confirm.disable.rule": "¿Estás seguro de que quieres desactivar esta regla ?",
  "confirm.enable.rule": "¿Estás seguro de que quieres activar esta regla ?",
  "When an exception occurs for details": "Utilice esta configuración para especificar qué excepciones deben asignarse automáticamente. Seleccione un automatización y defina los detalles de las excepciones que desea asignar.",
  "Assign to details": "Aquí puede seleccionar si desea asignar excepciones a un usuario específico o a un grupo según las reglas que ha definido. Además, decida si desea alertar a la parte asignada sobre la excepción.",
  "synchronized.processes": "Proceso para sincronizar",
  "max.processes.reached": "Máximo de automatización alcanzado",
  "process.in.orchestrator": "Proceso en el orquestador",
  "process.synchronize": "Proceso para sincronizar",
  "orchestrator.formControl.processes.saveSuccess": "Proceso para sincronizar agregado exitosamente",
  "orchestrator.formControl.processes.updateSuccess": "Proceso para sincronizar actualizado exitosamente",
  "task.initial.task": "Tarea inicial",
  "task.post.completion.delay": "Retraso después de completar",
  "task.remove": "Eliminar tarea",
  "task.name": "Nombre de la tarea",
  "task.description": "Descripción de la tarea",
  "task.on.complete": "Al completar",
  "task.on.exception": "En caso de excepción",
  "task.add.session": "Agregar sesión",
  "task.delete.session": "Eliminar sesión",
  "task.name.already.exist": "El nombre de la tarea ya existe",
  "task(s)": "Tarea(s)",
  "add.task": "Agregar tarea",
  task: "Tarea",
  "no.tasks.yet": "Aún no hay tareas",
  "no.task.message": "No se encontró ninguna tarea",
  "task.required.process": "Por favor, elija un automatización",
  "task.required.first.session.process": "Por favor, elija el automatización de la primera sesión",
  "process.migration.not.possible": "Las siguientes automatizaciones ya están asignadas a otros SRQ. Una automatización solo puede asignarse a un SRQ.",
  "see.all": "Ver todo",
  "sync.task_session": "Sesiones de tareas",
  "sync.sch_task": "Tareas",
  "unique.schedule.name.exception": "El nombre del horario ya existe",
  "awaiting.synchro": "Esperando sincronización",
  "pendingCount.descending": "Elementos pendientes (descendente)",
  pendingCount: "Elementos pendientes",
  "completedCount.descending": "Elementos completados (descendente)",
  completedCount: "Elementos completados",
  "exceptionCount.descending": "Elementos excepcionales (descendente)",
  exceptionCount: "Elementos excepcionales",
  "productivity.descending": "Productividad (descendente)",
  "occupancy.ascending": "Ocupación",
  "occupancy.descending": "Ocupación (descendente)",
  "processRestriction.recentActivity": "Actividad reciente",
  "processRestriction.recentActivity.descending": "Actividad reciente (descendente)",
  "average.FTEs.saved": "Tiempo medio ahorrado",
  "success.rate": "Tasa de éxito",
  "average.success.rate": "Tasa de éxito promedio",
  transactions: "Transacciones",
  "total.transactions": "Transacciones totales",
  "average.transactions": "Transacciones promedio",
  min: "min",
  week: "semana",
  "newDashboard.AllStats.title": "Estadísticas de todos los tiempos",
  "newDashboard.CurrentStats.title": "Estadísticas actuales",
  "transactions.header.tooltip": "Número total de transacciones",
  "successRate.header.tooltip": "Porcentaje de transacciones exitosas a lo largo del tiempo",
  "Completed transactions": "Transacciones realizadas",
  "bots.header.tooltip": "Tiempo total de trabajo de los bots",
  "FTESaved.header.tooltip": "Total de FTEs ahorrados",
  "transactions.header.label": "Todas las transacciones",
  "items.header.label": "Elementos",
  "successRate.header.label": "Tasa de éxito",
  "transactions.cardHeader": "Transacciones",
  "AvgHandlingTime.cardHeader": "Tiempo medio de gestión",
  "inProgress.transactions.status": "En curso",
  "completed.transactions.status": "Completado",
  "all.processes": "Automatización maestros",
  "working.processes.status": "Funcionando según los horarios",
  "notWorking.processes.status": "Planificaciones con ejecuciones faltantes",
  "Processes.activity.tooltip": "Este KPI sigue el número de automatización que están en horario y se están ejecutando según lo planeado, en comparación con el número de automatización que están programados pero no están procediendo según el cronograma planeado.",
  "Schedules health": "Estado de los horarios",
  "til.now": "Hasta ahora",
  "Monitoring Dashboard": "Tablero de monitoreo",
  "System exceptions": "Excepciones del sistema",
  "Business exceptions": "Excepciones comerciales",
  "reporting.exportCSV": "Exportar como CSV",
  "reporting.exportCSV.all": "Exportar todo como CSV",
  migrate: "Migrar",
  "do.not.migrate": "No migrar",
  "The name of the schedule": "El nombre del horario",
  "The first task executed by the schedule": "La primera tarea ejecutada por el horario",
  "The date and time of the first execution of the schedule": "La fecha y hora de la primera ejecución del horario",
  "The date and time after which the schedule will no longer be executed": "La fecha y hora después de la cual el horario ya no se ejecutará",
  "The frequency at which the schedule will be executed (starting from the start date)": "La frecuencia con la que se ejecutará el horario (a partir de la fecha de inicio)",
  "Set(s) of process sessions that are executed concurrently": "Conjunto(s) de sesiones de automatización que se ejecutan concurrentemente",
  "The task to execute if this task is completed": "La tarea a ejecutar si esta tarea se completa",
  "The task to execute if this task ends on an exception": "La tarea a ejecutar si esta tarea termina con una excepción",
  "The process to be executed in this task session": "El automatización a ejecutar en esta sesión de tarea",
  "The resource (robot on prod) on which the process will be executed": "El recurso (robot en producción) en el que se ejecutará el automatización",
  GROUP_ADDED: "Grupo agregado",
  GROUP_MODIFIED: "Información del grupo modificada",
  GROUP_DELETED: "Grupo eliminado",
  USER_ADDED: "Usuario agregado",
  USER_INFO_MODIFIED: "Información del usuario modificada",
  USER_DELETED: "Usuario eliminado",
  USER_PERMISSIONS_MODIFIED: "Permisos de usuario modificados",
  PROCESS_EXCEPTION_HIDDEN: "La excepción del automatización estaba oculta",
  PROCESS_EXCEPTION_UNHIDDEN: "Se mostró una excepción en el automatización",
  DEFAULT_ALERT_CREATED: "Alerta predeterminada creada",
  DEFAULT_ALERT_MODIFIED: "Alerta predeterminada modificada",
  DEFAULT_ALERT_DELETED: "Alerta predeterminada eliminada",
  CUSTOM_ALERT_CREATED: "Alerta personalizada creada",
  CUSTOM_ALERT_MODIFIED: "Alerta personalizada modificada",
  CUSTOM_ALERT_DELETED: "Alerta personalizada eliminada",
  TAG_CREATED: "Etiqueta creada",
  TAG_DELETED: "Etiqueta eliminada",
  TAG_ASSIGNED_PROCESSES: "Automatización asignados a la etiqueta",
  TAG_UNASSIGNED_PROCESSES: "Automatización retirados de la etiqueta",
  USER_ASSIGNED_PROCESSES: "Automatización asignados al usuario",
  USER_UNASSIGNED_PROCESSES: "Automatización retirados del usuario",
  USER_ASSIGNED_TAGS: "Etiquetas asignadas al usuario",
  USER_UNASSIGNED_TAGS: "Etiquetas retiradas del usuario",
  PROCESS_SLA_UPDATED: "SLA de automatización actualizados",
  PROCESS_SETTINGS_UPDATED: "Configuración del automatización actualizada",
  PROCESS_DATA_MAPPING_UPDATED: "Configuración de asignación de datos actualizada",
  RESOURCE_ASSIGNED_PROCESSES: "Automatización asignados al recurso",
  RESOURCE_UNASSIGNED_PROCESSES: "Automatización retirados del recurso",
  RESOURCE_INFORMATION_MODIFIED: "Información de recursos modificada",
  RESOURCE_HIDDEN: "El recurso estaba oculto",
  RESOURCE_UNHIDDEN: "Se mostró el recurso",
  COST_ALLOCATION_ADDED: "Costo agregado",
  COST_ALLOCATION_DELETED: "Costo eliminado",
  COST_ALLOCATION_EDITED: "Costo modificado",
  PROCESS_PARAMETERS_EDITED: "Parámetros del automatización modificados",
  DB_CONNECTION_INITIATED: "Conexión de base de datos iniciada",
  ORCHESTRATOR_ADDED: "Orquestador agregado",
  ORCHESTRATOR_MODIFIED: "Información del orquestador modificada",
  ORCHESTRATOR_ENABLED: "Orquestador habilitado",
  ORCHESTRATOR_DISABLED: "Orquestador deshabilitado",
  ORCHESTRATOR_DELETED: "Orquestador eliminado",
  ANALYTICS_PRESET_CREATED: "Ajuste preestablecido de análisis creado",
  ANALYTICS_PRESET_DELETED: "Preajuste de análisis eliminado",
  CUSTOM_KPI_DELETED: "KPI personalizado eliminado",
  CUSTOM_KPI_CREATED: "KPI personalizado creado",
  CUSTOM_KPI_MODIFIED: "Información de KPI personalizada modificada",
  QUEUE_ASSIGNED_RESOURCE: "Recurso asignado a la cola SR",
  QUEUE_UNASSIGNED_RESOURCE: "Recurso retirado de la cola SR",
  QUEUE_ASSIGNED_PROCESS: "Proceso asignado a la cola SR",
  QUEUE_UNASSIGNED_PROCESS: "Proceso retirado de la cola SR",
  QUEUE_CREATED: "Cola SR creada",
  QUEUE_MODIFIED: "Información de la cola SR modificada",
  QUEUE_DELETED: "Cola SR eliminada",
  "A role with the same already exists": "Ya existe un rol con el mismo nombre",
  "Update Role": "Actualizar rol",
  "Role Name": "Nombre del rol",
  "Apply to this group": "Se establece como función por defecto para los siguientes grupos",
  "Select Permissions": "Seleccionar permisos",
  "Manage Roles": "Roles",
  Role: "Rol",
  "default.group.role": "Rol por defecto del grupo",
  "group.change.role": "Al cambiar la función predeterminada del grupo, se aplicarán los permisos a todos los usuarios que hereden los permisos de este grupo",
  "Create Role": "Crear rol",
  "This role has associated users": "Este rol está asociado a usuarios.",
  assign: "asignar",
  unassign: "desasignar",
  "user.permission.paste.error": "Asegúrese de copiar permisos primero",
  "user.permission.paste": "Pegar permisos",
  "user.permission.copy": "Copiar permisos",
  "copy.role.permissions.template": "Copiar plantilla de permisos de rol",
  "Role Configuration": "Configuración de roles",
  "user.add.inherit.group.permissions": "Heredar permisos del grupo",
  Interfaces: "Interfaces",
  Automations: "Automatizaciones",
  "Opportunity assessment": "Oportunidades",
  "Personal settings": "Configuración personal",
  "List of item Data for this process": "Lista de datos de elementos para este automatización",
  "Last session synched": "Última sesión sincronizada",
  "The number of resources linked to this orchestrator": "El número de recursos vinculados a este orquestador",
  "The number of automations linked to this orchestrator": "El número de automatizaciones vinculadas a este orquestador",
  "Number of licences allowed to use on this orchestrator": "Número de licencias permitidas para usar en este orquestador",
  "The date of the latest session synched by SmartRoby": "La fecha de la última sesión sincronizada por SmartRoby",
  "The number of users belonging to this group": "El número de usuarios pertenecientes a este grupo",
  "The number of automations assigned to this group": "El número de automatizaciones asignadas a este grupo",
  "Resource type": "Tipo de recurso",
  "Paired automations": "Automatizaciones emparejadas",
  "The number of automations that this resource is able to run": "El número de automatizaciones que este recurso puede ejecutar",
  "The number of automations already scheduled with this resource": "El número de automatizaciones ya programadas con este recurso",
  "Created since": "Creado desde",
  "Human-in-the-loop activated": "Human-in-the-loop activado",
  "kpi.parameter.allowScheduling.tooltip": "Permite programar la automatización usando SmartRoby",
  "kpi.parameter.allowManual.tooltip": "Permite activar manualmente la automatización usando SmartRoby",
  "kpi.parameter.parameter.retryable.tooltip": "Permite a los usuarios volver a intentar excepciones para esta automatización",
  "kpi.parameter.human.in.the.loop.activated.tooltip": "Permite a los usuarios completar o cambiar los datos transaccionales antes de volver a intentar un elemento para esta automatización",
  "KPI settings": "Configuración de KPI",
  "SR properties": "Propiedades de SR",
  "Assignment Rules": "Reglas de asignación",
  "You choose a process assigned to queue, all processes must be assigned to the same queue": "Ha elegido un automatización asignado a la cola, todos los automatización deben asignarse a la misma cola",
  "Report a Bug": "Informar de un error",
  "executions found": "ejecuciones encontradas",
  "licence.max.automations": "Automatizaciones máximas",
  "Inherit group automations": "Heredar automatizaciones de grupo",
  "master.process": "Proceso maestro",
  "File exported successfully": "Archivo exportado correctamente",
  SCHEDULED_ON_COMPLETE: "En terminación programada",
  SCHEDULED_ON_EXCEPTION: "En excepción programada",
  "allocated.priority": "Prioridad asignada (SLA)",
  "Edit Resources": "Editar recursos",
  QUEUE_ASSIGNED_ROBOT: "Recurso asignado a la cola",
  SCHEDULE_ADDED: "Horario añadido",
  ITEM_EXCEPTION_MARKED_DONE: "Excepción de elemento marcada como hecha",
  ITEM_EXCEPTION_RETRIED: "Excepción de elemento reintentada",
  PROCESS_STARTED: "Proceso iniciado",
  SCHEDULE_MODIFIED: "Horario modificado",
  SCHEDULE_DELETED: "Horario eliminado",
  EXCEPTION_RULE_MODIFIED: "Regla de excepción modificada",
  EXCEPTION_RULE_ADDED: "Regla de excepción añadida",
  ITEM_EXCEPTION_RELEASED: "Excepción de elemento liberada",
  EXCEPTION_RULE_DELETED: "Regla de excepción eliminada",
  QUEUE_UNASSIGNED_ROBOT: "Recurso desasignado de la cola",
  ITEM_EXCEPTION_ASSIGNED: "Excepción de elemento asignada",
  EXCEPTION_RULE_DISABLED: "Regla de excepción desactivada",
  "Launched with input": "Lanzado con entrada",
  "Items processed": "Elementos procesados",
  "Items Exceptions can be retried with original": "Las excepciones de elementos se pueden reintentar con el original",
  "Item Exceptions can be retried with different data": "Las excepciones de elementos se pueden reintentar con datos diferentes",
  "Available robots": "Robots disponibles",
  "Robot assignation": "Asignación de robots",
  Time: "Tiempo",
  "Estimated execution time": "Tiempo de ejecución estimado",
  "Cost distribution": "Distribución de costos",
  "enhanced.search.tooltip": "Mejore su búsqueda con operadores lógicos: use 'AND' para encontrar proyectos de RPA que cumplan con múltiples criterios (por ejemplo, 'Automatización AND Finanzas'), 'OR' para descubrir proyectos en cualquiera de dos áreas (por ejemplo, 'Finanzas OR RRHH'), y 'NOT' para excluir ciertos temas de sus resultados (por ejemplo, 'Automatización NOT Manual').",
  "Automation dependency": "Dependencia de automatización",
  // resource statuses
  Null: "Nulo",
  "Logged Out": "Desconectado",
  Private: "Privado",
  // resources types
  None: "Ninguno",
  Retired: "Retirado",
  Local: "Local",
  Debug: "Depurar",
  Pool: "Grupo",
  LoginAgent: "Agente de inicio de sesión",
  DefaultInstance: "Instancia predeterminada",
  Desktop: "Escritorio",
  RemoteDigitalWorker: "Trabajador digital remoto",
  Open: "Abre",
  "Automations Pending": "Automatizaciones Pendientes",
  "Automations Pending Configuration": "Automatizaciones Pendientes de Configuración",
  "App Server": "Servidor de Aplicaciones",
  "Do you want to configure manually or copy from an existing automation?": "¿Desea configurar manualmente o copiar de una automatización existente?",
  "Configure manually": "Configurar manualmente",
  "Copy from an existing automation": "Copiar de una automatización existente",
  "Assign Automation to Users": "Asignar Automatización a Usuarios",
  "Define Automation Control Settings": "Definir Configuración de Control de Automatización",
  "Set Data Mapping": "Establecer Asignación de Datos",
  "Configure KPI Settings": "Configurar Configuración de KPI",
  "Set SLA Configuration": "Establecer Configuración de SLA",
  "Configure Financial Parameters": "Configurar Parámetros Financieros",
  "Set Linked Documentation": "Establecer Documentación Vinculada",
  "Stop.Process.confirmation.title": "Estás a punto de detener toda la ejecución de esta automatización",
  "Stop.Execution.confirmation.title": "Estás a punto de detener esta ejecución",
  "Stop.Execution.error.notification": "Error al detener la(s) ejecución(es)",
  "Stop.Execution.success.notification": "Ejecución(es) detenida(s) con éxito",
  "kpi.parameter.allowStop.tooltip": "Permite detener la ejecución de la automatización mientras se está ejecutando",
  "kpi.parameter.allowStop": "Puede detenerse",
  "kpi.parameter.allowStop.stoprequest.tooltip": "Puede detenerse utilizando la función de solicitud de parada",
  "kpi.parameter.allowStop.stoprequest": "Solicitar parada",
  "kpi.parameter.allowStop.stoprequest.itemdeferral.tooltip": "Puede detenerse aplazando todos los elementos pendientes actuales",
  "kpi.parameter.allowStop.itemdeferral": "Aplazar elementos pendientes",
  Stop: "Detener",
  "confirm.delete.role": "¿Estás seguro de que deseas eliminar este rol?",
  Orchestration: "Orquestación",
  SLA: "SLA",
  "Orchestration Group": "Grupo de Orquestación",
  queueItems: "Elementos de la Cola",
  sendStopRequest: "Enviar Solicitud de Detención",
  pauseQueueToStop: "Pausar Cola para Detener",
  immediateForcedStop: "Detención Forzada Inmediata",
  letTheProcessSelfStop: "Permitir que el Proceso se Detenga",
  veryImportant: "Muy Importante",
  important: "Importante",
  regular: "Regular",
  notImportant: "No Importante",
  "Queue name": "Nombre de la Cola",
  "Stop mode": "Modo de Detención",
  "Process Priority": "Prioridad del Proceso",
  Resources: "Recursos",
  "Intelligent Orchestration": "Orquestación Inteligente",
  "Activate Orchestration": "Activar Orquestación",
  "Add an sla": "Añadir un SLA",
  "Tag Filter": "Filtro de Etiquetas",
  "Response time calculation": "Cálculo del Tiempo de Respuesta",
  Deadline: "Fecha Límite",
  "Average running time": "Tiempo Promedio de Ejecución",
  "orchestration.formControl.succesUpdate": "Orquestación actualizada con éxito",
  "orchestration.management.disable.confirmMsg": "¿Está seguro de que desea desactivar esta orquestación?",
  "orchestration.management.enable.confirmMsg": "¿Está seguro de que desea activar esta orquestación?",
  "orchestration.management.delete.confirmMsg": "¿Está seguro de que desea eliminar esta orquestación?",
  "orchestration.management.deleteSelected.confirmMsg": "¿Está seguro de que desea eliminar las orquestaciones seleccionadas?",
  "Resource group": "Grupo de Recursos",
  "orchestration.deleted.success": "Orquestación eliminada con éxito",
  "orchestration.status.changed.success": "Estado de la orquestación cambiado con éxito",
  "In case of problems": "En Caso de Problemas",
  "Max retryal restart": "Máximo de Reintentos",
  "Can restart windows session": "Puede Reiniciar la Sesión de Windows",
  "Can restart BP ressource": "Puede Reiniciar el Recurso BP",
  "Host/Ip": "Host/Dirección IP",
  "Session Windows Login": "Inicio de Sesión de Windows",
  "Session Windows Password": "Contraseña de Windows",
  "Resource under maintenance not usable": "Recurso en Mantenimiento No Utilizable",
  "Windows session managed by ORS": "Sesión de Windows Gestionada por ORS",
  "orchestration.update.confirmMsg": "¿Está seguro de que desea actualizar esta orquestación?",
  "orchestration.save.confirmMsg": "¿Está seguro de que desea guardar esta orquestación?",
  "orchestration.formControl.errorUpdate": "Error al actualizar la orquestación",
  "orchestration.formControl.errorSave": "Error al guardar la orquestación",
  "orchestration.formControl.succesSave": "Orquestación guardada con éxito",
  "no.orchestration.message": "No se encontró ninguna orquestación",
  "Historical Execution Requests": "Solicitudes de ejecución histórica",
  "Execution Requests": "Solicitudes de ejecución",
  priority: "Prioridad",
  "request.date": "Fecha de solicitud",
  "estimated.duration": "Duración estimada",
  "max.wait.time": "Tiempo máximo de espera",
  "response.time.calc": "Cálculo del tiempo de respuesta",
  "Requested date (Ascending)": "Fecha de solicitud (ascendente)",
  "Requested date (Descending)": "Fecha de solicitud (descendente)",
  "MaxWaitTime (Descending)": "Tiempo máximo de espera (descendente)",
  "MaxWaitTime (Ascending)": "Tiempo máximo de espera (ascendente)",
  "EstimatedDuration (Descending)": "Duración estimada (descendente)",
  "EstimatedDuration (Ascending)": "Duración estimada (ascendente)",
  "archivedAt (Descending)": "Archivado en (descendente)",
  "archivedAt (Ascending)": "Archivado en (ascendente)",
  "archived.time": "Hora de archivo",
  "origine.of.stop": "Origen de la parada",
  "resources.formSection.orsInfo": "Información ORS",
  "resource.management.formControl.orsResourceVmIp": "IP de VM del recurso",
  "resource.management.formControl.orsScreenHeight": "Altura",
  "resource.management.formControl.orsScreenWidth": "Ancho",
  "resource.management.formControl.activateOrs": "Activar configuración ORS",
  "resource.management.formControl.orsScreenDpi": "DPI",
  "ors.connection.lost": "Conexión no encontrada o cerrada",
  "enable.ors": "Por favor, habilite ors para monitorear su sesión",
  "page.of": "Página {{page}} de {{pages}}",
  "orchestrator.management.formControl.irisUsername": "Nombre de usuario de Iris",
  "orchestrator.management.formControl.irisPassword": "Contraseña de Iris",
  "orchestrator.management.formControl.irisUrl": "URL de Iris",
  "days.to.license.expiry": "Le quedan {{daysToExpiry}} días hasta que expire su licencia",
  "license.max.automations.reached": "Ha alcanzado el número máximo de automatizaciones permitidas por su licencia, no podrá sincronizar más automatizaciones",
  "license.max.resources.reached": "Ha alcanzado el número máximo de recursos permitidos por su licencia, no podrá sincronizar más recursos",
  "no.execution.requests.message": "No se detectó ninguna solicitud de ejecución",
  "resource.management.formControl.activateIntelligentOrchestration": "Habilitar la orquestación de infraestructura",
  "Infrastructure orchestration": "orquestación de infraestructura",
  "max.conccurent.session": "Máximo de sesiones simultáneas",
  "execution.range": "Rango de ejecución",
  timeBased: "Basado en el tiempo",
  scheduling: "Programación",
  "orchestration.schedule.desc": "La orquestación se desencadenará cada {{frequency}} {{period}}",
  "orchestration.schedule.desc.hour": " a las {{executionTime}}",
  "orchestration.schedule.desc.week": "el {{days}}",
  "orchestration.schedule.desc.week.except": "todos los días excepto {{days}}",
  and: "y",
  "Add an orchestration": "Agregar una orquestación",
  At: "En",
  SPECIFIC_DAYS_OF_WEEK_IN_MONTH: "Un día específico de la semana en el mes",
  LAST_DAY_OF_MONTH: "El último día del mes",
  NTH_DAY_OF_MONTH: "El día {{day}} del mes",
  "NTH_DAY_OF_MONTH.1": "El 1er día del mes",
  "NTH_DAY_OF_MONTH.2": "El 2do día del mes",
  "NTH_DAY_OF_MONTH.3": "El 3er día del mes",
  "selected.day.not.exist.warning": "El día seleccionado no existe en todos los meses, puede programar la orquestación en el último día del mes.",
  "day.on": "El",
  "of.the.month": "del mes",
  the: "El",
  First: "Primero",
  Second: "Segundo",
  Third: "Tercero",
  Fourth: "Cuarto",
  Last: "Último",
  "start.date": "Fecha de inicio",
  "max.sessions.action": "Cuando se alcanza el número máximo de sesiones",
  SKIP_EXEC: "Omitir ejecución",
  WAIT_FOR_END: "Esperar a que el proceso actual termine antes de comenzar",
  WAIT_UNTIL_TIMEOUT: "Esperar hasta un tiempo límite y luego cancelar si toma demasiado tiempo",
  item_creation_date: "Fecha de creación del elemento",
  date_last_updated_item: "Fecha de la última actualización del elemento",
  "timeout.in.seconds": "Tiempo de espera (segundos)",
  "Trigger details": "Detalles del disparador",
  "using.slas": "usando SLAs",
  Minutely: "Cada minuto",
  "Minute(s)": "Minuto(s)",
  "creation.time.calc.description": "y calculando el tiempo de respuesta desde la fecha de creación del elemento",
  "last_update.time.calc.description": "y calculando el tiempo de respuesta desde la fecha de la última actualización del elemento",
  "orchestration.schedule.desc.past": "La orquestación se programó para cada {{frequency}} {{period}}",
  "using.sla": "Usando SLA",
  "with.filter": "filtrando por",
  "resource.credentials": "Credenciales del recurso",
  "Manage Credentials": "Gestionar credenciales",
  "Credential Name": "Nombre de la credencial",
  "Create Credential": "Crear credencial",
  "Update Credential": "Actualizar credencial",
  username: "Nombre de usuario",
  password: "Contraseña",
  "Assign to these users": "Asignar a estos usuarios",
  "Credential Configuration": "Configuración de credenciales",
  credentials: "Credenciales",
  "confirm.delete.credential": "¿Está seguro de que desea eliminar esta credencial?",
  "Use resource specific credentials": "Usar credenciales específicas del recurso",
  "resource.management.formControl.resourceCommandLinePort": "Este es el puerto que se usará para conectar a la consola de la máquina del recurso, el valor predeterminado de Windows es 5986",
  "resource.management.formControl.resource.bp.restartable": "Esto indicará si se permite que Smartroby reinicie el recurso Blueprism (utilizando la ruta del archivo proporcionada) cuando detecta que no está disponible, esto usará Windows Remote PowerShell, asegúrate de que esté habilitado en la máquina (utilizando Enable-PSRemoting)",
  "resource.management.formControl.resource.win.restartable": "Esto indicará si se permite que Smartroby reinicie la máquina Windows, esto usará Windows Remote PowerShell, asegúrate de que esté habilitado en la máquina (utilizando Enable-PSRemoting)",
  "resource.management.formControl.bpResourceFilePath": "Esta es la ruta completa del archivo .bat utilizado para iniciar el recurso en su máquina",
  "resource.management.formControl.maxRestartTries": "Este es el número de intentos que hará Smartroby para reiniciar el recurso en caso de fallo",
  "test.resource.connection": "Probar conexión",
  "orchestrator.management.testConnection.tooltip": "Pruebe la conexión a la consola de recursos utilizando los datos (host, credenciales, ruta de archivo, puerto) completados anteriormente",
  "command line port": "Puerto de línea de comandos",
  "bp.resource.bat.file.full.filepath": "Ruta completa del archivo de recurso BP",
  "resource.management.testConnection.successMessage": "El recurso está conectado y el archivo existe",
  "resource.management.testConnection.failureMessage": "No se pudo conectar al recurso, verifique los datos del formulario",
  "resource.management.testConnection.successMessage.no.file": "El recurso está conectado, pero no se pudo encontrar el archivo",
  "resource.restart.success": "Recurso reiniciado con éxito, estará disponible en unos minutos",
  "resource.restart.error": "No se pudo reiniciar el recurso",
  "tooltip.action.restart": "Reinicie la máquina Windows del recurso, esto podría tomar unos minutos",
  "action.resource.restart.desc": "¿Estás seguro de que deseas reiniciar la máquina del recurso? Esto podría tomar unos minutos y podría interrumpir ejecuciones y horarios planificados",
  "action.resource.restart": "Reiniciar recurso",
  "select.orchestrator": "Por favor, seleccione un orquestador",
  "resource.groups": "Grupos de recursos",
  "Manage ResourceGroups": "Gestionar grupos de recursos",
  "Create ResourceGroup": "Crear grupo de recursos",
  "ResourceGroup Name": "Nombre del grupo de recursos",
  ResourceCount: "Cantidad de recursos",
  "confirm.delete.resourceGroup": "¿Estás seguro de que deseas eliminar este grupo de recursos?",
  "ResourceGroup Configuration": "Configuración del grupo de recursos",
  "Update ResourceGroup": "Actualizar grupo de recursos",
  "resource.group": "Grupo de recursos",
  "Orchestration started successfully": "La orquestación se inició con éxito",
  "Intelligent orchestration is not enabled on this orchestrator": "La orquestación inteligente no está habilitada en este orquestador",
  "An error occurred while starting orchestration": "Ocurrió un error al iniciar la orquestación",
  "Process response time": "Tiempo de respuesta del proceso",
  "Number of requests": "Número de solicitudes",
  "Request Outcome Distribution": "Distribución de resultados de solicitudes",
  activityHoursDesc: "Horas de actividad (descendente)",
  activityHoursAsc: "Horas de actividad (ascendente)",
  avgWaitTimeDesc: "Tiempo promedio de espera (descendente)",
  avgWaitTimeAsc: "Tiempo promedio de espera (ascendente)",
  completedRequestsDesc: "Solicitudes completadas (descendente)",
  completedRequestsAsc: "Solicitudes completadas (ascendente)",
  failedToStartRequestsDesc: "Solicitudes fallidas al iniciar (descendente)",
  failedToStartRequestsAsc: "Solicitudes fallidas al iniciar (ascendente)",
  stoppedRequestsDesc: "Solicitudes detenidas (descendente)",
  stoppedRequestsAsc: "Solicitudes detenidas (ascendente)",
  completedRequests: "Solicitud completada",
  failedToStartRequests: "Falló al iniciar",
  stoppedRequests: "Solicitudes detenidas",
  avgWaitTime: "Tiempo promedio de espera",
  "Percentage of SLAs achieved & missed": "Porcentaje de SLAs logrados y perdidos",
  "Resource session activity": "Actividad de sesión de recursos",
  "Average processing time": "Tiempo promedio de procesamiento",
  "Average Resource Execution Time": "Tiempo promedio de ejecución de recursos",
  achievedSlas: "Logrados",
  missedSlas: "Perdidos",
  SLAs: "SLAs",
  "See More": "Ver más",
  completedSessions: "Sesiones iniciadas y completadas con éxito",
  stoppedSessions: "Sesiones detenidas por SR",
  failedSessions: "Sesiones que no pudieron iniciarse correctamente",
  totalExecutionTimeHours: "Tiempo promedio de ejecución",
  totalPreemptionTimeHours: "Tiempo promedio de preemisión",
  totalReactionTimeHours: "Tiempo promedio de reacción",
  "Average Processing Time": "Tiempo promedio de procesamiento",
  "Maximum Processing Time": "Tiempo máximo de procesamiento",
  "orchestrator.management.formControl.uipath.is.cloud": "Orquestador en la nube de UiPath",
  "orchestrator.management.formControl.uipath.is.cloud.information": "Indica si el orquestador es un orquestador en la nube de UiPath, de lo contrario, es un orquestador independiente",
  "orchestrator.management.formControl.uipath.url": "URL del orquestador",
  "orchestrator.management.formControl.uipath.url.information": "Esta es la URL del orquestador independiente, incluirá el dominio en el que se aloja el orquestador",
};
